const Communes=[{"id":"1","post_code":"01001","name":"Adrar","wilaya_id":"1", "ar_name":"أدرار", "longitude":"27.9763317", "latitude":"-0.4841573"},
{"id":"2","post_code":"01002","name":"Tamest","wilaya_id":"1", "ar_name":"تأماست", "longitude":"27.4257197","latitude":"-0.2807673"},
{"id":"3","post_code":"01003","name":"Charouine","wilaya_id":"1", "ar_name":"شروين", "longitude":"29.0189483", "latitude":"-0.2690792"},
{"id":"4","post_code":"01004","name":"Reggane","wilaya_id":"1", "ar_name":"رڨان", "longitude":"25.4775826", "latitude":"-4.3665328"},
{"id":"5","post_code":"01005","name":"In Zghmir","wilaya_id":"1", "ar_name":"ان زغمير", "longitude":"25.8711501", "latitude":"-6.252223"},
{"id":"6","post_code":"01006","name":"Tit","wilaya_id":"1", "ar_name":"تــيـــت", "longitude":"26.9368382", "latitude":"1.481824"},
{"id":"7","post_code":"01007","name":"Ksar Kaddour","wilaya_id":"1", "ar_name":"قصر قدور", "longitude":"29.5798013", "latitude":"0.3643512"},
{"id":"8","post_code":"01008","name":"Tsabit","wilaya_id":"1", "ar_name":"تسابيت", "longitude":"28.3533514", "latitude":"-0.2255095"},
{"id":"9","post_code":"01009","name":"Timimoun","wilaya_id":"1", "ar_name":"تيميمون", "longitude":"29.2536341", "latitude":"0.218653"},
{"id":"10","post_code":"01010","name":"Ouled Said","wilaya_id":"1", "ar_name":"أولاد سعيد", "longitude":"29.4217292", "latitude":"0.2254341"},
{"id":"11","post_code":"01011","name":"Zaouiet Kounta","wilaya_id":"1", "ar_name":"زاوية كنتة", "longitude":"27.2293681", "latitude":"-0.2202846"},
{"id":"12","post_code":"01012","name":"Aoulef","wilaya_id":"1", "ar_name":"أولف", "longitude":"26.9710733", "latitude":"1.059451"},
{"id":"13","post_code":"01013","name":"Timokten","wilaya_id":"1", "ar_name":"تيمقتن", "longitude":"27.0221605", "latitude":"1.0255048"},
{"id":"14","post_code":"01014","name":"Tamentit","wilaya_id":"1", "ar_name":"تامنطيت", "longitude":"27.764557", "latitude":"-0.2722121"},
{"id":"15","post_code":"01015","name":"Fenoughil","wilaya_id":"1", "ar_name":"فنوغيل", "longitude":"27.6489014", "latitude":"-0.3155995"},
{"id":"16","post_code":"01016","name":"Tinerkouk","wilaya_id":"1", "ar_name":"زاوية دباغ", "longitude":"29.708777", "latitude":"0.6955288"},
{"id":"17","post_code":"01017","name":"Deldoul","wilaya_id":"1", "ar_name":"دﻟﺪول", "longitude":"34.2080511", "latitude":"3.2543828"},
{"id":"18","post_code":"01018","name":"Sali","wilaya_id":"1", "ar_name":"سالى", "longitude":"26.7370315", "latitude":"-2.8517184"},
{"id":"19","post_code":"01019","name":"Akabli","wilaya_id":"1", "ar_name":"أقبلي", "longitude":"26.7057062", "latitude":"1.344838"},
{"id":"20","post_code":"01020","name":"Metarfa","wilaya_id":"1", "ar_name":"المطارفة", "longitude":"28.5978205", "latitude":"-0.1587011"},
{"id":"21","post_code":"01021","name":"Ouled Ahmed Tammi","wilaya_id":"1", "ar_name":"أولاد أحمد تيمى", "longitude":"27.9766155", "latitude":"-0.20396"},
{"id":"22","post_code":"01022","name":"Bouda","wilaya_id":"1", "ar_name":"بودة", "longitude":"27.9779233", "latitude":"-0.4533424"},
{"id":"23","post_code":"01023","name":"Aougrout","wilaya_id":"1", "ar_name":"أوقروت", "longitude":"28.7499992", "latitude":"0.2412452"},
{"id":"24","post_code":"01024","name":"Talmine","wilaya_id":"1", "ar_name":"", "longitude":"27.9766155", "latitude":"-0.20396"},
{"id":"25","post_code":"01025","name":"Bordj Badji Mokhtar","wilaya_id":"1", "ar_name":"برج باجي مختار", "longitude":"22.9676154", "latitude":"-3.946422"},
{"id":"26","post_code":"01026","name":"Sbaa","wilaya_id":"1", "ar_name":"السبع", "longitude":"28.2112372", "latitude":"-0.1862527"},
{"id":"27","post_code":"01027","name":"Ouled Aissa","wilaya_id":"1", "ar_name":"أولاد عيسى", "longitude":"29.4201586", "latitude":"-0.104542"},
{"id":"28","post_code":"01028","name":"Timiaouine","wilaya_id":"1", "ar_name":"تيمياوين", "longitude":"20.9259001", "latitude":"0.7958642"},
{"id":"29","post_code":"02001","name":"Chlef","wilaya_id":"2",  "ar_name":"الشلف", "longitude":"36.1691042", "latitude":"1.2838598"},
{"id":"30","post_code":"02002","name":"Tenes","wilaya_id":"2",  "ar_name":"تنس", "longitude":"36.508324", "latitude":"1.2426542"},
{"id":"31","post_code":"02003","name":"Benairia","wilaya_id":"2", "ar_name":"بنايرية", "longitude":"36.3849733", "latitude":"1.3150523"},
{"id":"32","post_code":"02004","name":"El Karimia","wilaya_id":"2", "ar_name":"الكريمية", "longitude":"36.0774092", "latitude":"1.5112398"},
{"id":"33","post_code":"02005","name":"Tadjna","wilaya_id":"2", "ar_name":"تاجنة", "longitude":"36.3240803", "latitude":"1.1303901"},
{"id":"34","post_code":"02006","name":"Taougrite","wilaya_id":"2", "ar_name":"تاوقريت", "longitude":"36.2460876", "latitude":"0.8993445"},
{"id":"35","post_code":"02007","name":"Beni Haoua","wilaya_id":"2", "ar_name":"بني حواء", "longitude":"36.4938241", "latitude":"1.5198873"},
{"id":"36","post_code":"02008","name":"Sobha","wilaya_id":"2", "ar_name":"صبحة", "longitude":"36.1084781", "latitude":"1.1017012"},
{"id":"37","post_code":"02009","name":"Harchoun","wilaya_id":"2", "ar_name":"حرشون", "longitude":"36.1184786", "latitude":"1.4187143"},
{"id":"38","post_code":"02010","name":"Ouled Fares","wilaya_id":"2", "ar_name":"أولاد فارس", "longitude":"36.2302131", "latitude":"1.0951463"},
{"id":"39","post_code":"02011","name":"Sidi Akacha","wilaya_id":"2", "ar_name":"سيدي عكاشة", "longitude":"36.4357802", "latitude":"1.2554858"},
{"id":"40","post_code":"02012","name":"Boukadir","wilaya_id":"2", "ar_name":"بوقدير", "longitude":"36.0617947", "latitude":"1.1083316"},
{"id":"41","post_code":"02013","name":"Beni Rached","wilaya_id":"2", "ar_name":"بني راشد", "longitude":"36.2930145", "latitude":"1.4611361"},
{"id":"42","post_code":"02014","name":"Talassa","wilaya_id":"2", "ar_name":"تلعصة", "longitude":"36.4266341", "latitude":"1.0790634"},
{"id":"43","post_code":"02015","name":"Herenfa","wilaya_id":"2", "ar_name":"الهرنفة", "longitude":"36.2438569", "latitude":"1.0440444"},
{"id":"44","post_code":"02016","name":"Oued Goussine","wilaya_id":"2", "ar_name":"واد ڨوسين", "longitude":"36.4949721", "latitude":"1.3928579"},
{"id":"45","post_code":"02017","name":"Dahra","wilaya_id":"2", "ar_name":"الظهرة", "longitude":"36.2551398", "latitude":"0.8432006"},
{"id":"46","post_code":"02018","name":"Ouled Abbes","wilaya_id":"2", "ar_name":"أولاد عباس", "longitude":"36.2139534", "latitude":"1.4578336"},
{"id":"47","post_code":"02019","name":"Sendjas","wilaya_id":"2", "ar_name":"سنجاس", "longitude":"36.0396831", "latitude":"1.2713566"},
{"id":"48","post_code":"02020","name":"Zeboudja","wilaya_id":"2", "ar_name":"الزبوجة", "longitude":"36.3910799", "latitude":"1.3907765"},
{"id":"49","post_code":"02021","name":"Oued Sly","wilaya_id":"2", "ar_name":"واد سلي", "longitude":"36.0806537", "latitude":"1.1443351"},
{"id":"50","post_code":"02022","name":"Abou El Hassen","wilaya_id":"2", "ar_name":"أبو الحسن", "longitude":"36.407875", "latitude":"1.1184571"},
{"id":"51","post_code":"02023","name":"El Marsa","wilaya_id":"2", "ar_name":"المرصى", "longitude":"36.2599947", "latitude":"0.722512"},
{"id":"52","post_code":"02024","name":"Chettia","wilaya_id":"2", "ar_name":"الشطية", "longitude":"36.1782742", "latitude":"1.1791394"},
{"id":"53","post_code":"02025","name":"Sidi Abderrahmane","wilaya_id":"2", "ar_name":"سيدي عبد الرحمان", "longitude":"36.4236714", "latitude":"1.0492304"},
{"id":"54","post_code":"02026","name":"Moussadek","wilaya_id":"2", "ar_name":"مصدق", "longitude":"36.3521334", "latitude":"1.0004317"},
{"id":"55","post_code":"02027","name":"El Hadjadj","wilaya_id":"2", "ar_name":"الحجاج", "longitude":"35.6908338", "latitude":"1.538967"},
{"id":"56","post_code":"02028","name":"Labiod Medjadja","wilaya_id":"2", "ar_name":"الأبيض مجاجة", "longitude":"36.2682804", "latitude":"1.3325618"},
{"id":"57","post_code":"02029","name":"Oued Fodda","wilaya_id":"2", "ar_name":"واد الفضة", "longitude":"36.1995109", "latitude":"1.4672731"},
{"id":"58","post_code":"02030","name":"Ouled Ben Abdelkader","wilaya_id":"2", "ar_name":"أولاد بن عبد القادر", "longitude":"35.9670371", "latitude":"1.192049"},
{"id":"59","post_code":"02031","name":"Bouzghaia","wilaya_id":"2", "ar_name":"بوزغاية", "longitude":"36.3604333", "latitude":"1.1871646"},
{"id":"60","post_code":"02032","name":"Ain Merane","wilaya_id":"2", "ar_name":"عين مران", "longitude":"36.1625642", "latitude":"0.9651016"},
{"id":"61","post_code":"02033","name":"Oum Drou","wilaya_id":"2", "ar_name":"أم الذروع", "longitude":"36.2034869", "latitude":"1.3460801"},
{"id":"62","post_code":"02034","name":"Breira","wilaya_id":"2", "ar_name":"بريرة", "longitude":"36.4476233", "latitude":"1.5221188"},
{"id":"63","post_code":"02035","name":"Beni Bouateb","wilaya_id":"2", "ar_name":"بني بوعتاب", "longitude":"35.9895844", "latitude":"1.5589402"},
{"id":"64","post_code":"03001","name":"Laghouat","wilaya_id":"3", "ar_name":"الأغواط", "longitude":"33.7939523", "latitude":"2.8368501"},
{"id":"65","post_code":"03002","name":"Ksar El Hirane","wilaya_id":"3", "ar_name":"قصر الحيران", "longitude":"33.7855481", "latitude":"3.119366"},
{"id":"66","post_code":"03003","name":"Benacer Ben Chohra","wilaya_id":"3", "ar_name":"بن ناصر بن شهرة", "longitude":"33.7522913", "latitude":"2.9939138"},
{"id":"67","post_code":"03004","name":"Sidi Makhlouf","wilaya_id":"3", "ar_name":"سيدي مخلوف", "longitude":"34.1276242", "latitude":"3.0060262"},
{"id":"68","post_code":"03005","name":"Hassi Delaa","wilaya_id":"3", "ar_name":"حاسي دلاعة", "longitude":"33.4154299", "latitude":"3.5410309"},
{"id":"69","post_code":"03006","name":"Hassi R'Mel","wilaya_id":"3", "ar_name":"حاسي الرمل", "longitude":"32.9412958", "latitude":"3.2443135"},
{"id":"70","post_code":"03007","name":"Ain Mahdi","wilaya_id":"3", "ar_name":"عــيــن مـــاضــي", "longitude":"33.7955179", "latitude":"2.2924947"},
{"id":"71","post_code":"03008","name":"Tadjmout","wilaya_id":"3", "ar_name":"تاجموت", "longitude":"33.8376241", "latitude":"2.5234705"},
{"id":"72","post_code":"03009","name":"El Kheneg","wilaya_id":"3", "ar_name":"الخنق", "longitude":"33.7441108", "latitude":"2.7841758"},
{"id":"73","post_code":"03010","name":"Gueltat Sidi Saad","wilaya_id":"3", "ar_name":"قلتة سيدي سعد", "longitude":"34.2983511", "latitude":"1.9323492"},
{"id":"74","post_code":"03011","name":"Ain Sidi Ali","wilaya_id":"3", "ar_name":"عين سيدي علي", "longitude":"34.1666661", "latitude":"1.5412452"},
{"id":"75","post_code":"03012","name":"Beidha","wilaya_id":"3", "ar_name":"بيضاء", "longitude":"33.7873735", "latitude":"2.8829115"},
{"id":"76","post_code":"03013","name":"Brida","wilaya_id":"3", "ar_name":"بريدة", "longitude":"33.9495922", "latitude":"1.7465471"},
{"id":"77","post_code":"03014","name":"El Ghicha","wilaya_id":"3", "ar_name":"الغيشة", "longitude":"33.9297719", "latitude":"2.1396303"},
{"id":"78","post_code":"03015","name":"Hadj Mechri","wilaya_id":"3", "ar_name":"الحاج المشري", "longitude":"33.9572842", "latitude":"1.5908605"},
{"id":"79","post_code":"03016","name":"Sebgag","wilaya_id":"3", "ar_name":"سبقاق", "longitude":"34.0285379", "latitude":"1.9293458"},
{"id":"80","post_code":"03017","name":"Taouiala","wilaya_id":"3", "ar_name":"تاويالة", "longitude":"33.8715595", "latitude":"1.8530845"},
{"id":"81","post_code":"03018","name":"Tadjrouna","wilaya_id":"3", "ar_name":"تاجرونة", "longitude":"33.5036312", "latitude":"2.0924622"},
{"id":"82","post_code":"03019","name":"Aflou","wilaya_id":"3", "ar_name":"أفلو", "longitude":"34.1121156", "latitude":"2.0835167"},
{"id":"83","post_code":"03020","name":"El Assafia","wilaya_id":"3", "ar_name":"العسافية", "longitude":"33.8269722", "latitude":"2.9816293"},
{"id":"84","post_code":"03021","name":"Oued Morra","wilaya_id":"3", "ar_name":"وادي مرة", "longitude":"34.1666661", "latitude":"2.3079121"},
{"id":"85","post_code":"03022","name":"Oued M'Zi","wilaya_id":"3", "ar_name":"وادي مزي", "longitude":"33.9410427", "latitude":"2.4231133"},
{"id":"86","post_code":"03023","name":"El Haouaita","wilaya_id":"3", "ar_name":"الهوارية", "longitude":"37.0505755", "latitude":"10.9904863"},
{"id":"87","post_code":"03024","name":"Sidi Bouzid","wilaya_id":"3", "ar_name":"سيدي بوزيد", "longitude":"35.0363883", "latitude":"9.4595281"},
{"id":"88","post_code":"04001","name":"Oum El Bouaghi","wilaya_id":"4", "ar_name":"أم البواقي", "longitude":"35.8725963", "latitude":"7.0830052"},
{"id":"89","post_code":"04002","name":"Ain Beida","wilaya_id":"4", "ar_name":"عين البيضاء", "longitude":"35.7948292", "latitude":"7.3338883"},
{"id":"90","post_code":"04003","name":"Ain M'lila","wilaya_id":"4", "ar_name":"عين مليلة", "longitude":"36.0260869", "latitude":"6.4239017"},
{"id":"91","post_code":"04004","name":"Behir Chergui","wilaya_id":"4", "ar_name":"بحير الشرڨي", "longitude":"35.7937891", "latitude":"7.7132713"},
{"id":"92","post_code":"04005","name":"El Amiria","wilaya_id":"4", "ar_name":"العامرية", "longitude":"36.1101777", "latitude":"6.9026434"},
{"id":"93","post_code":"04006","name":"Sigus","wilaya_id":"4", "ar_name":"سيقوس", "longitude":"36.1085199", "latitude":"6.603674"},
{"id":"94","post_code":"04007","name":"El Belala","wilaya_id":"4", "ar_name":"البلالة", "longitude":"35.6660914", "latitude":"7.7837598"},
{"id":"95","post_code":"04008","name":"Ain Babouche","wilaya_id":"4", "ar_name":"عين بابوش", "longitude":"35.9402286", "latitude":"7.1751022"},
{"id":"96","post_code":"04009","name":"Berriche","wilaya_id":"4", "ar_name":"بريش", "longitude":"35.9155816", "latitude":"7.3676848"},
{"id":"97","post_code":"04010","name":"Ouled Hamla","wilaya_id":"4", "ar_name":"أولاد حملة", "longitude":"36.0811426", "latitude":"6.3334038"},
{"id":"98","post_code":"04011","name":"Dhala","wilaya_id":"4", "ar_name":"الضلعة", "longitude":"35.4610885", "latitude":"7.5379943"},
{"id":"99","post_code":"04012","name":"Ain Kercha","wilaya_id":"4", "ar_name":"عين كرشة", "longitude":"35.9275632", "latitude":"6.6811426"},
{"id":"100","post_code":"04013","name":"Hanchir Toumghani","wilaya_id":"4", "ar_name":"هنشير تومغني", "longitude":"35.9371712", "latitude":"6.7319584"},
{"id":"101","post_code":"04014","name":"El Djazia","wilaya_id":"4", "ar_name":"الجازيـــــــة", "longitude":"35.663473", "latitude":"7.5030506"},
{"id":"102","post_code":"04015","name":"Ain Diss","wilaya_id":"4", "ar_name":"عين الديس", "longitude":"35.8101391", "latitude":"7.2888545"},
{"id":"103","post_code":"04016","name":"Fkirina","wilaya_id":"4", "ar_name":"فكرينة", "longitude":"35.6638941", "latitude":"7.2909736"},
{"id":"104","post_code":"04017","name":"Souk Naamane","wilaya_id":"4", "ar_name":"سوق نعمان", "longitude":"35.93924", "latitude":"6.2583772"},
{"id":"105","post_code":"04018","name":"Zorg","wilaya_id":"4", "ar_name":"الزرڨ", "longitude":"35.8726014", "latitude":"7.1180248"},
{"id":"106","post_code":"04019","name":"El Fedjoudj Boughrara Saoudi","wilaya_id":"4", "ar_name":"الفجوج بوغرارة سعودى", "longitude":"35.709409", "latitude":"6.8162978"},
{"id":"107","post_code":"04020","name":"Ouled Zouai","wilaya_id":"4", "ar_name":"أولاد زواي", "longitude":"35.8556626", "latitude":"6.3821021"},
{"id":"108","post_code":"04021","name":"Bir Chouhada","wilaya_id":"4", "ar_name":"بئر الشهداء", "longitude":"35.9007146", "latitude":"6.1753867"},
{"id":"109","post_code":"04022","name":"Ksar Sbahi","wilaya_id":"4", "ar_name":"قصر صباحي", "longitude":"36.0820976", "latitude":"7.250204"},
{"id":"110","post_code":"04023","name":"Oued Nini","wilaya_id":"4", "ar_name":"وادي نيني", "longitude":"35.5716976", "latitude":"7.3377513"},
{"id":"111","post_code":"04024","name":"Meskiana","wilaya_id":"4", "ar_name":"مسكيانة", "longitude":"35.6297416", "latitude":"7.6523636"},
{"id":"112","post_code":"04025","name":"Ain Fekroune","wilaya_id":"4", "ar_name":"عين فكرون", "longitude":"35.9745986", "latitude":"6.851982"},
{"id":"113","post_code":"04026","name":"Rahia","wilaya_id":"4", "ar_name":"الراحية", "longitude":"35.7166662", "latitude":"7.6579122"},
{"id":"114","post_code":"04027","name":"Ain Zitoun","wilaya_id":"4", "ar_name":"عين الزيتون", "longitude":"35.6841587", "latitude":"6.9309782"},
{"id":"115","post_code":"04028","name":"Ouled Gacem","wilaya_id":"4", "ar_name":"أولاد ڨاسم", "longitude":"36.0328844", "latitude":"6.6594743"},
{"id":"116","post_code":"04029","name":"El Harmilia","wilaya_id":"4", "ar_name":"الحرملية", "longitude":"35.9257478", "latitude":"6.6176749"},
{"id":"117","post_code":"05001","name":"Batna","wilaya_id":"5", "ar_name":"باتنة", "longitude":"35.5784266", "latitude":"6.1083422"},
{"id":"118","post_code":"05002","name":"Ghassira","wilaya_id":"5", "ar_name":"غسيرة", "longitude":"35.0629605", "latitude":"6.1563323"},
{"id":"119","post_code":"05003","name":"Maafa","wilaya_id":"5", "ar_name":"معافة", "longitude":"35.2770359", "latitude":"5.8156273"},
{"id":"120","post_code":"05004","name":"Merouana","wilaya_id":"5", "ar_name":"مروانة", "longitude":"35.6417881", "latitude":"5.8172903"},
{"id":"121","post_code":"05005","name":"Seriana","wilaya_id":"5", "ar_name":"سريانة", "longitude":"35.7161562", "latitude":"6.127064"},
{"id":"122","post_code":"05006","name":"Menaa","wilaya_id":"5", "ar_name":"منعة", "longitude":"35.1373236", "latitude":"5.9981034"},
{"id":"123","post_code":"05007","name":"El Madher","wilaya_id":"5", "ar_name":"المعذر", "longitude":"35.6392974", "latitude":"6.3042185"},
{"id":"124","post_code":"05008","name":"Tazoult","wilaya_id":"5", "ar_name":"تازولت", "longitude":"35.470362", "latitude":"6.1694644"},
{"id":"125","post_code":"05009","name":"Ngaous","wilaya_id":"5", "ar_name":"نڨاوس", "longitude":"35.5525842", "latitude":"5.5189965"},
{"id":"126","post_code":"05010","name":"Guigba","wilaya_id":"5", "ar_name":"قيقبة", "longitude":"35.7407753", "latitude":"5.5352956"},
{"id":"127","post_code":"05011","name":"Inoughissen","wilaya_id":"5", "ar_name":"إينوغيسن", "longitude":"35.2771231", "latitude":"6.4628765"},
{"id":"128","post_code":"05012","name":"Ouyoun El Assafir","wilaya_id":"5", "ar_name":"عيون العصافير", "longitude":"35.5468473", "latitude":"6.2710449"},
{"id":"129","post_code":"05013","name":"Djerma","wilaya_id":"5", "ar_name":"جرمة", "longitude":"35.6853933", "latitude":"6.2267778"},
{"id":"130","post_code":"05014","name":"Bitam","wilaya_id":"5", "ar_name":"بيطام", "longitude":"35.1772246", "latitude":"4.9980505"},
{"id":"131","post_code":"05015","name":"Metkaouak","wilaya_id":"5", "ar_name":"عزيل عبد القادر", "longitude":"35.323352", "latitude":"4.6952399"},
{"id":"132","post_code":"05016","name":"Arris","wilaya_id":"5", "ar_name":"اريس", "longitude":"35.3105194", "latitude":"6.2007524"},
{"id":"133","post_code":"05017","name":"Kimmel","wilaya_id":"5", "ar_name":"كيمل", "longitude":"34.9992137", "latitude":"6.2208802"},
{"id":"134","post_code":"05018","name":"Tilatou","wilaya_id":"5", "ar_name":"تيلاطو", "longitude":"35.3131323", "latitude":"5.4911366"},
{"id":"135","post_code":"05019","name":"Ain Djasser","wilaya_id":"5", "ar_name":"عين جاسر", "longitude":"35.8311206", "latitude":"5.9331296"},
{"id":"136","post_code":"05020","name":"Ouled Selam","wilaya_id":"5", "ar_name":"أولاد سلام", "longitude":"35.8457485", "latitude":"5.7276937"},
{"id":"137","post_code":"05021","name":"Tigherghar","wilaya_id":"5", "ar_name":"تيغرغار", "longitude":"35.0765612", "latitude":"5.8212842"},
{"id":"138","post_code":"05022","name":"Ain Yagout","wilaya_id":"5", "ar_name":"عين ياقوت", "longitude":"35.7864482", "latitude":"6.2679257"},
{"id":"139","post_code":"05023","name":"Fesdis","wilaya_id":"5", "ar_name":"فسديس", "longitude":"35.6179227", "latitude":"6.1696788"},
{"id":"140","post_code":"05024","name":"Sefiane","wilaya_id":"5", "ar_name":"سفيان", "longitude":"35.4280853", "latitude":"5.5186532"},
{"id":"141","post_code":"05025","name":"Rahbat","wilaya_id":"5", "ar_name":"الرحبات", "longitude":"35.7257797", "latitude":"5.5571911"},
{"id":"142","post_code":"05026","name":"Tighanimine","wilaya_id":"5", "ar_name":"تيغانمين", "longitude":"35.185829", "latitude":"6.2290737"},
{"id":"143","post_code":"05027","name":"Lemsane","wilaya_id":"5", "ar_name":"لمسان", "longitude":"35.6384911", "latitude":"5.7282516"},
{"id":"144","post_code":"05028","name":"Ksar Belezma","wilaya_id":"5", "ar_name":"قصر بلازمة", "longitude":"35.728729", "latitude":"5.8247576"},
{"id":"145","post_code":"05029","name":"Seggana","wilaya_id":"5", "ar_name":"سقانة", "longitude":"35.3380358", "latitude":"5.4071512"},
{"id":"146","post_code":"05030","name":"Ichmoul","wilaya_id":"5", "ar_name":"ايشمول", "longitude":"35.3059756", "latitude":"6.4186737"},
{"id":"147","post_code":"05031","name":"Foum Toub","wilaya_id":"5", "ar_name":"فم الطوب", "longitude":"35.3867728", "latitude":"6.4478348"},
{"id":"148","post_code":"05032","name":"Beni Foudhala El Hakania","wilaya_id":"5", "ar_name":"بنى فضالة الحقانية", "longitude":"35.3790646", "latitude":"5.9300746"},
{"id":"149","post_code":"05033","name":"Oued El Ma","wilaya_id":"5", "ar_name":"واد الماء", "longitude":"35.6696259", "latitude":"5.952935"},
{"id":"150","post_code":"05034","name":"Talkhamt","wilaya_id":"5", "ar_name":"تالخمت", "longitude":"35.722912", "latitude":"5.7070515"},
{"id":"151","post_code":"05035","name":"Bouzina","wilaya_id":"5", "ar_name":"بوزينة", "longitude":"35.2757765", "latitude":"5.9780111"},
{"id":"152","post_code":"05036","name":"Chemora","wilaya_id":"5", "ar_name":"الشمرة", "longitude":"35.6301185", "latitude":"6.4747992"},
{"id":"153","post_code":"05037","name":"Oued Chaaba","wilaya_id":"5", "ar_name":"واد الشعبة", "longitude":"35.5243377", "latitude":"6.011965"},
{"id":"154","post_code":"05038","name":"Taxlent","wilaya_id":"5", "ar_name":"تاكسلانت", "longitude":"35.5635407", "latitude":"5.7388089"},
{"id":"155","post_code":"05039","name":"Gosbat","wilaya_id":"5", "ar_name":"القصبات", "longitude":"35.6198308", "latitude":"5.3394309"},
{"id":"156","post_code":"05040","name":"Ouled Aouf","wilaya_id":"5", "ar_name":"أولاد عوف", "longitude":"35.4740373", "latitude":"5.7172224"},
{"id":"157","post_code":"05041","name":"Boumagueur","wilaya_id":"5", "ar_name":"بــومقر", "longitude":"35.4973671", "latitude":"5.5073234"},
{"id":"158","post_code":"05042","name":"Barika","wilaya_id":"5", "ar_name":"بريكة", "longitude":"35.3698534", "latitude":"5.1424493"},
{"id":"159","post_code":"05043","name":"Djezzar","wilaya_id":"5", "ar_name":"الجزار", "longitude":"35.5180608", "latitude":"5.1947203"},
{"id":"160","post_code":"05044","name":"Tkout","wilaya_id":"5", "ar_name":"تكوت", "longitude":"35.1533059", "latitude":"6.2237014"},
{"id":"161","post_code":"05045","name":"Ain Touta","wilaya_id":"5", "ar_name":"عين التوتة", "longitude":"35.3640241", "latitude":"5.7540788"},
{"id":"162","post_code":"05046","name":"Hidoussa","wilaya_id":"5", "ar_name":"حيدوسة", "longitude":"35.528608", "latitude":"5.7812335"},
{"id":"163","post_code":"05047","name":"Teniet El Abed","wilaya_id":"5", "ar_name":"نية العابد", "longitude":"35.2869315", "latitude":"6.1926387"},
{"id":"164","post_code":"05048","name":"Oued Taga","wilaya_id":"5", "ar_name":"وادي الطاقة", "longitude":"35.4185955", "latitude":"6.2379494"},
{"id":"165","post_code":"05049","name":"Ouled Fadel","wilaya_id":"5", "ar_name":"أولاد فاضل", "longitude":"35.5552777", "latitude":"6.540245"},
{"id":"166","post_code":"05050","name":"Timgad","wilaya_id":"5", "ar_name":"تيمقاد", "longitude":"35.5269245", "latitude":"6.3713303"},
{"id":"167","post_code":"05051","name":"Ras El Aioun","wilaya_id":"5", "ar_name":"رأس العيون", "longitude":"35.6547596", "latitude":"5.5340598"},
{"id":"168","post_code":"05052","name":"Chir","wilaya_id":"5", "ar_name":"شير", "longitude":"35.218839", "latitude":"6.061661"},
{"id":"169","post_code":"05053","name":"Ouled Si Slimane","wilaya_id":"5", "ar_name":"أولاد سي سليمان", "longitude":"35.5671114", "latitude":"5.5504885"},
{"id":"170","post_code":"05054","name":"Zanat El Beida","wilaya_id":"5", "ar_name":"زانة البيضاء", "longitude":"35.7947074", "latitude":"5.9551803"},
{"id":"171","post_code":"05055","name":"M'doukel","wilaya_id":"5", "ar_name":"أمدوكال", "longitude":"35.1132204", "latitude":"5.0064721"},
{"id":"172","post_code":"05056","name":"Ouled Ammar","wilaya_id":"5", "ar_name":"أولاد عمار", "longitude":"35.4637775", "latitude":"5.1226733"},
{"id":"173","post_code":"05057","name":"El Hassi","wilaya_id":"5", "ar_name":"الحاسي", "longitude":"35.7805122", "latitude":"5.8788416"},
{"id":"174","post_code":"05058","name":"Lazrou","wilaya_id":"5", "ar_name":"لازرو", "longitude":"35.8042574", "latitude":"6.2001918"},
{"id":"175","post_code":"05059","name":"Boumia","wilaya_id":"5", "ar_name":"بومية", "longitude":"35.7041224", "latitude":"6.3963578"},
{"id":"176","post_code":"05060","name":"Boulhilat","wilaya_id":"5", "ar_name":"بولهيلات", "longitude":"35.7374798", "latitude":"6.5889619"},
{"id":"177","post_code":"05061","name":"Larbaa","wilaya_id":"5", "ar_name":"الاربعاء", "longitude":"36.5787079", "latitude":"3.103324"},
{"id":"178","post_code":"06001","name":"Bejaia","wilaya_id":"6", "ar_name":"بجاية", "longitude":"36.7695763", "latitude":"4.938545"},
{"id":"179","post_code":"06002","name":"Amizour","wilaya_id":"6", "ar_name":"اميزور", "longitude":"36.6429158", "latitude":"4.8679708"},
{"id":"180","post_code":"06003","name":"Ferraoun","wilaya_id":"6", "ar_name":"فرعون", "longitude":"36.5654312", "latitude":"4.8417715"},
{"id":"181","post_code":"06004","name":"Taourirt Ighil","wilaya_id":"6", "ar_name":"تاوريرت اغيل", "longitude":"36.7329317", "latitude":"4.6709356"},
{"id":"182","post_code":"06005","name":"Chelata","wilaya_id":"6", "ar_name":"شلاطة", "longitude":"36.5079843", "latitude":"4.4892072"},
{"id":"183","post_code":"06006","name":"Tamokra","wilaya_id":"6", "ar_name":"تامقرة", "longitude":"36.3916342", "latitude":"4.5814411"},
{"id":"184","post_code":"06007","name":"Timzrit","wilaya_id":"6", "ar_name":"تيمزريت", "longitude":"36.6161452", "latitude":"4.7342109"},
{"id":"185","post_code":"06008","name":"Souk El Thenine","wilaya_id":"6", "ar_name":"ﺳﻮق اﻻﺛﻨﻴﻦ", "longitude":"36.6150269", "latitude":"5.2777913"},
{"id":"186","post_code":"06009","name":"M'cisna","wilaya_id":"6", "ar_name":"مسيسنة", "longitude":"36.5646521", "latitude":"4.6922142"},
{"id":"187","post_code":"06010","name":"Thinabdher","wilaya_id":"6", "ar_name":"تينبذار", "longitude":"36.6333952", "latitude":"4.6645332"},
{"id":"188","post_code":"06011","name":"Tichi","wilaya_id":"6", "ar_name":"تيشي", "longitude":"36.620329", "latitude":"5.0944646"},
{"id":"189","post_code":"06012","name":"Semaoun","wilaya_id":"6", "ar_name":"سمعون", "longitude":"36.6163121", "latitude":"4.8029022"},
{"id":"190","post_code":"06013","name":"Kendira","wilaya_id":"6", "ar_name":"كنديرة", "longitude":"36.5568449", "latitude":"4.948437"},
{"id":"191","post_code":"06014","name":"Tifra","wilaya_id":"6", "ar_name":"تيفرة", "longitude":"36.6626768", "latitude":"4.6505501"},
{"id":"192","post_code":"06015","name":"Ighram","wilaya_id":"6", "ar_name":"إغرم", "longitude":"36.4765457", "latitude":"4.3924687"},
{"id":"193","post_code":"06016","name":"Amalou","wilaya_id":"6", "ar_name":"امالو", "longitude":"36.4780606", "latitude":"4.5674507"},
{"id":"194","post_code":"06017","name":"Ighil Ali","wilaya_id":"6", "ar_name":"إغيل على", "longitude":"36.2905404", "latitude":"4.4345472"},
{"id":"195","post_code":"06018","name":"Ifelain Ilmathen","wilaya_id":"6", "ar_name":"افناين الماثن", "longitude":"36.6673126", "latitude":"4.7479832"},
{"id":"196","post_code":"06019","name":"Toudja","wilaya_id":"6", "ar_name":"توجة", "longitude":"36.7862498", "latitude":"4.7209914"},
{"id":"197","post_code":"06020","name":"Darguina","wilaya_id":"6", "ar_name":"درقينة", "longitude":"36.5537403", "latitude":"5.3057073"},
{"id":"198","post_code":"06021","name":"Sidi Ayad","wilaya_id":"6", "ar_name":"سيدي عياد", "longitude":"36.6095992", "latitude":"4.6956681"},
{"id":"199","post_code":"06022","name":"Aokas","wilaya_id":"6", "ar_name":"أوقاس", "longitude":"36.6195851", "latitude":"5.2038174"},
{"id":"200","post_code":"06023","name":"Ait Djellil","wilaya_id":"6", "ar_name":"آيث جليل", "longitude":"36.5626795", "latitude":"4.7643966"},
{"id":"201","post_code":"06024","name":"Adekar","wilaya_id":"6", "ar_name":"آدكار", "longitude":"36.7343709", "latitude":"4.5420287"},
{"id":"202","post_code":"06025","name":"Akbou","wilaya_id":"6", "ar_name":"أقبو", "longitude":"36.4583204", "latitude":"4.4639658"},
{"id":"203","post_code":"06026","name":"Seddouk","wilaya_id":"6", "ar_name":"صدوق", "longitude":"36.5367869", "latitude":"4.6409049"},
{"id":"204","post_code":"06027","name":"Tazmalt","wilaya_id":"6", "ar_name":"تازمالت", "longitude":"36.3980947", "latitude":"4.3892305"},
{"id":"205","post_code":"06028","name":"Ait R'zine","wilaya_id":"6", "ar_name":"آيت أرزين", "longitude":"36.3866561", "latitude":"4.4646094"},
{"id":"206","post_code":"06029","name":"Chemini","wilaya_id":"6", "ar_name":"شميني", "longitude":"36.5928345", "latitude":"4.5601731"},
{"id":"207","post_code":"06030","name":"Souk Oufella","wilaya_id":"6", "ar_name":"سوق أوفلة", "longitude":"36.5880436", "latitude":"4.5992053"},
{"id":"208","post_code":"06031","name":"Taskriout","wilaya_id":"6", "ar_name":"تاسقريوت", "longitude":"36.5565707", "latitude":"5.2237624"},
{"id":"209","post_code":"06032","name":"Tibane","wilaya_id":"6", "ar_name":"طيبان", "longitude":"36.6131706", "latitude":"4.6360841"},
{"id":"210","post_code":"06033","name":"Tala Hamza","wilaya_id":"6", "ar_name":"ثالة حمزة", "longitude":"36.6904609", "latitude":"4.9432228"},
{"id":"211","post_code":"06034","name":"Barbacha","wilaya_id":"6", "ar_name":"برباشة", "longitude":"36.5628189", "latitude":"4.8862205"},
{"id":"212","post_code":"06035","name":"Beni Ksila","wilaya_id":"6", "ar_name":"بنى كسيلة", "longitude":"36.8280602", "latitude":"4.6309708"},
{"id":"213","post_code":"06036","name":"Ouzallaguen","wilaya_id":"6", "ar_name":"أوزلاقن", "longitude":"36.5473904", "latitude":"4.5075207"},
{"id":"214","post_code":"06037","name":"Bouhamza","wilaya_id":"6", "ar_name":"بوحمزة", "longitude":"36.4393229", "latitude":"4.5700544"},
{"id":"215","post_code":"06038","name":"Beni Melikeche","wilaya_id":"6", "ar_name":"بنى مليكش", "longitude":"36.4475154", "latitude":"4.3846601"},
{"id":"216","post_code":"06039","name":"Sidi Aich","wilaya_id":"6", "ar_name":"سيدي عيش", "longitude":"36.6223093", "latitude":"4.6792592"},
{"id":"217","post_code":"06040","name":"El Kseur","wilaya_id":"6", "ar_name":"القصر", "longitude":"36.7187382", "latitude":"4.7678254"},
{"id":"218","post_code":"06041","name":"Melbou","wilaya_id":"6", "ar_name":"ملبو", "longitude":"36.6217049", "latitude":"5.351135"},
{"id":"219","post_code":"06042","name":"Akfadou","wilaya_id":"6", "ar_name":"اكفادو", "longitude":"36.6580416", "latitude":"4.5747965"},
{"id":"220","post_code":"06043","name":"Leflaye","wilaya_id":"6", "ar_name":"لفلاى", "longitude":"36.597995", "latitude":"4.6441959"},
{"id":"221","post_code":"06044","name":"Kherrata","wilaya_id":"6", "ar_name":"خراطة", "longitude":"36.4719307", "latitude":"5.2047107"},
{"id":"222","post_code":"06045","name":"Draa Kaid","wilaya_id":"6", "ar_name":"ذراع القايد", "longitude":"36.4405974", "latitude":"5.168301"},
{"id":"223","post_code":"06046","name":"Tamridjet","wilaya_id":"6", "ar_name":"تامريجت", "longitude":"36.5729424", "latitude":"5.3775099"},
{"id":"224","post_code":"06047","name":"Ait Smail","wilaya_id":"6", "ar_name":"آيت سماعيل", "longitude":"36.554314", "latitude":"5.1892262"},
{"id":"225","post_code":"06048","name":"Boukhelifa","wilaya_id":"6", "ar_name":"بوخليفة", "longitude":"36.6367939", "latitude":"5.0058926"},
{"id":"226","post_code":"06049","name":"Tizi N'berber","wilaya_id":"6", "ar_name":"تيزى نبربر", "longitude":"36.5899196", "latitude":"5.1307333"},
{"id":"227","post_code":"06050","name":"Beni Maouch","wilaya_id":"6", "ar_name":"بني معوش", "longitude":"36.4881459", "latitude":"4.72105"},
{"id":"228","post_code":"06051","name":"Oued Ghir","wilaya_id":"6", "ar_name":"وادي غير", "longitude":"36.7149207", "latitude":"4.9085928"},
{"id":"229","post_code":"06052","name":"Boudjellil","wilaya_id":"6", "ar_name":"بوجليل", "longitude":"36.3223972", "latitude":"4.3477724"},
{"id":"230","post_code":"07001","name":"Biskra","wilaya_id":"7", "ar_name":"بسكرة", "longitude":"34.851484", "latitude":"5.6546304"},
{"id":"231","post_code":"07002","name":"Oumache","wilaya_id":"7", "ar_name":"أوماش", "longitude":"34.586303", "latitude":"5.5181413"},
{"id":"232","post_code":"07003","name":"Branis","wilaya_id":"7", "ar_name":"البرانس", "longitude":"34.9709574", "latitude":"5.6412543"},
{"id":"233","post_code":"07004","name":"Chetma","wilaya_id":"7", "ar_name":"شتمة", "longitude":"34.8655388", "latitude":"5.7729266"},
{"id":"234","post_code":"07005","name":"Ouled Djellal","wilaya_id":"7", "ar_name":"أولاد جلال", "longitude":"34.4174639", "latitude":"4.9713028"},
{"id":"235","post_code":"07006","name":"Ras El Miaad","wilaya_id":"7", "ar_name":"راس الميعاد", "longitude":"34.0268001", "latitude":"4.1596986"},
{"id":"236","post_code":"07007","name":"Besbes","wilaya_id":"7", "ar_name":"البسباس", "longitude":"36.7204026", "latitude":"7.7544139"},
{"id":"237","post_code":"07008","name":"Sidi Khaled","wilaya_id":"7", "ar_name":"سيدي خالد", "longitude":"34.3845588", "latitude":"4.8400248"},
{"id":"238","post_code":"07009","name":"Doucen","wilaya_id":"7", "ar_name":"الدوسن", "longitude":"34.6197821", "latitude":"4.9863232"},
{"id":"239","post_code":"07010","name":"Ech Chaiba","wilaya_id":"7", "ar_name":"أولاد رحمة", "longitude":"34.6257746", "latitude":"4.5572238"},
{"id":"240","post_code":"07011","name":"Sidi Okba","wilaya_id":"7", "ar_name":"سيدي عقبة", "longitude":"34.776526", "latitude":"5.8344242"},
{"id":"241","post_code":"07012","name":"Mchouneche","wilaya_id":"7", "ar_name":"مشونش", "longitude":"34.9063847", "latitude":"5.8993044"},
{"id":"242","post_code":"07013","name":"El Haouch","wilaya_id":"7", "ar_name":"الحوش", "longitude":"34.5603287", "latitude":"5.8852281"},
{"id":"243","post_code":"07014","name":"Ain Naga","wilaya_id":"7", "ar_name":"عين الناقة", "longitude":"34.6508231", "latitude":"6.0189093"},
{"id":"244","post_code":"07015","name":"Zeribet El Oued","wilaya_id":"7", "ar_name":"زريبة الوادي", "longitude":"34.683456", "latitude":"6.3748921"},
{"id":"245","post_code":"07016","name":"El Feidh","wilaya_id":"7", "ar_name":"الفيض", "longitude":"34.4990192", "latitude":"6.1924699"},
{"id":"246","post_code":"07017","name":"El Kantara","wilaya_id":"7", "ar_name":"القنطرة", "longitude":"35.2052532", "latitude":"5.5062642"},
{"id":"247","post_code":"07018","name":"Ain Zaatout","wilaya_id":"7", "ar_name":"عين زعطوط", "longitude":"35.1705188", "latitude":"5.7860157"},
{"id":"248","post_code":"07019","name":"El Outaya","wilaya_id":"7", "ar_name":"لوطاية", "longitude":"34.9772883", "latitude":"5.4401959"},
{"id":"249","post_code":"07020","name":"Djemorah","wilaya_id":"7", "ar_name":"جمورة", "longitude":"35.0989288", "latitude":"5.6954643"},
{"id":"250","post_code":"07021","name":"Tolga","wilaya_id":"7", "ar_name":"طولقة", "longitude":"34.894129", "latitude":"4.9483543"},
{"id":"251","post_code":"07022","name":"Lioua","wilaya_id":"7", "ar_name":"لواء", "longitude":"34.5726359", "latitude":"5.224675"},
{"id":"252","post_code":"07023","name":"Lichana","wilaya_id":"7", "ar_name":"لشانة", "longitude":"34.7379403", "latitude":"5.3672479"},
{"id":"253","post_code":"07024","name":"Ourlal","wilaya_id":"7", "ar_name":"أورلال", "longitude":"34.563429", "latitude":"5.3748787"},
{"id":"254","post_code":"07025","name":"M'lili","wilaya_id":"7", "ar_name":"مليلي", "longitude":"34.5781616", "latitude":"5.4617608"},
{"id":"255","post_code":"07026","name":"Foughala","wilaya_id":"7", "ar_name":"فوغالة", "longitude":"34.7598362", "latitude":"5.2212069"},
{"id":"256","post_code":"07027","name":"Bordj Ben Azzouz","wilaya_id":"7", "ar_name":"برج بن عزوز", "longitude":"34.7281944", "latitude":"5.281975"},
{"id":"257","post_code":"07028","name":"M'ziraa","wilaya_id":"7", "ar_name":"مزيرعة", "longitude":"34.8853471", "latitude":"6.0060458"},
{"id":"258","post_code":"07029","name":"Bouchagroun","wilaya_id":"7", "ar_name":"بوشقرون", "longitude":"34.7378859", "latitude":"5.4169652"},
{"id":"259","post_code":"07030","name":"Mekhadma","wilaya_id":"7", "ar_name":"مخادمة", "longitude":"34.5636943", "latitude":"5.3092183"},
{"id":"260","post_code":"07031","name":"El Ghrous","wilaya_id":"7", "ar_name":"الغروس", "longitude":"34.7175474", "latitude":"5.0903931"},
{"id":"261","post_code":"07032","name":"El Hadjab","wilaya_id":"7", "ar_name":"الحاجب", "longitude":"34.7967888", "latitude":"5.4551949"},
{"id":"262","post_code":"07033","name":"Khanguet Sidinadji","wilaya_id":"7", "ar_name":"خنڨة سيدي ناجي", "longitude":"34.784537", "latitude":"6.6327354"},
{"id":"263","post_code":"08001","name":"Bechar","wilaya_id":"8", "ar_name":"بشار", "longitude":"31.5965368", "latitude":"-2.4145248"},
{"id":"264","post_code":"08002","name":"Erg Ferradj","wilaya_id":"8", "ar_name":"عرق فراج", "longitude":"31.0835905", "latitude":"-2.8630916"},
{"id":"265","post_code":"08003","name":"Ouled Khoudir","wilaya_id":"8", "ar_name":"أولاد خدير", "longitude":"29.2558699", "latitude":"-1.0641503"},
{"id":"266","post_code":"08004","name":"Meridja","wilaya_id":"8", "ar_name":"مريجة", "longitude":"31.5494045", "latitude":"-2.95583"},
{"id":"267","post_code":"08005","name":"Timoudi","wilaya_id":"8", "ar_name":"تيمودى", "longitude":"29.3195526", "latitude":"-1.135803"},
{"id":"268","post_code":"08006","name":"Lahmar","wilaya_id":"8", "ar_name":"لحمر", "longitude":"31.9312184", "latitude":"-2.2681563"},
{"id":"269","post_code":"08007","name":"Beni Abbes","wilaya_id":"8", "ar_name":"بني عباس", "longitude":"30.1302299", "latitude":"-2.1782542"},
{"id":"270","post_code":"08008","name":"Beni Ikhlef","wilaya_id":"8", "ar_name":"بني يخلف", "longitude":"29.5802183", "latitude":"-1.6128088"},
{"id":"271","post_code":"08009","name":"Mechraa Houari Boumedienne","wilaya_id":"8", "ar_name":"مشرع ھوارى بومدين", "longitude":"30.9318258", "latitude":"-2.741164"},
{"id":"272","post_code":"08010","name":"Kenedsa","wilaya_id":"8", "ar_name":"القنادسة", "longitude":"31.5570599", "latitude":"-2.4381801"},
{"id":"273","post_code":"08011","name":"Igli","wilaya_id":"8", "ar_name":"إقلي", "longitude":"30.452849", "latitude":"-2.3000801"},
{"id":"274","post_code":"08012","name":"Tabalbala","wilaya_id":"8", "ar_name":"تبلبالة", "longitude":"28.9735664", "latitude":"-5.9957642"},
{"id":"275","post_code":"08013","name":"Taghit","wilaya_id":"8", "ar_name":"تــــاغـيــث", "longitude":"30.9150725", "latitude":"-2.0472123"},
{"id":"276","post_code":"08014","name":"El Ouata","wilaya_id":"8", "ar_name":"الوطى", "longitude":"29.854111", "latitude":"-1.8650158"},
{"id":"277","post_code":"08015","name":"Boukais","wilaya_id":"8", "ar_name":"بوكايس", "longitude":"31.9230182", "latitude":"-2.4714058"},
{"id":"278","post_code":"08016","name":"Mogheul","wilaya_id":"8", "ar_name":"موغل", "longitude":"32.0219943", "latitude":"-2.2227252"},
{"id":"279","post_code":"08017","name":"Abadla","wilaya_id":"8", "ar_name":"العبادلة", "longitude":"31.0174866", "latitude":"-2.7615494"},
{"id":"280","post_code":"08018","name":"Kerzaz","wilaya_id":"8", "ar_name":"كرزاز", "longitude":"29.4602231", "latitude":"-1.4553024"},
{"id":"281","post_code":"08019","name":"Ksabi","wilaya_id":"8", "ar_name":"قصابى", "longitude":"29.0984238", "latitude":"-1.0022456"},
{"id":"282","post_code":"08020","name":"Tamtert","wilaya_id":"8", "ar_name":"تامترت", "longitude":"29.9166662", "latitude":"-1.9087548"},
{"id":"283","post_code":"08021","name":"Beni Ounif","wilaya_id":"8", "ar_name":"بني ونيف", "longitude":"32.0512638", "latitude":"-1.2519046"},
{"id":"284","post_code":"09001","name":"Blida","wilaya_id":"9", "ar_name":"البليدة‎", "longitude":"36.4802972", "latitude":"2.7659184"},
{"id":"285","post_code":"09002","name":"Chebli","wilaya_id":"9", "ar_name":"الشبلي", "longitude":"36.5685763", "latitude":"2.9433463"},
{"id":"286","post_code":"09003","name":"Bouinan","wilaya_id":"9", "ar_name":"بوعينان", "longitude":"36.5154839", "latitude":"2.9218243"},
{"id":"287","post_code":"09004","name":"Oued El Alleug","wilaya_id":"9", "ar_name":"واد العلايڨ", "longitude":"36.5502875", "latitude":"2.7026126"},
{"id":"288","post_code":"09007","name":"Ouled Yaich","wilaya_id":"9", "ar_name":"اولاد يعيش", "longitude":"36.4852123", "latitude":"2.8325741"},
{"id":"289","post_code":"09008","name":"Chrea","wilaya_id":"9", "ar_name":"الشريعة", "longitude":"36.4254896", "latitude":"2.8286336"},
{"id":"290","post_code":"09010","name":"El Affroun","wilaya_id":"9", "ar_name":"العفرون", "longitude":"36.4460675", "latitude":"2.5382873"},
{"id":"291","post_code":"09011","name":"Chiffa","wilaya_id":"9", "ar_name":"الشفة", "longitude":"36.444398", "latitude":"2.6691173"},
{"id":"292","post_code":"09012","name":"Hammam Melouane","wilaya_id":"9", "ar_name":"حمام ملوان", "longitude":"36.436102", "latitude":"2.8604689"},
{"id":"293","post_code":"09013","name":"Ben Khlil","wilaya_id":"9", "ar_name":"بني خليل", "longitude":"36.5959588", "latitude":"2.7838729"},
{"id":"294","post_code":"09014","name":"Soumaa","wilaya_id":"9", "ar_name":"صومعة", "longitude":"36.507015", "latitude":"2.8509281"},
{"id":"295","post_code":"09016","name":"Mouzaia","wilaya_id":"9", "ar_name":"موزاية", "longitude":"36.50497", "latitude":"2.6139932"},
{"id":"296","post_code":"09017","name":"Souhane","wilaya_id":"9", "ar_name":"صوحان", "longitude":"36.5263305", "latitude":"3.1705831"},
{"id":"297","post_code":"09018","name":"Meftah","wilaya_id":"9", "ar_name":"مفتاح", "longitude":"36.6238272", "latitude":"3.1711838"},
{"id":"298","post_code":"09019","name":"Ouled Selama","wilaya_id":"9", "ar_name":"أولاد سلامة", "longitude":"36.514057", "latitude":"3.0705904"},
{"id":"299","post_code":"09020","name":"Boufarik","wilaya_id":"9", "ar_name":"بوفاريك", "longitude":"36.5739581", "latitude":"2.8478167"},
{"id":"300","post_code":"09021","name":"Larbaa","wilaya_id":"9", "ar_name":"الاربعاء", "longitude":"36.5787079", "latitude":"3.103324"},
{"id":"301","post_code":"09022","name":"Oued Djer","wilaya_id":"9", "ar_name":"واد جر", "longitude":"36.4223526", "latitude":"2.4657911"},
{"id":"302","post_code":"09023","name":"Beni Tamou","wilaya_id":"9", "ar_name":"بني تامو", "longitude":"36.5401306", "latitude":"2.7729529"},
{"id":"303","post_code":"09024","name":"Bouarfa","wilaya_id":"9", "ar_name":"بوعرفة", "longitude":"36.4151693", "latitude":"2.7284692"},
{"id":"304","post_code":"09025","name":"Beni Mered","wilaya_id":"9", "ar_name":"بني مراد", "longitude":"36.5327654", "latitude":"2.8198593"},
{"id":"305","post_code":"09026","name":"Bougara","wilaya_id":"9", "ar_name":"بوڨرة", "longitude":"36.4936597", "latitude":"2.9731862"},
{"id":"306","post_code":"09027","name":"Guerrouaou","wilaya_id":"9", "ar_name":"ڨرواو", "longitude":"36.5019935", "latitude":"2.8148899"},
{"id":"307","post_code":"09028","name":"Ain Romana","wilaya_id":"9", "ar_name":"عين الرمانة", "longitude":"36.3875985", "latitude":"2.5722144"},
{"id":"308","post_code":"09029","name":"Djebabra","wilaya_id":"9", "ar_name":"جبابرة", "longitude":"36.5803885", "latitude":"3.2252592"},
{"id":"309","post_code":"10001","name":"Bouira","wilaya_id":"10", "ar_name":"البويرة", "longitude":"36.3728066", "latitude":"3.8350413"},
{"id":"310","post_code":"10002","name":"El Asnam","wilaya_id":"10", "ar_name":"الأصنام", "longitude":"36.3709809", "latitude":"3.8941923"},
{"id":"311","post_code":"10003","name":"Guerrouma","wilaya_id":"10", "ar_name":"قرومة", "longitude":"36.4678041", "latitude":"3.3603546"},
{"id":"312","post_code":"10004","name":"Souk El Khemis","wilaya_id":"10", "ar_name":"سوق الخميس", "longitude":"36.3941664", "latitude":"3.5550187"},
{"id":"313","post_code":"10005","name":"Kadiria","wilaya_id":"10", "ar_name":"قادرية", "longitude":"36.5253163", "latitude":"3.5963676"},
{"id":"314","post_code":"10006","name":"Hanif","wilaya_id":"10", "ar_name":"احنيف", "longitude":"36.2632628", "latitude":"4.0687934"},
{"id":"315","post_code":"10007","name":"Dirah","wilaya_id":"10", "ar_name":"ديــرة", "longitude":"36.0038981", "latitude":"3.5835066"},
{"id":"316","post_code":"10008","name":"Ait Laaziz","wilaya_id":"10", "ar_name":"آيت لعزيز", "longitude":"36.4453381", "latitude":"3.8484757"},
{"id":"317","post_code":"10009","name":"Taghzout","wilaya_id":"10", "ar_name":"تاغزوت", "longitude":"36.4161178", "latitude":"3.9036414"},
{"id":"318","post_code":"10010","name":"Raouraoua","wilaya_id":"10", "ar_name":"الروراوة", "longitude":"36.2168126", "latitude":"3.5492681"},
{"id":"319","post_code":"10011","name":"Mezdour","wilaya_id":"10", "ar_name":"مسدور", "longitude":"36.0722819", "latitude":"3.923954"},
{"id":"320","post_code":"10012","name":"Haizer","wilaya_id":"10", "ar_name":"حيزر", "longitude":"36.4068708", "latitude":"3.9487242"},
{"id":"321","post_code":"10013","name":"Lakhdaria","wilaya_id":"10", "ar_name":"الأخضرية", "longitude":"36.5613265", "latitude":"3.5173819"},
{"id":"322","post_code":"10014","name":"Maala","wilaya_id":"10", "ar_name":"معالة", "longitude":"36.4945576", "latitude":"3.4880279"},
{"id":"323","post_code":"10015","name":"El Hachimia","wilaya_id":"10", "ar_name":"الھاشمية", "longitude":"36.2227385", "latitude":"3.7198058"},
{"id":"324","post_code":"10016","name":"Aomar","wilaya_id":"10", "ar_name":"أعمر", "longitude":"36.5022135", "latitude":"3.7122176"},
{"id":"325","post_code":"10017","name":"Chorfa","wilaya_id":"10", "ar_name":"الشرفاء", "longitude":"36.3782491", "latitude":"4.3082238"},
{"id":"326","post_code":"10018","name":"Bordj Oukhriss","wilaya_id":"10", "ar_name":"برج أوخريص", "longitude":"36.09281", "latitude":"3.8468001"},
{"id":"327","post_code":"10019","name":"El Adjiba","wilaya_id":"10", "ar_name":"العجيبة", "longitude":"36.3723036", "latitude":"4.0139476"},
{"id":"328","post_code":"10020","name":"El Hakimia","wilaya_id":"10", "ar_name":"الحاكمية", "longitude":"36.0907204", "latitude":"3.72668"},
{"id":"329","post_code":"10021","name":"El Khebouzia","wilaya_id":"10", "ar_name":"الخبوزية", "longitude":"36.3091266", "latitude":"3.5599129"},
{"id":"330","post_code":"10022","name":"Ahl El Ksar","wilaya_id":"10", "ar_name":"أھل القصر", "longitude":"36.2426022", "latitude":"3.9606117"},
{"id":"331","post_code":"10023","name":"Bouderbala","wilaya_id":"10", "ar_name":"بودربالة", "longitude":"36.5662885", "latitude":"3.4137415"},
{"id":"332","post_code":"10024","name":"Zbarbar","wilaya_id":"10", "ar_name":"زبربر", "longitude":"36.4732903", "latitude":"3.4459708"},
{"id":"333","post_code":"10025","name":"Ain El Hadjar","wilaya_id":"10", "ar_name":"عين الحجر", "longitude":"36.3598252", "latitude":"3.7160585"},
{"id":"334","post_code":"10026","name":"Djebahia","wilaya_id":"10", "ar_name":"الجباحية", "longitude":"36.4603324", "latitude":"3.6520932"},
{"id":"335","post_code":"10027","name":"Aghbalou","wilaya_id":"10", "ar_name":"أغبالو", "longitude":"36.4259787", "latitude":"4.2679284"},
{"id":"336","post_code":"10028","name":"Taguedit","wilaya_id":"10", "ar_name":"تاڨديت", "longitude":"35.9534858", "latitude":"3.7969676"},
{"id":"337","post_code":"10029","name":"Ain Turk","wilaya_id":"10", "ar_name":"عين الترك", "longitude":"36.4049144", "latitude":"3.7447465"},
{"id":"338","post_code":"10030","name":"Saharidj","wilaya_id":"10", "ar_name":"الصهاريج", "longitude":"36.4310532", "latitude":"4.1825268"},
{"id":"339","post_code":"10031","name":"Dechmia","wilaya_id":"10", "ar_name":"الدشمية", "longitude":"36.1366443", "latitude":"3.5034345"},
{"id":"340","post_code":"10032","name":"Ridane","wilaya_id":"10", "ar_name":"ريدان", "longitude":"36.0701573", "latitude":"3.399751"},
{"id":"341","post_code":"10033","name":"Bechloul","wilaya_id":"10", "ar_name":"بشلول", "longitude":"36.3745093", "latitude":"3.9607305"},
{"id":"342","post_code":"10034","name":"Boukram","wilaya_id":"10", "ar_name":"بوكرام", "longitude":"36.5178782", "latitude":"3.2987712"},
{"id":"343","post_code":"10035","name":"Ain Bessam","wilaya_id":"10", "ar_name":"عين بسام", "longitude":"36.3364461", "latitude":"3.5562554"},
{"id":"344","post_code":"10036","name":"Bir Ghbalou","wilaya_id":"10", "ar_name":"بئر غبالو", "longitude":"36.268461", "latitude":"3.5073827"},
{"id":"345","post_code":"10037","name":"Mchedallah","wilaya_id":"10", "ar_name":"مشدا الله", "longitude":"36.3633061", "latitude":"4.1871831"},
{"id":"346","post_code":"10038","name":"Sour El Ghozlane","wilaya_id":"10", "ar_name":"سور الغزلان", "longitude":"36.1694764", "latitude":"3.55647"},
{"id":"347","post_code":"10039","name":"Maamora","wilaya_id":"10", "ar_name":"المعمورة", "longitude":"35.9979399", "latitude":"3.525493"},
{"id":"348","post_code":"10040","name":"Ouled Rached","wilaya_id":"10", "ar_name":"أولاد راشد", "longitude":"36.2020432", "latitude":"3.9321509"},
{"id":"349","post_code":"10041","name":"Ain Laloui","wilaya_id":"10", "ar_name":"عين العلوي", "longitude":"36.3067411", "latitude":"3.6860828"},
{"id":"350","post_code":"10042","name":"Hadjera Zerga","wilaya_id":"10", "ar_name":"الحجرة الزرقاء", "longitude":"35.9465348", "latitude":"3.6906234"},
{"id":"351","post_code":"10043","name":"Ath Mansour","wilaya_id":"10", "ar_name":"آث منصور", "longitude":"36.2991769", "latitude":"4.2484448"},
{"id":"352","post_code":"10044","name":"El Mokrani","wilaya_id":"10", "ar_name":"المقراني", "longitude":"36.4249768", "latitude":"3.5341619"},
{"id":"353","post_code":"10045","name":"Oued El Berdi","wilaya_id":"10", "ar_name":"وادى البردي", "longitude":"36.257285", "latitude":"3.8546965"},
{"id":"354","post_code":"11001","name":"Tamanghasset","wilaya_id":"11", "ar_name":"تمنراست", "longitude":"22.2707733", "latitude":"4.5540574"},
{"id":"355","post_code":"11002","name":"Abalessa","wilaya_id":"11", "ar_name":"أبلسة", "longitude":"21.984742", "latitude":"2.2077874"},
{"id":"356","post_code":"11003","name":"In Ghar","wilaya_id":"11", "ar_name":"عـيـن غــار", "longitude":"27.1037909", "latitude":"1.8904637"},
{"id":"357","post_code":"11004","name":"In Guezzam","wilaya_id":"11", "ar_name":"عين قزام", "longitude":"20.3875487", "latitude":"4.7892187"},
{"id":"358","post_code":"11005","name":"Idles","wilaya_id":"11", "ar_name":"إدلس", "longitude":"23.8174829", "latitude":"5.9250425"},
{"id":"359","post_code":"11006","name":"Tazouk","wilaya_id":"11", "ar_name":"تاظروك", "longitude":"23.4241274", "latitude":"6.2425195"},
{"id":"360","post_code":"11007","name":"Tinzaouatine","wilaya_id":"11", "ar_name":"تين زاوتين", "longitude":"20.4281421", "latitude":"2.0907022"},
{"id":"361","post_code":"11008","name":"In Salah","wilaya_id":"11", "ar_name":"عين صالح", "longitude":"27.1955358", "latitude":"2.4666218"},
{"id":"362","post_code":"11009","name":"In Amguel","wilaya_id":"11", "ar_name":"ان أمقل", "longitude":"24.9098562", "latitude":"1.2189579"},
{"id":"363","post_code":"11010","name":"Foggaret Ezzaouia","wilaya_id":"11", "ar_name":"فقارة الزوى", "longitude":"27.3604139", "latitude":"2.8384638"},
{"id":"364","post_code":"12001","name":"Tebessa","wilaya_id":"12", "ar_name":"تبسة", "longitude":"35.4117209", "latitude":"8.0755254"},
{"id":"365","post_code":"12002","name":"Bir El Ater","wilaya_id":"12", "ar_name":"بئر العاتر", "longitude":"34.7546133", "latitude":"8.0234307"},
{"id":"366","post_code":"12003","name":"Cheria","wilaya_id":"12", "ar_name":"الــشــريــعـة", "longitude":"35.267904", "latitude":"7.7355765"},
{"id":"367","post_code":"12004","name":"Stah Guentis","wilaya_id":"12", "ar_name":"سطح قنطيس", "longitude":"34.9951892", "latitude":"7.2928362"},
{"id":"368","post_code":"12005","name":"El Aouinet","wilaya_id":"12", "ar_name":"العوينات", "longitude":"35.8685842", "latitude":"7.8693435"},
{"id":"369","post_code":"12006","name":"Lahouidjbet","wilaya_id":"12", "ar_name":"الحويجبات", "longitude":"35.292789", "latitude":"8.274572"},
{"id":"370","post_code":"12007","name":"Safsaf El Ouesra","wilaya_id":"12", "ar_name":"صفصاف الوسرة", "longitude":"34.9570976", "latitude":"8.1988049"},
{"id":"371","post_code":"12008","name":"Hammamet","wilaya_id":"12", "ar_name":"الحمامات", "longitude":"35.4519226", "latitude":"7.923867"},
{"id":"372","post_code":"12009","name":"Negrine","wilaya_id":"12", "ar_name":"نقرين", "longitude":"34.485828", "latitude":"7.5028466"},
{"id":"373","post_code":"12010","name":"Bir El Mokadem","wilaya_id":"12", "ar_name":"بــئــر مــقـدم", "longitude":"35.3704867", "latitude":"7.8005075"},
{"id":"374","post_code":"12011","name":"El Kouif","wilaya_id":"12", "ar_name":"الكويف", "longitude":"35.499565", "latitude":"8.3115219"},
{"id":"375","post_code":"12012","name":"Morsott","wilaya_id":"12", "ar_name":"مرسط", "longitude":"35.6694643", "latitude":"8.0015659"},
{"id":"376","post_code":"12013","name":"El Ogla","wilaya_id":"12", "ar_name":"العقلة", "longitude":"35.1833322", "latitude":"7.4579122"},
{"id":"377","post_code":"12014","name":"Bir Dheb","wilaya_id":"12", "ar_name":"بٔير الذھب", "longitude":"35.4117259", "latitude":"8.110545"},
{"id":"378","post_code":"12015","name":"El Ogla","wilaya_id":"12", "ar_name":"العقلة", "longitude":"35.1833322", "latitude":"7.4579122"},
{"id":"379","post_code":"12016","name":"Gorriguer","wilaya_id":"12", "ar_name":"قوريقر", "longitude":"35.1833322", "latitude":"7.4579122"},
{"id":"380","post_code":"12017","name":"Bekkaria","wilaya_id":"12", "ar_name":"بكارية", "longitude":"35.3666661", "latitude":"8.2412452"},
{"id":"381","post_code":"12018","name":"Boukhadra","wilaya_id":"12", "ar_name":"بوخضرة", "longitude":"35.7490236", "latitude":"8.0267572"},
{"id":"382","post_code":"12019","name":"Ouenza","wilaya_id":"12", "ar_name":"الونزة", "longitude":"35.9421139", "latitude":"8.1088536"},
{"id":"383","post_code":"12020","name":"El Ma El Biodh","wilaya_id":"12", "ar_name":"الماء الأبيض", "longitude":"35.2066339", "latitude":"8.1509541"},
{"id":"384","post_code":"12021","name":"Oum Ali","wilaya_id":"12", "ar_name":"أم على", "longitude":"35.0115532", "latitude":"8.2926392"},
{"id":"385","post_code":"12022","name":"Tlidjene","wilaya_id":"12", "ar_name":"ثليجان", "longitude":"35.1184512", "latitude":"7.7597808"},
{"id":"386","post_code":"12023","name":"Ain Zerga","wilaya_id":"12", "ar_name":"عين الزرقاء", "longitude":"35.6333322", "latitude":"8.2579122"},
{"id":"387","post_code":"12024","name":"El Meridj","wilaya_id":"12", "ar_name":"المريج", "longitude":"35.7935581", "latitude":"8.2175374"},
{"id":"388","post_code":"12025","name":"Boulhaf Dyr","wilaya_id":"12", "ar_name":"بولحاف الدير", "longitude":"35.4117259", "latitude":"8.110545"},
{"id":"389","post_code":"12026","name":"Bedjene","wilaya_id":"12", "ar_name":"بجن", "longitude":"35.3487094", "latitude":"7.469641"},
{"id":"390","post_code":"12027","name":"El Mazeraa","wilaya_id":"12", "ar_name":"المزرعة", "longitude":"35.4117259", "latitude":"8.110545"},
{"id":"391","post_code":"12028","name":"Ferkane","wilaya_id":"12", "ar_name":"فـــــــركـــــان", "longitude":"34.557943", "latitude":"7.3995494"},
{"id":"392","post_code":"13001","name":"Tlemcen","wilaya_id":"13", "ar_name":"تلمسان", "longitude":"34.8959491", "latitude":"-1.3501175"},
{"id":"393","post_code":"13002","name":"Beni Mester","wilaya_id":"13", "ar_name":"بني مستار", "longitude":"34.8639575", "latitude":"-1.4356042"},
{"id":"394","post_code":"13003","name":"Ain Tallout","wilaya_id":"13", "ar_name":"عين تالوت", "longitude":"34.9238688", "latitude":"-0.9667326"},
{"id":"395","post_code":"13004","name":"Remchi","wilaya_id":"13", "ar_name":"الرمشي", "longitude":"35.0550485", "latitude":"-1.4635847"},
{"id":"396","post_code":"13005","name":"El Fehoul","wilaya_id":"13", "ar_name":"الفحول", "longitude":"35.1166788", "latitude":"-1.2970948"},
{"id":"397","post_code":"13006","name":"Sabra","wilaya_id":"13", "ar_name":"صبرة", "longitude":"34.8255654", "latitude":"-1.5368414"},
{"id":"398","post_code":"13007","name":"Ghazaouet","wilaya_id":"13", "ar_name":"الغزوات", "longitude":"35.0934337", "latitude":"-1.8720748"},
{"id":"399","post_code":"13008","name":"Souani","wilaya_id":"13", "ar_name":"السواني", "longitude":"34.9184687", "latitude":"-1.92235"},
{"id":"400","post_code":"13009","name":"Djebala","wilaya_id":"13", "ar_name":"جبالة", "longitude":"34.9775481", "latitude":"-1.8940714"},
{"id":"401","post_code":"13010","name":"El Gor","wilaya_id":"13", "ar_name":"الغور", "longitude":"34.6356392", "latitude":"-1.1625071"},
{"id":"402","post_code":"13011","name":"Oued Chouly","wilaya_id":"13", "ar_name":"وادى الشولى", "longitude":"34.8666661", "latitude":"-1.1420878"},
{"id":"403","post_code":"13012","name":"Ain Fezza","wilaya_id":"13", "ar_name":"عين فزّة", "longitude":"34.8794264", "latitude":"-1.2421418"},
{"id":"404","post_code":"13013","name":"Ouled Mimoun","wilaya_id":"13", "ar_name":"أولاد ميمون", "longitude":"34.9018053", "latitude":"-1.0442162"},
{"id":"405","post_code":"13014","name":"Amieur","wilaya_id":"13", "ar_name":"عمير", "longitude":"34.8959541", "latitude":"-1.3150979"},
{"id":"406","post_code":"13015","name":"Ain Youcef","wilaya_id":"13", "ar_name":"عين يوسف", "longitude":"35.048443", "latitude":"-1.3841379"},
{"id":"407","post_code":"13016","name":"Zenata","wilaya_id":"13", "ar_name":"زناتة", "longitude":"34.9849042", "latitude":"-1.4760198"},
{"id":"408","post_code":"13017","name":"Beni Snous","wilaya_id":"13", "ar_name":"بنى سنوس", "longitude":"34.6593315", "latitude":"-1.5615608"},
{"id":"409","post_code":"13018","name":"Bab El Assa","wilaya_id":"13", "ar_name":"باب العسة", "longitude":"34.9654779", "latitude":"-2.0399109"},
{"id":"410","post_code":"13019","name":"Dar Yaghmouracene","wilaya_id":"13", "ar_name":"دار يغمراسن", "longitude":"34.8959541", "latitude":"-1.3150979"},
{"id":"411","post_code":"13020","name":"Fellaoucene","wilaya_id":"13", "ar_name":"فلاوسن", "longitude":"35.013723", "latitude":"-1.6538313"},
{"id":"412","post_code":"13021","name":"Azails","wilaya_id":"13", "ar_name":"العزايل", "longitude":"34.8959541", "latitude":"-1.3150979"},
{"id":"413","post_code":"13022","name":"Sebbaa Chioukh","wilaya_id":"13", "ar_name":"سبعة شيوخ", "longitude":"35.1563716", "latitude":"-1.3705445"},
{"id":"414","post_code":"13023","name":"Terni Beni Hediel","wilaya_id":"13", "ar_name":"تيرني بني هديل", "longitude":"34.7966361", "latitude":"-1.3623785"},
{"id":"415","post_code":"13024","name":"Bensekrane","wilaya_id":"13", "ar_name":"بن سكران", "longitude":"35.0745555", "latitude":"-1.2362705"},
{"id":"416","post_code":"13025","name":"Ain Nehala","wilaya_id":"13", "ar_name":"عين نحالة", "longitude":"35.0273782", "latitude":"-0.9359407"},
{"id":"417","post_code":"13026","name":"Hennaya","wilaya_id":"13", "ar_name":"الحناية", "longitude":"34.9490231", "latitude":"-1.3866808"},
{"id":"418","post_code":"13027","name":"Maghnia","wilaya_id":"13", "ar_name":"مغنية", "longitude":"34.8481781", "latitude":"-1.780215"},
{"id":"419","post_code":"13028","name":"Hammam Boughrara","wilaya_id":"13", "ar_name":"حمام بوغرارة", "longitude":"34.8904539", "latitude":"-1.6555881"},
{"id":"420","post_code":"13029","name":"Souahlia","wilaya_id":"13", "ar_name":"تونان", "longitude":"35.0438386", "latitude":"-1.9086172"},
{"id":"421","post_code":"13030","name":"Msirda Fouaga","wilaya_id":"13", "ar_name":"مسيردة الفواقة", "longitude":"35.0142663", "latitude":"-2.073219"},
{"id":"422","post_code":"13031","name":"Ain Fetah","wilaya_id":"13", "ar_name":"عين فتاح", "longitude":"36.0036103", "latitude":"0.3170785"},
{"id":"423","post_code":"13032","name":"El Aricha","wilaya_id":"13", "ar_name":"العريشة", "longitude":"34.2202108", "latitude":"-1.2723972"},
{"id":"424","post_code":"13033","name":"Souk Thlata","wilaya_id":"13", "ar_name":"سوق الثلاثاء", "longitude":"35.0194848", "latitude":"-2.0213987"},
{"id":"425","post_code":"13034","name":"Sidi Abdelli","wilaya_id":"13", "ar_name":"سيدي العبدلي", "longitude":"35.0652344", "latitude":"-1.1432004"},
{"id":"426","post_code":"13035","name":"Sebdou","wilaya_id":"13", "ar_name":"سبدو", "longitude":"34.6392357", "latitude":"-1.3469271"},
{"id":"427","post_code":"13036","name":"Beni Ouarsous","wilaya_id":"13", "ar_name":"برج عريمة", "longitude":"35.0818832", "latitude":"-1.5645648"},
{"id":"428","post_code":"13037","name":"Sidi Medjahed","wilaya_id":"13", "ar_name":"سيدي مجاهد", "longitude":"34.77568", "latitude":"-1.6426706"},
{"id":"429","post_code":"13038","name":"Beni Boussaid","wilaya_id":"13", "ar_name":"بني بوسعيد", "longitude":"34.6490781", "latitude":"-1.762104"},
{"id":"430","post_code":"13039","name":"Marsa Ben Mhidi","wilaya_id":"13", "ar_name":"مرسى بن مھيدي", "longitude":"35.0790982", "latitude":"-2.2135629"},
{"id":"431","post_code":"13040","name":"Nedroma","wilaya_id":"13", "ar_name":"ندرومة", "longitude":"35.0141436", "latitude":"-1.769829"},
{"id":"432","post_code":"13041","name":"Sidi Djillali","wilaya_id":"13", "ar_name":"سيدي الجيلالي", "longitude":"34.4459186", "latitude":"-1.5761732"},
{"id":"433","post_code":"13042","name":"Beni Bahdel","wilaya_id":"13", "ar_name":"بني بهدل", "longitude":"34.7114024", "latitude":"-1.5174008"},
{"id":"434","post_code":"13043","name":"El Bouihi","wilaya_id":"13", "ar_name":"البويھي", "longitude":"34.8959541", "latitude":"-1.3150979"},
{"id":"435","post_code":"13044","name":"Honaine","wilaya_id":"13", "ar_name":"هنين", "longitude":"35.1800515", "latitude":"-1.657691"},
{"id":"436","post_code":"13045","name":"Tianet","wilaya_id":"13", "ar_name":"تيانت", "longitude":"35.0491084", "latitude":"-1.8584921"},
{"id":"437","post_code":"13046","name":"Ouled Riyah","wilaya_id":"13", "ar_name":"أولاد رياح", "longitude":"34.9615122", "latitude":"-1.5020585"},
{"id":"438","post_code":"13047","name":"Bouhlou","wilaya_id":"13", "ar_name":"بوحلو", "longitude":"34.7787462", "latitude":"-1.5850461"},
{"id":"439","post_code":"13048","name":"Souk El Khemis","wilaya_id":"13", "ar_name":"سوق الخميس", "longitude":"35.1732293", "latitude":"-1.5607239"},
{"id":"440","post_code":"13049","name":"Ain Ghoraba","wilaya_id":"13", "ar_name":"عين غرابة", "longitude":"34.7147251", "latitude":"-1.4039435"},
{"id":"441","post_code":"13050","name":"Chetouane","wilaya_id":"13", "ar_name":"شتوان", "longitude":"34.9175348", "latitude":"-1.3164927"},
{"id":"442","post_code":"13051","name":"Mansourah","wilaya_id":"13", "ar_name":"المنصورة", "longitude":"34.8704299", "latitude":"-1.389299"},
{"id":"443","post_code":"13052","name":"Beni Semiel","wilaya_id":"13", "ar_name":"بني مستار", "longitude":"34.8639575", "latitude":"-1.4356042"},
{"id":"444","post_code":"13053","name":"Ain Kebira","wilaya_id":"13", "ar_name":"عين الكبيرة", "longitude":"35.0317001", "latitude":"-1.686101"},
{"id":"445","post_code":"14001","name":"Tiaret","wilaya_id":"14", "ar_name":"تيارت‎", "longitude":"35.3599696", "latitude":"1.3215754"},
{"id":"446","post_code":"14002","name":"Medroussa","wilaya_id":"14", "ar_name":"مدروسة", "longitude":"35.1752197", "latitude":"1.1979175"},
{"id":"447","post_code":"14003","name":"Ain Bouchekif","wilaya_id":"14", "ar_name":"بوشقيف", "longitude":"35.3543704", "latitude":"1.5036248"},
{"id":"448","post_code":"14004","name":"Sidi Ali Mellal","wilaya_id":"14", "ar_name":"سيدي علي ملال", "longitude":"35.5635054", "latitude":"1.2199974"},
{"id":"449","post_code":"14005","name":"Ain Zarit","wilaya_id":"14", "ar_name":"عين زاريت", "longitude":"35.351973", "latitude":"1.6572618"},
{"id":"450","post_code":"14006","name":"Ain Deheb","wilaya_id":"14", "ar_name":"عين الذهب", "longitude":"34.847125", "latitude":"1.530876"},
{"id":"451","post_code":"14007","name":"Sidi Bakhti","wilaya_id":"14", "ar_name":"سيدي بختي", "longitude":"35.2393277", "latitude":"0.9755382"},
{"id":"452","post_code":"14008","name":"Medrissa","wilaya_id":"14", "ar_name":"مدريسة", "longitude":"34.8949768", "latitude":"1.2315845"},
{"id":"453","post_code":"14009","name":"Zmalet El Emir Aek","wilaya_id":"14", "ar_name":"زمالة الأمير عبد القادر", "longitude":"34.899755", "latitude":"2.279362"},
{"id":"454","post_code":"14010","name":"Madna","wilaya_id":"14", "ar_name":"مادنة", "longitude":"35.3599899", "latitude":"1.3916159"},
{"id":"455","post_code":"14011","name":"Sebt","wilaya_id":"14", "ar_name":"السبت", "longitude":"35.3599899", "latitude":"1.3916159"},
{"id":"456","post_code":"14012","name":"Mellakou","wilaya_id":"14", "ar_name":"ملاكو", "longitude":"35.2514714", "latitude":"1.2272501"},
{"id":"457","post_code":"14013","name":"Dahmouni","wilaya_id":"14", "ar_name":"دحموني", "longitude":"35.4166137", "latitude":"1.4674473"},
{"id":"458","post_code":"14014","name":"Rahouia","wilaya_id":"14", "ar_name":"رحوية", "longitude":"35.5308956", "latitude":"1.0130642"},
{"id":"459","post_code":"14015","name":"Mahdia","wilaya_id":"14", "ar_name":"المھدية", "longitude":"35.4312648", "latitude":"1.7415474"},
{"id":"460","post_code":"14016","name":"Sougueur","wilaya_id":"14", "ar_name":"سوقر", "longitude":"35.1812014", "latitude":"1.4648287"},
{"id":"461","post_code":"14017","name":"Sidi Abdelghani","wilaya_id":"14", "ar_name":"سيدي عبد الغنى", "longitude":"35.2355935", "latitude":"1.6199474"},
{"id":"462","post_code":"14018","name":"Ain El Hadid","wilaya_id":"14", "ar_name":"عين الحديد", "longitude":"35.0604573", "latitude":"0.8713103"},
{"id":"463","post_code":"14019","name":"Ouled Djerad","wilaya_id":"14", "ar_name":"اولاد جراد", "longitude":"35.3599899", "latitude":"1.3916159"},
{"id":"464","post_code":"14020","name":"Naima","wilaya_id":"14", "ar_name":"نعيمة", "longitude":"35.092594", "latitude":"1.4729726"},
{"id":"465","post_code":"14021","name":"Meghila","wilaya_id":"14", "ar_name":"مغيلة", "longitude":"35.3633423", "latitude":"1.3249683"},
{"id":"466","post_code":"14022","name":"Guertoufa","wilaya_id":"14", "ar_name":"قرطوفة", "longitude":"35.3929502", "latitude":"1.246283"},
{"id":"467","post_code":"14023","name":"Sidi Hosni","wilaya_id":"14", "ar_name":"سيدي حسني", "longitude":"35.4697136", "latitude":"1.5114355"},
{"id":"468","post_code":"14024","name":"Djillali Ben Amar","wilaya_id":"14", "ar_name":"جيلالي بن عمار", "longitude":"35.4442917", "latitude":"0.8475566"},
{"id":"469","post_code":"14025","name":"Sebaine","wilaya_id":"14", "ar_name":"سبعين", "longitude":"35.4574363", "latitude":"1.5985322"},
{"id":"470","post_code":"14026","name":"Tousnina","wilaya_id":"14", "ar_name":"توسنينة", "longitude":"35.1140017", "latitude":"1.2658846"},
{"id":"471","post_code":"14027","name":"Frenda","wilaya_id":"14", "ar_name":"فرندة", "longitude":"35.0625636", "latitude":"1.0403751"},
{"id":"472","post_code":"14028","name":"Ain Kermes","wilaya_id":"14", "ar_name":"عين كرمس", "longitude":"34.9297691", "latitude":"1.00103"},
{"id":"473","post_code":"14029","name":"Ksar Chellala","wilaya_id":"14", "ar_name":"قصر الشلالة", "longitude":"35.2197113", "latitude":"2.3002837"},
{"id":"474","post_code":"14030","name":"Rechaiga","wilaya_id":"14", "ar_name":"الرشايقة", "longitude":"35.4099423", "latitude":"1.9612956"},
{"id":"475","post_code":"14031","name":"Nadorah","wilaya_id":"14", "ar_name":"ملاكو", "longitude":"35.2514714", "latitude":"1.2272501"},
{"id":"476","post_code":"14032","name":"Tagdemt","wilaya_id":"14", "ar_name":"تاقدمت", "longitude":"35.3354988", "latitude":"1.2243641"},
{"id":"477","post_code":"14033","name":"Oued Lilli","wilaya_id":"14", "ar_name":"وادى ليلى", "longitude":"35.5119281", "latitude":"1.2582952"},
{"id":"478","post_code":"14034","name":"Mechraa Safa","wilaya_id":"14", "ar_name":"مشرع الصفاء", "longitude":"35.3599899", "latitude":"1.3916159"},
{"id":"479","post_code":"14035","name":"Hamadia","wilaya_id":"14", "ar_name":"الحمادية", "longitude":"35.4612984", "latitude":"1.8652296"},
{"id":"480","post_code":"14036","name":"Chehaima","wilaya_id":"14", "ar_name":"شحيمة", "longitude":"34.897482", "latitude":"1.2948222"},
{"id":"481","post_code":"14037","name":"Takhemaret","wilaya_id":"14", "ar_name":"تاخمرت", "longitude":"35.1048114", "latitude":"0.6649302"},
{"id":"482","post_code":"14038","name":"Sidi Abderrahmane","wilaya_id":"14", "ar_name":"سيدي عبدالرحمان", "longitude":"34.7996374", "latitude":"1.1235237"},
{"id":"483","post_code":"14039","name":"Serghine","wilaya_id":"14", "ar_name":"سرغين", "longitude":"35.2557642", "latitude":"2.4738335"},
{"id":"484","post_code":"14040","name":"Bougara","wilaya_id":"14", "ar_name":"بوقرة", "longitude":"35.5542945", "latitude":"1.9566606"},
{"id":"485","post_code":"14041","name":"Faidja","wilaya_id":"14", "ar_name":"الفايجة", "longitude":"35.3599899", "latitude":"1.3916159"},
{"id":"486","post_code":"14042","name":"Tidda","wilaya_id":"14", "ar_name":"تيدة", "longitude":"35.583236", "latitude":"1.263181"},
{"id":"487","post_code":"15001","name":"Tizi Ouzou","wilaya_id":"15", "ar_name":"تيزي وزو", "longitude":"36.7001863", "latitude":"4.0059166"},
{"id":"488","post_code":"15002","name":"Ain El Hammam","wilaya_id":"15", "ar_name":"عين الحمام", "longitude":"36.5725501", "latitude":"4.246757"},
{"id":"489","post_code":"15003","name":"Akbil","wilaya_id":"15", "ar_name":"أقبيل", "longitude":"36.5045347", "latitude":"4.2789395"},
{"id":"490","post_code":"15004","name":"Freha","wilaya_id":"15", "ar_name":"فريحة", "longitude":"36.7609641", "latitude":"4.1820964"},
{"id":"491","post_code":"15005","name":"Souamaa","wilaya_id":"15", "ar_name":"صوامع", "longitude":"36.6699749", "latitude":"4.2847082"},
{"id":"492","post_code":"15006","name":"Mechtrass","wilaya_id":"15", "ar_name":"مشطراس", "longitude":"36.5543756", "latitude":"3.9810203"},
{"id":"493","post_code":"15007","name":"Irdjen","wilaya_id":"15", "ar_name":"إرجن", "longitude":"36.6675039", "latitude":"4.1060517"},
{"id":"494","post_code":"15008","name":"Timizart","wilaya_id":"15", "ar_name":"تيميزارت", "longitude":"36.7971206", "latitude":"4.1694482"},
{"id":"495","post_code":"15009","name":"Makouda","wilaya_id":"15", "ar_name":"ماكودة", "longitude":"36.7937385", "latitude":"4.0162747"},
{"id":"496","post_code":"15010","name":"Draa El Mizan","wilaya_id":"15", "ar_name":"ذراع الميزان", "longitude":"36.5470073", "latitude":"3.7377549"},
{"id":"497","post_code":"15011","name":"Tizi Ghenif","wilaya_id":"15", "ar_name":"تيزي غنيف", "longitude":"36.5902982", "latitude":"3.7422175"},
{"id":"498","post_code":"15012","name":"Bounouh","wilaya_id":"15", "ar_name":"بونوح", "longitude":"36.4936137", "latitude":"3.9005751"},
{"id":"499","post_code":"15013","name":"Ait Chaffaa","wilaya_id":"15", "ar_name":"آيت شفعة", "longitude":"36.8274474", "latitude":"4.4618199"},
{"id":"500","post_code":"15014","name":"Frikat","wilaya_id":"15", "ar_name":"فريقات", "longitude":"36.4984125", "latitude":"3.8441856"},
{"id":"501","post_code":"15015","name":"Beni Aissi","wilaya_id":"15", "ar_name":"بني عيسي", "longitude":"36.6547723", "latitude":"4.0545452"},
{"id":"502","post_code":"15016","name":"Beni Zmenzer","wilaya_id":"15", "ar_name":"أيت زمنزر", "longitude":"36.6377976", "latitude":"4.0034"},
{"id":"503","post_code":"15017","name":"Iferhounene","wilaya_id":"15", "ar_name":"إيفرحونن", "longitude":"36.5212265", "latitude":"4.3082031"},
{"id":"504","post_code":"15018","name":"Azazga","wilaya_id":"15", "ar_name":"عزازقة", "longitude":"36.7422819", "latitude":"4.3001578"},
{"id":"505","post_code":"15019","name":"Iloula Oumalou","wilaya_id":"15", "ar_name":"إيلولة أمالو", "longitude":"36.5648402", "latitude":"4.3743839"},
{"id":"506","post_code":"15020","name":"Yakouren","wilaya_id":"15", "ar_name":"اعكورن", "longitude":"36.7370677", "latitude":"4.3947111"},
{"id":"507","post_code":"15021","name":"Larba Nait Irathen","wilaya_id":"15", "ar_name":"الأربعاء نايت إيراثن", "longitude":"36.6369786", "latitude":"4.1448706"},
{"id":"508","post_code":"15022","name":"Tizi Rached","wilaya_id":"15", "ar_name":"تيزي راشد", "longitude":"36.6869348", "latitude":"4.1508398"},
{"id":"509","post_code":"15023","name":"Zekri","wilaya_id":"15", "ar_name":"زكري", "longitude":"36.7845712", "latitude":"4.5092844"},
{"id":"510","post_code":"15024","name":"Ouaguenoun","wilaya_id":"15", "ar_name":"واقنون", "longitude":"36.75758", "latitude":"4.1374033"},
{"id":"511","post_code":"15025","name":"Ain Zaouia","wilaya_id":"15", "ar_name":"عين الزاوية", "longitude":"36.576394", "latitude":"3.8364146"},
{"id":"512","post_code":"15026","name":"Mkira","wilaya_id":"15", "ar_name":"مكيرة", "longitude":"36.6308053", "latitude":"3.7426895"},
{"id":"513","post_code":"15027","name":"Ait Yahia","wilaya_id":"15", "ar_name":"أيت يحي", "longitude":"36.5983865", "latitude":"4.2844951"},
{"id":"514","post_code":"15028","name":"Ait Mahmoud","wilaya_id":"15", "ar_name":"أيت محمود", "longitude":"36.6099011", "latitude":"4.0829223"},
{"id":"515","post_code":"15029","name":"Maatka","wilaya_id":"15", "ar_name":"المعاتقة", "longitude":"36.6103742", "latitude":"3.9321607"},
{"id":"516","post_code":"15030","name":"Ait Boumehdi","wilaya_id":"15", "ar_name":"آيت بومھدى", "longitude":"36.4881938", "latitude":"4.1571444"},
{"id":"517","post_code":"15031","name":"Abi Youcef","wilaya_id":"15", "ar_name":"أبي يوسف", "longitude":"36.524679", "latitude":"4.3011139"},
{"id":"518","post_code":"15032","name":"Beni Douala","wilaya_id":"15", "ar_name":"بني دوالة", "longitude":"36.6234865", "latitude":"4.0457361"},
{"id":"519","post_code":"15033","name":"Illilten","wilaya_id":"15", "ar_name":"إليلتن", "longitude":"36.5107269", "latitude":"4.3705193"},
{"id":"520","post_code":"15034","name":"Bouzguen","wilaya_id":"15", "ar_name":"بوزقن", "longitude":"36.6175618", "latitude":"4.4228207"},
{"id":"521","post_code":"15035","name":"Ait Aggouacha","wilaya_id":"15", "ar_name":"أيت أقواشة", "longitude":"36.6198894", "latitude":"4.2120707"},
{"id":"522","post_code":"15036","name":"Ouadhia","wilaya_id":"15", "ar_name":"واضية", "longitude":"36.5677265", "latitude":"4.0785704"},
{"id":"523","post_code":"15037","name":"Azzefoun","wilaya_id":"15", "ar_name":"أزفون", "longitude":"36.8702256", "latitude":"4.3178818"},
{"id":"524","post_code":"15038","name":"Tigzirt","wilaya_id":"15", "ar_name":"تقزيرت", "longitude":"36.8639518", "latitude":"4.1020626"},
{"id":"525","post_code":"15039","name":"Ait Aissa Mimoun","wilaya_id":"15", "ar_name":"آيت عيسى ميمون", "longitude":"36.7514348", "latitude":"4.0819593"},
{"id":"526","post_code":"15040","name":"Boghni","wilaya_id":"15", "ar_name":"بوغني", "longitude":"36.5145938", "latitude":"3.9000151"},
{"id":"527","post_code":"15041","name":"Ifigha","wilaya_id":"15", "ar_name":"ايفيغاء", "longitude":"36.6655524", "latitude":"4.3826971"},
{"id":"528","post_code":"15042","name":"Ait Oumalou","wilaya_id":"15", "ar_name":"آيت أومالو", "longitude":"36.6546349", "latitude":"4.1929358"},
{"id":"529","post_code":"15043","name":"Tirmitine","wilaya_id":"15", "ar_name":"ترمتين", "longitude":"36.6686122", "latitude":"3.9267051"},
{"id":"530","post_code":"15044","name":"Akerrou","wilaya_id":"15", "ar_name":"أقرو", "longitude":"36.8097183", "latitude":"4.4087242"},
{"id":"531","post_code":"15045","name":"Yatafen","wilaya_id":"15", "ar_name":"يطافن", "longitude":"36.5076873", "latitude":"4.2052774"},
{"id":"532","post_code":"15046","name":"Beni Ziki","wilaya_id":"15", "ar_name":"بنى زيكى", "longitude":"36.5577577", "latitude":"4.4700776"},
{"id":"533","post_code":"15047","name":"Draa Ben Khedda","wilaya_id":"15", "ar_name":"ذراع بن خدة", "longitude":"36.7218395", "latitude":"3.9216358"},
{"id":"534","post_code":"15048","name":"Ouacif","wilaya_id":"15", "ar_name":"واسيف", "longitude":"36.518106", "latitude":"4.1780763"},
{"id":"535","post_code":"15049","name":"Idjeur","wilaya_id":"15", "ar_name":"آجر", "longitude":"36.6731533", "latitude":"4.4981712"},
{"id":"536","post_code":"15050","name":"Mekla","wilaya_id":"15", "ar_name":"مقلع", "longitude":"36.6754811", "latitude":"4.1969679"},
{"id":"537","post_code":"15051","name":"Tizi Nthlata","wilaya_id":"15", "ar_name":"تيزي نثلاثة", "longitude":"36.5614012", "latitude":"4.0110525"},
{"id":"538","post_code":"15052","name":"Beni Yenni","wilaya_id":"15", "ar_name":"بني يني", "longitude":"36.5780655", "latitude":"4.1617148"},
{"id":"539","post_code":"15053","name":"Aghrib","wilaya_id":"15", "ar_name":"أغريب", "longitude":"36.8198487", "latitude":"4.2726598"},
{"id":"540","post_code":"15054","name":"Iflissen","wilaya_id":"15", "ar_name":"إفليسن", "longitude":"36.8728441", "latitude":"4.1818421"},
{"id":"541","post_code":"15055","name":"Boudjima","wilaya_id":"15", "ar_name":"بوجيمة", "longitude":"36.8046355", "latitude":"4.1193574"},
{"id":"542","post_code":"15056","name":"Ait Yahia Moussa","wilaya_id":"15", "ar_name":"أيت يحي موسى", "longitude":"36.6423405", "latitude":"3.8041329"},
{"id":"543","post_code":"15057","name":"Souk El Thenine","wilaya_id":"15", "ar_name":"سوق الإثنين", "longitude":"36.6035414", "latitude":"3.9314975"},
{"id":"544","post_code":"15058","name":"Ait Khelil","wilaya_id":"15", "ar_name":"أيت خليلي", "longitude":"36.6760946", "latitude":"4.2387675"},
{"id":"545","post_code":"15059","name":"Sidi Naamane","wilaya_id":"15", "ar_name":"سيدي نعمان", "longitude":"36.7771939", "latitude":"3.9208277"},
{"id":"546","post_code":"15060","name":"Iboudraren","wilaya_id":"15", "ar_name":"أبودرارن", "longitude":"36.5115392", "latitude":"4.1786226"},
{"id":"547","post_code":"15061","name":"Agouni Gueghrane","wilaya_id":"15", "ar_name":"آقنى قغران", "longitude":"36.5042565", "latitude":"4.0879622"},
{"id":"548","post_code":"15062","name":"Mizrana","wilaya_id":"15", "ar_name":"مزرانة", "longitude":"36.8631777", "latitude":"4.0352004"},
{"id":"549","post_code":"15063","name":"Imsouhal","wilaya_id":"15", "ar_name":"إمسوحال", "longitude":"36.5781164", "latitude":"4.3630843"},
{"id":"550","post_code":"15064","name":"Tadmait","wilaya_id":"15", "ar_name":"تادمايت", "longitude":"36.7209434", "latitude":"3.8253437"},
{"id":"551","post_code":"15065","name":"Ait Bouadou","wilaya_id":"15", "ar_name":"أيت بوعدو", "longitude":"36.5074837", "latitude":"4.0215722"},
{"id":"552","post_code":"15066","name":"Assi Youcef","wilaya_id":"15", "ar_name":"أسي يوسف", "longitude":"36.5028276", "latitude":"3.9916789"},
{"id":"553","post_code":"15067","name":"Ait Toudert","wilaya_id":"15", "ar_name":"أيت تودرت", "longitude":"36.5308324", "latitude":"4.1318418"},
{"id":"554","post_code":"16001","name":"Alger Centre","wilaya_id":"16", "ar_name":"الجزائر الوسطى", "longitude":"36.7681618", "latitude":"3.0404258"},
{"id":"555","post_code":"16002","name":"Sidi Mhamed","wilaya_id":"16", "ar_name":"سيدي امحمد", "longitude":"36.759285", "latitude":"3.0467857"},
{"id":"556","post_code":"16003","name":"El Madania","wilaya_id":"16", "ar_name":"المدنية", "longitude":"36.7422816", "latitude":"3.0604942"},
{"id":"557","post_code":"16004","name":"Belouizdad","wilaya_id":"16", "ar_name":"بلوزداد", "longitude":"36.7504548", "latitude":"3.0639145"},
{"id":"558","post_code":"16005","name":"Bab El Oued","wilaya_id":"16", "ar_name":"باب الواد", "longitude":"36.7917802", "latitude":"3.0445432"},
{"id":"559","post_code":"16006","name":"Bologhine","wilaya_id":"16", "ar_name":"بولوغين", "longitude":"36.8039256", "latitude":"3.0296193"},
{"id":"560","post_code":"16007","name":"Casbah","wilaya_id":"16", "ar_name":"القصبة", "longitude":"36.7847303", "latitude":"3.0504945"},
{"id":"561","post_code":"16008","name":"Oued Koriche","wilaya_id":"16", "ar_name":"وادي قريش", "longitude":"36.7850295", "latitude":"3.0243678"},
{"id":"562","post_code":"16009","name":"Bir Mourad Rais","wilaya_id":"16", "ar_name":"بير مراد رايس", "longitude":"36.730271", "latitude":"3.0257462"},
{"id":"563","post_code":"16010","name":"El Biar","wilaya_id":"16", "ar_name":"الآبيار", "longitude":"36.7671977", "latitude":"3.0119382"},
{"id":"564","post_code":"16011","name":"Bouzareah","wilaya_id":"16", "ar_name":"بوزريعة", "longitude":"36.7891132", "latitude":"2.9938601"},
{"id":"565","post_code":"16012","name":"Birkhadem","wilaya_id":"16", "ar_name":"بئر خادم", "longitude":"36.7146375", "latitude":"3.0311349"},
{"id":"566","post_code":"16013","name":"El Harrach","wilaya_id":"16", "ar_name":"الحراش", "longitude":"36.7120702", "latitude":"3.1217531"},
{"id":"567","post_code":"16014","name":"Baraki","wilaya_id":"16", "ar_name":"براقي", "longitude":"36.6701186", "latitude":"3.0635219"},
{"id":"568","post_code":"16015","name":"Oued Smar","wilaya_id":"16", "ar_name":"وادي سمار", "longitude":"36.7098628", "latitude":"3.1596368"},
{"id":"569","post_code":"16016","name":"Bourouba","wilaya_id":"16", "ar_name":"بوروبة", "longitude":"36.7166963", "latitude":"3.0955372"},
{"id":"570","post_code":"16017","name":"Hussein Dey","wilaya_id":"16", "ar_name":"حسين داي", "longitude":"36.7402922", "latitude":"3.0914013"},
{"id":"571","post_code":"16018","name":"Kouba","wilaya_id":"16", "ar_name":"القبة", "longitude":"36.7241467", "latitude":"3.0598317"},
{"id":"572","post_code":"16019","name":"Bachedjerah","wilaya_id":"16", "ar_name":"باش جراح", "longitude":"36.723121", "latitude":"3.0940781"},
{"id":"573","post_code":"16020","name":"Dar El Beida","wilaya_id":"16", "ar_name":"الدار البيضاء", "longitude":"36.7024372", "latitude":"3.1766575"},
{"id":"574","post_code":"16021","name":"Bab Azzouar","wilaya_id":"16", "ar_name":"باب الزوار", "longitude":"36.7228311", "latitude":"3.1689279"},
{"id":"575","post_code":"16022","name":"Ben Aknoun","wilaya_id":"16", "ar_name":"بن عكنون", "longitude":"36.7571759", "latitude":"2.9946244"},
{"id":"576","post_code":"16023","name":"Dely Ibrahim","wilaya_id":"16", "ar_name":"دالي ابراهيم", "longitude":"36.7567281", "latitude":"2.962446"},
{"id":"577","post_code":"16024","name":"El Hammamet","wilaya_id":"16", "ar_name":"الحمامات", "longitude":"36.8024015", "latitude":"2.9559659"},
{"id":"578","post_code":"16025","name":"Rais Hamidou","wilaya_id":"16", "ar_name":"الرايس حميدو", "longitude":"36.8077359", "latitude":"2.9921434"},
{"id":"579","post_code":"16026","name":"Djasr Kasentina","wilaya_id":"16", "ar_name":"جسر قسنطينة", "longitude":"36.6927735", "latitude":"3.0610977"},
{"id":"580","post_code":"16027","name":"El Mouradia","wilaya_id":"16", "ar_name":"المرادية", "longitude":"36.7490236", "latitude":"3.0384439"},
{"id":"581","post_code":"16028","name":"Hydra","wilaya_id":"16", "ar_name":"حيدرة", "longitude":"36.7407683", "latitude":"3.0079149"},
{"id":"582","post_code":"16029","name":"Mohammadia","wilaya_id":"16", "ar_name":"المحمدية", "longitude":"36.7353224", "latitude":"3.1359743"},
{"id":"583","post_code":"16030","name":"Bordj El Kiffan","wilaya_id":"16", "ar_name":"برج الكيفان", "longitude":"36.7533604", "latitude":"3.1868338"},
{"id":"584","post_code":"16031","name":"El Magharia","wilaya_id":"16", "ar_name":"المقرية", "longitude":"36.7305475", "latitude":"3.1026854"},
{"id":"585","post_code":"16032","name":"Beni Messous","wilaya_id":"16", "ar_name":"بني مسوس", "longitude":"36.7844574", "latitude":"2.9591524"},
{"id":"586","post_code":"16033","name":"Les Eucalyptus","wilaya_id":"16", "ar_name":"الكليتوس", "longitude":"36.668168", "latitude":"3.1306093"},
{"id":"587","post_code":"16034","name":"Birtouta","wilaya_id":"16", "ar_name":"بئر توتة", "longitude":"36.6424589", "latitude":"2.9745906"},
{"id":"588","post_code":"16035","name":"Tassala El Merdja","wilaya_id":"16", "ar_name":"تسالة المرجة", "longitude":"36.6277781", "latitude":"2.8848547"},
{"id":"589","post_code":"16036","name":"Ouled Chebel","wilaya_id":"16", "ar_name":"أولاد الشبل", "longitude":"36.6082831", "latitude":"2.9737753"},
{"id":"590","post_code":"16037","name":"Sidi Moussa","wilaya_id":"16", "ar_name":"سيدي موسى", "longitude":"36.6162931", "latitude":"3.0682748"},
{"id":"591","post_code":"16038","name":"Ain Taya","wilaya_id":"16", "ar_name":"عين طاية", "longitude":"36.7880614", "latitude":"3.2742797"},
{"id":"592","post_code":"16039","name":"Bordj El Bahri","wilaya_id":"16", "ar_name":"برج البحري", "longitude":"36.7880016", "latitude":"3.2321689"},
{"id":"593","post_code":"16040","name":"Marsa","wilaya_id":"16", "ar_name":"المرسى", "longitude":"36.8081013", "latitude":"3.2263432"},
{"id":"594","post_code":"16041","name":"Haraoua","wilaya_id":"16", "ar_name":"هراوة", "longitude":"36.7726194", "latitude":"3.3006834"},
{"id":"595","post_code":"16042","name":"Rouiba","wilaya_id":"16", "ar_name":"رويبة", "longitude":"36.7375877", "latitude":"3.2491683"},
{"id":"596","post_code":"16043","name":"Reghaia","wilaya_id":"16", "ar_name":"الرغاية", "longitude":"36.7538466", "latitude":"3.312152"},
{"id":"597","post_code":"16044","name":"Ain Benian","wilaya_id":"16", "ar_name":"عين بنيان", "longitude":"36.7941279", "latitude":"2.9099606"},
{"id":"598","post_code":"16045","name":"Staoueli","wilaya_id":"16", "ar_name":"سطاوالي", "longitude":"36.7426531", "latitude":"2.8372776"},
{"id":"599","post_code":"16046","name":"Zeralda","wilaya_id":"16", "ar_name":"زرالدة", "longitude":"36.6995503", "latitude":"2.8049892"},
{"id":"600","post_code":"16047","name":"Mahelma","wilaya_id":"16", "ar_name":"محالمة", "longitude":"36.6721514", "latitude":"2.8158789"},
{"id":"601","post_code":"16048","name":"Rahmania","wilaya_id":"16", "ar_name":"رحمانية", "longitude":"36.6805606", "latitude":"2.8805201"},
{"id":"602","post_code":"16049","name":"Souidania","wilaya_id":"16", "ar_name":"سويدانية", "longitude":"36.7112067", "latitude":"2.8684395"},
{"id":"603","post_code":"16050","name":"Cheraga","wilaya_id":"16", "ar_name":"شراقة", "longitude":"36.7596098", "latitude":"2.8933198"},
{"id":"604","post_code":"16051","name":"Ouled Fayet","wilaya_id":"16", "ar_name":"أولاد فايت", "longitude":"36.725135", "latitude":"2.9078787"},
{"id":"605","post_code":"16052","name":"El Achour","wilaya_id":"16", "ar_name":"العاشور", "longitude":"36.7269712", "latitude":"2.9530471"},
{"id":"606","post_code":"16053","name":"Draria","wilaya_id":"16", "ar_name":"درارية", "longitude":"36.7132847", "latitude":"2.9677778"},
{"id":"607","post_code":"16054","name":"Douera","wilaya_id":"16", "ar_name":"دويرة", "longitude":"36.6681547", "latitude":"2.8979868"},
{"id":"608","post_code":"16055","name":"Baba Hassen","wilaya_id":"16", "ar_name":"بابا حسن", "longitude":"36.6959627", "latitude":"2.9591094"},
{"id":"609","post_code":"16056","name":"Khracia","wilaya_id":"16", "ar_name":"خرايسية", "longitude":"36.6691421", "latitude":"2.9639691"},
{"id":"610","post_code":"16057","name":"Saoula","wilaya_id":"16", "ar_name":"السحاولة", "longitude":"36.6866758", "latitude":"3.0056614"},
{"id":"611","post_code":"17001","name":"Djelfa","wilaya_id":"17", "ar_name":"الجلفة", "longitude":"34.6741226", "latitude":"3.2504381"},
{"id":"612","post_code":"17002","name":"Moudjebara","wilaya_id":"17", "ar_name":"مجبرة", "longitude":"34.5040006", "latitude":"3.4597278"},
{"id":"613","post_code":"17003","name":"El Guedid","wilaya_id":"17", "ar_name":"القديد", "longitude":"34.646898", "latitude":"2.6057338"},
{"id":"614","post_code":"17004","name":"Hassi Bahbah","wilaya_id":"17", "ar_name":"حاسي بحبح", "longitude":"35.0764297", "latitude":"2.9959141"},
{"id":"615","post_code":"17005","name":"Ain Maabed","wilaya_id":"17", "ar_name":"عين معبد", "longitude":"34.8059782", "latitude":"3.1152247"},
{"id":"616","post_code":"17006","name":"Sed Rahal","wilaya_id":"17", "ar_name":"سد رحال", "longitude":"33.9518497", "latitude":"3.2188654"},
{"id":"617","post_code":"17007","name":"Feidh El Botma","wilaya_id":"17", "ar_name":"فيض البطمة", "longitude":"34.5303577", "latitude":"3.7719368"},
{"id":"618","post_code":"17008","name":"Birine","wilaya_id":"17", "ar_name":"البيرين", "longitude":"35.6315904", "latitude":"3.2060765"},
{"id":"619","post_code":"17009","name":"Bouira Lahdeb","wilaya_id":"17", "ar_name":"بويرة الأحداب", "longitude":"35.2444181", "latitude":"3.1338179"},
{"id":"620","post_code":"17010","name":"Zaccar","wilaya_id":"17", "ar_name":"زكار", "longitude":"34.4305669", "latitude":"3.3213472"},
{"id":"621","post_code":"17011","name":"El Khemis","wilaya_id":"17", "ar_name":"الخميس", "longitude":"35.2876616", "latitude":"2.5915396"},
{"id":"622","post_code":"17012","name":"Sidi Baizid","wilaya_id":"17", "ar_name":"سيدي بايزيد", "longitude":"35.0582537", "latitude":"3.425889"},
{"id":"623","post_code":"17013","name":"M'Liliha","wilaya_id":"17", "ar_name":"المليليحة", "longitude":"34.7546461", "latitude":"3.4761"},
{"id":"624","post_code":"17014","name":"El Idrissia","wilaya_id":"17", "ar_name":"الإدريسية", "longitude":"34.4515826", "latitude":"2.5095583"},
{"id":"625","post_code":"17015","name":"Douis","wilaya_id":"17", "ar_name":"الدويس", "longitude":"34.3739232", "latitude":"2.6978792"},
{"id":"626","post_code":"17016","name":"Hassi El Euch","wilaya_id":"17", "ar_name":"حاسي العش", "longitude":"35.1540377", "latitude":"3.2426298"},
{"id":"627","post_code":"17017","name":"Messaad","wilaya_id":"17", "ar_name":"مسعد", "longitude":"34.1658932", "latitude":"3.4682458"},
{"id":"628","post_code":"17018","name":"Guettara","wilaya_id":"17", "ar_name":"قتارة", "longitude":"33.1614178", "latitude":"4.6788776"},
{"id":"629","post_code":"17019","name":"Sidi Ladjel","wilaya_id":"17", "ar_name":"سيدي لعجال", "longitude":"35.4433626", "latitude":"2.4941109"},
{"id":"630","post_code":"17020","name":"Had Sahary","wilaya_id":"17", "ar_name":"حد الصحاري", "longitude":"35.3538344", "latitude":"3.3463044"},
{"id":"631","post_code":"17021","name":"Guernini","wilaya_id":"17", "ar_name":"القرنيني", "longitude":"35.2001485", "latitude":"2.6780462"},
{"id":"632","post_code":"17022","name":"Selmana","wilaya_id":"17", "ar_name":"سلمانة", "longitude":"34.1762848", "latitude":"3.5904298"},
{"id":"633","post_code":"17023","name":"Ain Chouhada","wilaya_id":"17", "ar_name":"عين الشهداء", "longitude":"34.2421928", "latitude":"2.510569"},
{"id":"634","post_code":"17024","name":"Oum Laadham","wilaya_id":"17", "ar_name":"ام العظام", "longitude":"33.7195744", "latitude":"4.5251226"},
{"id":"635","post_code":"17025","name":"Dar Chouikh","wilaya_id":"17", "ar_name":"دار الشيوخ", "longitude":"34.8946674", "latitude":"3.4691904"},
{"id":"636","post_code":"17026","name":"Charef","wilaya_id":"17", "ar_name":"الشارف", "longitude":"34.619593", "latitude":"2.792244"},
{"id":"637","post_code":"17027","name":"Beni Yacoub","wilaya_id":"17", "ar_name":"بن يعقوب", "longitude":"34.4666662", "latitude":"2.7745782"},
{"id":"638","post_code":"17028","name":"Zaafrane","wilaya_id":"17", "ar_name":"الزعفران", "longitude":"34.8540509", "latitude":"2.8442416"},
{"id":"639","post_code":"17029","name":"Deldoul","wilaya_id":"17", "ar_name":"دلدول", "longitude":"34.6672467", "latitude":"3.2993118"},
{"id":"640","post_code":"17030","name":"Ain El Ibel","wilaya_id":"17", "ar_name":"عين الابل", "longitude":"34.3539985", "latitude":"3.2157325"},
{"id":"641","post_code":"17031","name":"Ain Oussera","wilaya_id":"17", "ar_name":"عين وسارة", "longitude":"35.4466401", "latitude":"2.8711647"},
{"id":"642","post_code":"17032","name":"Benhar","wilaya_id":"17", "ar_name":"بنهار", "longitude":"35.4848362", "latitude":"3.0055036"},
{"id":"643","post_code":"17033","name":"Hassi Fedoul","wilaya_id":"17", "ar_name":"حاسي فدول", "longitude":"35.4392023", "latitude":"2.1992396"},
{"id":"644","post_code":"17034","name":"Amourah","wilaya_id":"17", "ar_name":"عمورة", "longitude":"34.3553852", "latitude":"3.8660287"},
{"id":"645","post_code":"17035","name":"Ain Fekka","wilaya_id":"17", "ar_name":"عين افقة", "longitude":"35.429667", "latitude":"3.4118023"},
{"id":"646","post_code":"17036","name":"Tadmit","wilaya_id":"17", "ar_name":"تعضميت", "longitude":"34.2833322", "latitude":"2.9745782"},
{"id":"647","post_code":"18001","name":"Jijel","wilaya_id":"18", "ar_name":"جيجل", "longitude":"36.7962663", "latitude":"5.715465"},
{"id":"648","post_code":"18002","name":"Erraguene","wilaya_id":"18", "ar_name":"إراڨن", "longitude":"36.7962714", "latitude":"5.7504845"},
{"id":"649","post_code":"18003","name":"El Aouana","wilaya_id":"18", "ar_name":"العوانة", "longitude":"36.7383739", "latitude":"5.5575774"},
{"id":"650","post_code":"18004","name":"Ziamma Mansouriah","wilaya_id":"18", "ar_name":"زيامة منصورية", "longitude":"36.6501629", "latitude":"5.4284118"},
{"id":"651","post_code":"18005","name":"Taher","wilaya_id":"18", "ar_name":"الطاهير", "longitude":"36.7775912", "latitude":"5.8348749"},
{"id":"652","post_code":"18006","name":"Emir Abdelkader","wilaya_id":"18", "ar_name":"الامير عبد القادر", "longitude":"36.7557894", "latitude":"5.778586"},
{"id":"653","post_code":"18007","name":"Chekfa","wilaya_id":"18", "ar_name":"الشقفة", "longitude":"36.7728541", "latitude":"5.9468436"},
{"id":"654","post_code":"18008","name":"Chahna","wilaya_id":"18", "ar_name":"الشحنة", "longitude":"36.6772642", "latitude":"5.9446441"},
{"id":"655","post_code":"18009","name":"El Milia","wilaya_id":"18", "ar_name":"الميلية", "longitude":"36.7469209", "latitude":"6.2451046"},
{"id":"656","post_code":"18010","name":"Sidi Maarouf","wilaya_id":"18", "ar_name":"سيدي معروف", "longitude":"36.6373452", "latitude":"6.2617134"},
{"id":"657","post_code":"18011","name":"Settara","wilaya_id":"18", "ar_name":"السطارة", "longitude":"36.7183649", "latitude":"6.324544"},
{"id":"658","post_code":"18012","name":"El Ancer","wilaya_id":"18", "ar_name":"العنصر", "longitude":"36.7982581", "latitude":"6.1523866"},
{"id":"659","post_code":"18013","name":"Sidi Abdelaziz","wilaya_id":"18", "ar_name":"سيدي عبد العزيز", "longitude":"36.8535179", "latitude":"6.0439396"},
{"id":"660","post_code":"18014","name":"Kaous","wilaya_id":"18", "ar_name":"قاوس", "longitude":"36.7590016", "latitude":"5.7313651"},
{"id":"661","post_code":"18015","name":"Ghebala","wilaya_id":"18", "ar_name":"غبالة", "longitude":"36.6290636", "latitude":"6.3831317"},
{"id":"662","post_code":"18016","name":"Bouraoui Belhadef","wilaya_id":"18", "ar_name":"بوراوي بلهادف", "longitude":"36.6979904", "latitude":"6.0968648"},
{"id":"663","post_code":"18017","name":"Djmila","wilaya_id":"18", "ar_name":"جيملة", "longitude":"36.5827122", "latitude":"5.8287487"},
{"id":"664","post_code":"18018","name":"Selma Benziada","wilaya_id":"18", "ar_name":"سلمى بن زيادة", "longitude":"36.6505425", "latitude":"5.5628345"},
{"id":"665","post_code":"18019","name":"Boussif Ouled Askeur","wilaya_id":"18", "ar_name":"أولاد عسكر", "longitude":"36.6455063", "latitude":"6.0109806"},
{"id":"666","post_code":"18020","name":"El Kennar Nouchfi","wilaya_id":"18", "ar_name":"القنار", "longitude":"36.8243495", "latitude":"5.9558128"},
{"id":"667","post_code":"18021","name":"Ouled Yahia Khadrouch","wilaya_id":"18", "ar_name":"اولاد يحيى", "longitude":"36.7223204", "latitude":"6.1822341"},
{"id":"668","post_code":"18022","name":"Boudria Beni Yadjis","wilaya_id":"18", "ar_name":"بودريعة بن ياجيس", "longitude":"36.5902781", "latitude":"5.6954215"},
{"id":"669","post_code":"18023","name":"Kemir Oued Adjoul","wilaya_id":"18", "ar_name":"بني بلعيد", "longitude":"36.8774044", "latitude":"6.1179255"},
{"id":"670","post_code":"18024","name":"Texena","wilaya_id":"18", "ar_name":"تاكسنة", "longitude":"36.6702895", "latitude":"5.6987689"},
{"id":"671","post_code":"18025","name":"Djemaa Beni Habibi","wilaya_id":"18", "ar_name":"الجمعة بني حبيبي", "longitude":"36.809857", "latitude":"6.1050402"},
{"id":"672","post_code":"18026","name":"Bordj T'her","wilaya_id":"18", "ar_name":"برج الطهر", "longitude":"36.7484784", "latitude":"6.0248638"},
{"id":"673","post_code":"18027","name":"Ouled Rabah","wilaya_id":"18", "ar_name":"ولاد رابح", "longitude":"36.5974059", "latitude":"6.1619139"},
{"id":"674","post_code":"18028","name":"Ouadjana","wilaya_id":"18", "ar_name":"وجانة", "longitude":"36.675", "latitude":"5.8128487"},
{"id":"675","post_code":"19001","name":"Setif","wilaya_id":"19", "ar_name":"سطيف‎", "longitude":"36.1904969", "latitude":"5.350173"},
{"id":"676","post_code":"19002","name":"Ain El Kebira","wilaya_id":"19", "ar_name":"عين الكبيرة", "longitude":"36.3773932", "latitude":"5.4503964"},
{"id":"677","post_code":"19003","name":"Beni Aziz","wilaya_id":"19", "ar_name":"بني عزيز", "longitude":"36.4732335", "latitude":"5.5735848"},
{"id":"678","post_code":"19004","name":"Ouled Sidi Ahmed","wilaya_id":"19", "ar_name":"أولاد سي أحمد", "longitude":"35.907478", "latitude":"5.1083824"},
{"id":"679","post_code":"19005","name":"Boutaleb","wilaya_id":"19", "ar_name":"بوطالب", "longitude":"35.6858444", "latitude":"5.1806519"},
{"id":"680","post_code":"19006","name":"Ain Roua","wilaya_id":"19", "ar_name":"عين الروى", "longitude":"36.3210122", "latitude":"5.1291535"},
{"id":"681","post_code":"19007","name":"Draa Kebila","wilaya_id":"19", "ar_name":"ذراع قبيلة", "longitude":"36.4219572", "latitude":"4.9356912"},
{"id":"682","post_code":"19008","name":"Bir El Arch","wilaya_id":"19", "ar_name":"بئر العرش", "longitude":"36.1012237", "latitude":"5.7440447"},
{"id":"683","post_code":"19009","name":"Beni Chebana","wilaya_id":"19", "ar_name":"بني شبانة", "longitude":"36.4681034", "latitude":"4.8420284"},
{"id":"684","post_code":"19010","name":"Ouled Tebben","wilaya_id":"19", "ar_name":"أولاد تبــان", "longitude":"35.787273", "latitude":"5.0449536"},
{"id":"685","post_code":"19011","name":"Hamma","wilaya_id":"19", "ar_name":"حــامة", "longitude":"35.6877227", "latitude":"5.31252"},
{"id":"686","post_code":"19012","name":"Maaouia","wilaya_id":"19", "ar_name":"معـاويـة", "longitude":"36.3748054", "latitude":"5.6401895"},
{"id":"687","post_code":"19013","name":"Ain Legraj","wilaya_id":"19", "ar_name":"عين لڨراج", "longitude":"36.4102318", "latitude":"4.7998641"},
{"id":"688","post_code":"19014","name":"Ain Abessa","wilaya_id":"19", "ar_name":"عين عباسـة", "longitude":"36.3045155", "latitude":"5.1522342"},
{"id":"689","post_code":"19015","name":"Dehamcha","wilaya_id":"19", "ar_name":"الدهامشة", "longitude":"36.3786819", "latitude":"5.5425141"},
{"id":"690","post_code":"19016","name":"Babor","wilaya_id":"19", "ar_name":"بابور", "longitude":"36.5019013", "latitude":"5.3674976"},
{"id":"691","post_code":"19017","name":"Guidjel","wilaya_id":"19", "ar_name":"قجــال", "longitude":"36.0807516", "latitude":"5.4235957"},
{"id":"692","post_code":"19018","name":"Ain Lahdjar","wilaya_id":"19", "ar_name":"عين لحجـر", "longitude":"35.960213", "latitude":"5.4198543"},
{"id":"693","post_code":"19019","name":"Bousselam","wilaya_id":"19", "ar_name":"بوسلام", "longitude":"36.4937736", "latitude":"4.9369786"},
{"id":"694","post_code":"19020","name":"El Eulma","wilaya_id":"19", "ar_name":"العلمة", "longitude":"36.1657394", "latitude":"5.620148"},
{"id":"695","post_code":"19021","name":"Djemila","wilaya_id":"19", "ar_name":"جميلـة", "longitude":"36.3469977", "latitude":"5.6877397"},
{"id":"696","post_code":"19022","name":"Beni Ouartilane","wilaya_id":"19", "ar_name":"بني ورتيلان", "longitude":"36.4307623", "latitude":"4.7427652"},
{"id":"697","post_code":"19023","name":"Rosfa","wilaya_id":"19", "ar_name":"الرصفة", "longitude":"35.7790515", "latitude":"5.182669"},
{"id":"698","post_code":"19024","name":"Ouled Addouane","wilaya_id":"19", "ar_name":"أولاد عدوان", "longitude":"36.3363273", "latitude":"5.4260439"},
{"id":"699","post_code":"19025","name":"Belaa","wilaya_id":"19", "ar_name":"البلاعة", "longitude":"36.2217494", "latitude":"5.7975172"},
{"id":"700","post_code":"19026","name":"Ain Arnat","wilaya_id":"19", "ar_name":"عين أرنـات", "longitude":"36.1756548", "latitude":"5.173013"},
{"id":"701","post_code":"19027","name":"Amoucha","wilaya_id":"19", "ar_name":"عموشة", "longitude":"36.3748316", "latitude":"5.3447173"},
{"id":"702","post_code":"19028","name":"Ain Oulmane","wilaya_id":"19", "ar_name":"عين ولمان", "longitude":"35.9239262", "latitude":"5.2415487"},
{"id":"703","post_code":"19029","name":"Beidha Bordj","wilaya_id":"19", "ar_name":"بيضاء برج", "longitude":"35.8379281", "latitude":"5.5996773"},
{"id":"704","post_code":"19030","name":"Bouandas","wilaya_id":"19", "ar_name":"بوعنداس", "longitude":"36.5042094", "latitude":"5.0698679"},
{"id":"705","post_code":"19031","name":"Bazer Sakhra","wilaya_id":"19", "ar_name":"بازر الصخرة", "longitude":"36.0795448", "latitude":"5.6140969"},
{"id":"706","post_code":"19032","name":"Hammam Essokhna","wilaya_id":"19", "ar_name":"حمــام السخنة", "longitude":"35.9499435", "latitude":"5.6908217"},
{"id":"707","post_code":"19033","name":"Mezloug","wilaya_id":"19", "ar_name":"مزلوق", "longitude":"36.1050372", "latitude":"5.2373002"},
{"id":"708","post_code":"19034","name":"Bir Haddada","wilaya_id":"19", "ar_name":"بئر حدادة", "longitude":"35.9625421", "latitude":"5.3953788"},
{"id":"709","post_code":"19035","name":"Serdj El Ghoul","wilaya_id":"19", "ar_name":"سرج الغول", "longitude":"36.475214", "latitude":"5.5168507"},
{"id":"710","post_code":"19036","name":"Harbil","wilaya_id":"19", "ar_name":"حربيل", "longitude":"36.3471017", "latitude":"4.8497854"},
{"id":"711","post_code":"19037","name":"El Ouricia","wilaya_id":"19", "ar_name":"الأورسية", "longitude":"36.2706726", "latitude":"5.3148483"},
{"id":"712","post_code":"19038","name":"Tizi Nbechar","wilaya_id":"19", "ar_name":"تيزي نبشار", "longitude":"36.4317298", "latitude":"5.2875111"},
{"id":"713","post_code":"19039","name":"Salah Bey","wilaya_id":"19", "ar_name":"صـالح باي", "longitude":"35.8237361", "latitude":"5.2648518"},
{"id":"714","post_code":"19040","name":"Ain Azal","wilaya_id":"19", "ar_name":"عين أزال", "longitude":"35.8195556", "latitude":"5.4648373"},
{"id":"715","post_code":"19041","name":"Guenzet","wilaya_id":"19", "ar_name":"ڨنزات", "longitude":"36.3357504", "latitude":"4.7548888"},
{"id":"716","post_code":"19042","name":"Talaifacene","wilaya_id":"19", "ar_name":"تالة إيفاسن", "longitude":"35.9637883", "latitude":"5.6440518"},
{"id":"717","post_code":"19043","name":"Bougaa","wilaya_id":"19", "ar_name":"بوقاعـة", "longitude":"36.3394384", "latitude":"5.0449321"},
{"id":"718","post_code":"19044","name":"Beni Fouda","wilaya_id":"19", "ar_name":"بني فودة", "longitude":"36.2770368", "latitude":"5.4987834"},
{"id":"719","post_code":"19045","name":"Tachouda","wilaya_id":"19", "ar_name":"تاشودة", "longitude":"36.2670155", "latitude":"5.6616471"},
{"id":"720","post_code":"19046","name":"Beni Mouhli","wilaya_id":"19", "ar_name":"إيث موحلي", "longitude":"36.5137668", "latitude":"4.858188"},
{"id":"721","post_code":"19047","name":"Ouled Sabor","wilaya_id":"19", "ar_name":"أولاد صـابر", "longitude":"36.180991", "latitude":"5.4739354"},
{"id":"722","post_code":"19048","name":"Guellal","wilaya_id":"19", "ar_name":"قلال", "longitude":"36.036461", "latitude":"5.2209924"},
{"id":"723","post_code":"19049","name":"Ain Sebt","wilaya_id":"19", "ar_name":"عين السبت", "longitude":"36.4924439", "latitude":"5.649352"},
{"id":"724","post_code":"19050","name":"Hammam Guergour","wilaya_id":"19", "ar_name":"حمام قرقور", "longitude":"36.3463283", "latitude":"4.932553"},
{"id":"725","post_code":"19051","name":"Ait Naoual Mezada","wilaya_id":"19", "ar_name":"آيت نوال مزادة", "longitude":"36.5453859", "latitude":"5.0547929"},
{"id":"726","post_code":"19052","name":"Ksar El Abtal","wilaya_id":"19", "ar_name":"قصرالأبطال", "longitude":"35.9761631", "latitude":"5.1490233"},
{"id":"727","post_code":"19053","name":"Beni Hocine","wilaya_id":"19", "ar_name":"بني حسين", "longitude":"36.2590992", "latitude":"5.0521419"},
{"id":"728","post_code":"19054","name":"Ait Tizi","wilaya_id":"19", "ar_name":"آيت تيزي", "longitude":"36.5360367", "latitude":"5.1052087"},
{"id":"729","post_code":"19055","name":"Maouklane","wilaya_id":"19", "ar_name":"موكلان", "longitude":"36.4012018", "latitude":"5.0266852"},
{"id":"730","post_code":"19056","name":"Guelta Zerka","wilaya_id":"19", "ar_name":"القلتة الزرقاء", "longitude":"36.2047344", "latitude":"5.6012867"},
{"id":"731","post_code":"19057","name":"Oued El Barad","wilaya_id":"19", "ar_name":"واد البارد", "longitude":"36.4982198", "latitude":"5.3679794"},
{"id":"732","post_code":"19058","name":"Taya","wilaya_id":"19", "ar_name":"طاية", "longitude":"35.9253339", "latitude":"5.8147183"},
{"id":"733","post_code":"19059","name":"El Ouldja","wilaya_id":"19", "ar_name":"الولجـة", "longitude":"36.0876231", "latitude":"5.8494875"},
{"id":"734","post_code":"19060","name":"Tella","wilaya_id":"19", "ar_name":"التلة", "longitude":"35.9637883", "latitude":"5.6440518"},
{"id":"735","post_code":"20001","name":"Saida","wilaya_id":"20", "ar_name":"سعيدة", "longitude":"34.84194", "latitude":"0.1133388"},
{"id":"736","post_code":"20002","name":"Doui Thabet","wilaya_id":"20", "ar_name":"دوى ثابت", "longitude":"34.8926538", "latitude":"-0.0842"},
{"id":"737","post_code":"20003","name":"Ain El Hadjar","wilaya_id":"20", "ar_name":"عين الحجر", "longitude":"34.7609324", "latitude":"0.1333378"},
{"id":"738","post_code":"20004","name":"Ouled Khaled","wilaya_id":"20", "ar_name":"أولاد خالد", "longitude":"34.841945", "latitude":"0.1483583"},
{"id":"739","post_code":"20005","name":"Moulay Larbi","wilaya_id":"20", "ar_name":"موالي العربي", "longitude":"34.6487428", "latitude":"0.0046133"},
{"id":"740","post_code":"20006","name":"Youb","wilaya_id":"20", "ar_name":"يوب", "longitude":"34.9207469", "latitude":"-0.2177601"},
{"id":"741","post_code":"20007","name":"Hounet","wilaya_id":"20", "ar_name":"هونت", "longitude":"35.1193494", "latitude":"-0.1492344"},
{"id":"742","post_code":"20008","name":"Sidi Amar","wilaya_id":"20", "ar_name":"يدي عمر", "longitude":"35.0264293", "latitude":"0.1049602"},
{"id":"743","post_code":"20009","name":"Sidi Boubekeur","wilaya_id":"20", "ar_name":"سيدي بوبكر", "longitude":"35.0307334", "latitude":"0.0453185"},
{"id":"744","post_code":"20010","name":"El Hassasna","wilaya_id":"20", "ar_name":"حساسنة", "longitude":"34.8248463", "latitude":"0.3121231"},
{"id":"745","post_code":"20011","name":"Maamora","wilaya_id":"20", "ar_name":"معمورة", "longitude":"34.68074", "latitude":"0.4905867"},
{"id":"746","post_code":"20012","name":"Sidi Ahmed","wilaya_id":"20", "ar_name":"سيدي أحمد", "longitude":"34.5499905", "latitude":"0.2527392"},
{"id":"747","post_code":"20013","name":"Ain Sekhouna","wilaya_id":"20", "ar_name":"العين السخونة", "longitude":"34.5044436", "latitude":"0.8354119"},
{"id":"748","post_code":"20014","name":"Ouled Brahim","wilaya_id":"20", "ar_name":"أولاد ابراھيم", "longitude":"34.986248", "latitude":"0.4696722"},
{"id":"749","post_code":"20015","name":"Tircine","wilaya_id":"20", "ar_name":"تيرسين", "longitude":"34.9016504", "latitude":"0.549488"},
{"id":"750","post_code":"20016","name":"Ain Soltane","wilaya_id":"20", "ar_name":"عين السلطان", "longitude":"34.9675528", "latitude":"0.3012402"},
{"id":"751","post_code":"21001","name":"Skikda","wilaya_id":"21", "ar_name":"سكيكدة", "longitude":"36.8777706", "latitude":"6.86568"},
{"id":"752","post_code":"21002","name":"Ain Zouit","wilaya_id":"21", "ar_name":"عين زويت", "longitude":"36.8887165", "latitude":"6.7802703"},
{"id":"753","post_code":"21003","name":"El Hadaik","wilaya_id":"21", "ar_name":"الحدايق", "longitude":"36.8244178", "latitude":"6.878085"},
{"id":"754","post_code":"21004","name":"Azzaba","wilaya_id":"21", "ar_name":"عزابة", "longitude":"36.7430777", "latitude":"7.0997212"},
{"id":"755","post_code":"21005","name":"Djendel","wilaya_id":"21", "ar_name":"جندل", "longitude":"36.7814565", "latitude":"7.1617769"},
{"id":"756","post_code":"21006","name":"Ain Cherchar","wilaya_id":"21", "ar_name":"عين شرشار", "longitude":"36.7329798", "latitude":"7.2109578"},
{"id":"757","post_code":"21007","name":"Bekkouche Lakhdar","wilaya_id":"21", "ar_name":"بكوش لخضر", "longitude":"36.7011643", "latitude":"7.2953939"},
{"id":"758","post_code":"21008","name":"Ben Azzouz","wilaya_id":"21", "ar_name":"بن عزوز", "longitude":"36.8648574", "latitude":"7.2885273"},
{"id":"759","post_code":"21009","name":"Es Sebt","wilaya_id":"21", "ar_name":"السبت", "longitude":"36.659999", "latitude":"7.0612452"},
{"id":"760","post_code":"21010","name":"Collo","wilaya_id":"21", "ar_name":"القل", "longitude":"37.0006567", "latitude":"6.537659"},
{"id":"761","post_code":"21011","name":"Beni Zid","wilaya_id":"21", "ar_name":"بنى زيد", "longitude":"36.8166662", "latitude":"6.4912452"},
{"id":"762","post_code":"21012","name":"Kerkera","wilaya_id":"21", "ar_name":"كركرة", "longitude":"36.9297905", "latitude":"6.5761113"},
{"id":"763","post_code":"21013","name":"Ouled Attia","wilaya_id":"21", "ar_name":"أولاد عطية", "longitude":"35.5368952", "latitude":"2.1427722"},
{"id":"764","post_code":"21014","name":"Oued Zehour","wilaya_id":"21", "ar_name":"وادي الزهور", "longitude":"36.922037", "latitude":"6.3040281"},
{"id":"765","post_code":"21015","name":"Zitouna","wilaya_id":"21", "ar_name":"الزيتونة", "longitude":"36.9888779", "latitude":"6.4497961"},
{"id":"766","post_code":"21016","name":"El Harrouch","wilaya_id":"21", "ar_name":"الحروش", "longitude":"36.6547652", "latitude":"6.8234108"},
{"id":"767","post_code":"21017","name":"Zerdazas","wilaya_id":"21", "ar_name":"زردازة", "longitude":"36.8777912", "latitude":"6.9357204"},
{"id":"768","post_code":"21018","name":"Ouled Hebaba","wilaya_id":"21", "ar_name":"أولاد حبابة", "longitude":"36.8777912", "latitude":"6.9357204"},
{"id":"769","post_code":"21019","name":"Sidi Mezghiche","wilaya_id":"21", "ar_name":"سيدي مزغيش", "longitude":"36.6779093", "latitude":"6.7110264"},
{"id":"770","post_code":"21020","name":"Emdjez Edchich","wilaya_id":"21", "ar_name":"مجاز الدشيش", "longitude":"36.7047297", "latitude":"6.7961489"},
{"id":"771","post_code":"21021","name":"Beni Oulbane","wilaya_id":"21", "ar_name":"بني والبان", "longitude":"36.6282861", "latitude":"6.6174172"},
{"id":"772","post_code":"21022","name":"Ain Bouziane","wilaya_id":"21", "ar_name":"عين بوزيان", "longitude":"36.6036397", "latitude":"6.6793845"},
{"id":"773","post_code":"21023","name":"Ramdane Djamel","wilaya_id":"21", "ar_name":"رمضان جمال", "longitude":"36.7585337", "latitude":"6.8745445"},
{"id":"774","post_code":"21024","name":"Beni Bachir","wilaya_id":"21", "ar_name":"بني بشير", "longitude":"36.7833322", "latitude":"6.9245782"},
{"id":"775","post_code":"21025","name":"Salah Bouchaour","wilaya_id":"21", "ar_name":"صالح بوالشعور", "longitude":"36.6997005", "latitude":"6.8379484"},
{"id":"776","post_code":"21026","name":"Tamalous","wilaya_id":"21", "ar_name":"تمالوس", "longitude":"36.8366625", "latitude":"6.6275453"},
{"id":"777","post_code":"21027","name":"Ain Kechra","wilaya_id":"21", "ar_name":"عين قشرة", "longitude":"36.745039", "latitude":"6.423676"},
{"id":"778","post_code":"21028","name":"Oum Toub","wilaya_id":"21", "ar_name":"أم الطوب", "longitude":"36.6889452", "latitude":"6.5584944"},
{"id":"779","post_code":"21029","name":"Bein El Ouiden","wilaya_id":"21", "ar_name":"بين الويدان", "longitude":"36.8057424", "latitude":"6.5480875"},
{"id":"780","post_code":"21030","name":"Filfila","wilaya_id":"21", "ar_name":"فلفلة", "longitude":"36.8880361", "latitude":"7.0082877"},
{"id":"781","post_code":"21031","name":"Cheraia","wilaya_id":"21", "ar_name":"الشرايع", "longitude":"37.0030051", "latitude":"6.4972247"},
{"id":"782","post_code":"21032","name":"Kanoua","wilaya_id":"21", "ar_name":"قنواع", "longitude":"37.0381362", "latitude":"6.4001584"},
{"id":"783","post_code":"21033","name":"El Ghedir","wilaya_id":"21", "ar_name":"الغدير", "longitude":"36.6870219", "latitude":"6.9730998"},
{"id":"784","post_code":"21034","name":"Bouchtata","wilaya_id":"21", "ar_name":"بوشطاطة", "longitude":"36.7833322", "latitude":"6.7912452"},
{"id":"785","post_code":"21035","name":"Ouldja Boulbalout","wilaya_id":"21", "ar_name":"الولجة بو البلوط", "longitude":"36.73896", "latitude":"6.344292"},
{"id":"786","post_code":"21036","name":"Kheneg Mayoum","wilaya_id":"21", "ar_name":"خنق مايوم", "longitude":"36.8777912", "latitude":"6.9357204"},
{"id":"787","post_code":"21037","name":"Hamadi Krouma","wilaya_id":"21", "ar_name":"حمادي كرومة", "longitude":"36.8471689", "latitude":"6.9208716"},
{"id":"788","post_code":"21038","name":"El Marsa","wilaya_id":"21", "ar_name":"المرسى", "longitude":"37.0091437", "latitude":"7.116813"},
{"id":"789","post_code":"22001","name":"Sidi Bel Abbes","wilaya_id":"22", "ar_name":"سيدي بلعباس", "longitude":"35.2063139", "latitude":"-0.7001772"},
{"id":"790","post_code":"22002","name":"Tessala","wilaya_id":"22", "ar_name":"تسالة", "longitude":"35.2714294", "latitude":"-0.8558205"},
{"id":"791","post_code":"22003","name":"Sidi Brahim","wilaya_id":"22", "ar_name":"سيدي ابراهيم", "longitude":"35.2591019", "latitude":"-0.5991847"},
{"id":"792","post_code":"22004","name":"Mostefa Ben Brahim","wilaya_id":"22", "ar_name":"مصطفى بن ابراهيم", "longitude":"35.1721396", "latitude":"-0.4142215"},
{"id":"793","post_code":"22005","name":"Telagh","wilaya_id":"22", "ar_name":"تلاغ", "longitude":"34.8036693", "latitude":"-0.6044224"},
{"id":"794","post_code":"22006","name":"Mezaourou","wilaya_id":"22", "ar_name":"مزاورو", "longitude":"34.834391", "latitude":"-0.795146"},
{"id":"795","post_code":"22007","name":"Boukhanafis","wilaya_id":"22", "ar_name":"بوخنفيس", "longitude":"35.046047", "latitude":"-0.7594111"},
{"id":"796","post_code":"22008","name":"Sidi Ali Boussidi","wilaya_id":"22", "ar_name":"سيدي علي بوسيدي", "longitude":"35.1040625", "latitude":"-0.8716112"},
{"id":"797","post_code":"22009","name":"Badredine El Mokrani","wilaya_id":"22", "ar_name":"بدر الدين المقراني", "longitude":"35.0098106", "latitude":"-0.8547677"},
{"id":"798","post_code":"22010","name":"Marhoum","wilaya_id":"22", "ar_name":"مرحوم", "longitude":"34.4457418", "latitude":"-0.2038049"},
{"id":"799","post_code":"22011","name":"Tafissour","wilaya_id":"22", "ar_name":"تفسور", "longitude":"34.6926458", "latitude":"-0.2074045"},
{"id":"800","post_code":"22012","name":"Amarnas","wilaya_id":"22", "ar_name":"العمارنة", "longitude":"35.0999604", "latitude":"-0.6567147"},
{"id":"801","post_code":"22013","name":"Tilmouni","wilaya_id":"22", "ar_name":"تلموني", "longitude":"35.1832214", "latitude":"-0.5905178"},
{"id":"802","post_code":"22014","name":"Sidi Lahcene","wilaya_id":"22", "ar_name":"سيدي لحسن", "longitude":"35.1925854", "latitude":"-0.8112957"},
{"id":"803","post_code":"22015","name":"Ain Thrid","wilaya_id":"22", "ar_name":"عين التريد", "longitude":"35.284671", "latitude":"-0.6782448"},
{"id":"804","post_code":"22016","name":"Makedra","wilaya_id":"22", "ar_name":"مكدرة", "longitude":"35.4412149", "latitude":"-0.4348398"},
{"id":"805","post_code":"22017","name":"Tenira","wilaya_id":"22", "ar_name":"تنيرة", "longitude":"35.0063927", "latitude":"-0.5771282"},
{"id":"806","post_code":"22018","name":"Moulay Slissen","wilaya_id":"22", "ar_name":"مولاي سليسن", "longitude":"34.821448", "latitude":"-0.769794"},
{"id":"807","post_code":"22019","name":"El Hacaiba","wilaya_id":"22", "ar_name":"الحصيبة", "longitude":"34.699542", "latitude":"-0.7694292"},
{"id":"808","post_code":"22020","name":"Hassi Zehana","wilaya_id":"22", "ar_name":"حاسي زهانة", "longitude":"35.0251892", "latitude":"-0.8964662"},
{"id":"809","post_code":"22021","name":"Tabia","wilaya_id":"22", "ar_name":"طابية", "longitude":"35.0181729", "latitude":"-0.7786567"},
{"id":"810","post_code":"22022","name":"Merine","wilaya_id":"22", "ar_name":"مرين", "longitude":"34.7805884", "latitude":"-0.4548597"},
{"id":"811","post_code":"22023","name":"Ras El Ma","wilaya_id":"22", "ar_name":"رأس الماء", "longitude":"34.5039368", "latitude":"-0.8212282"},
{"id":"812","post_code":"22024","name":"Ain Tindamine","wilaya_id":"22", "ar_name":"عين تندامين", "longitude":"34.6890805", "latitude":"-0.7245612"},
{"id":"813","post_code":"22025","name":"Ain Kada","wilaya_id":"22", "ar_name":"عين قادة", "longitude":"35.1368876", "latitude":"-0.8607745"},
{"id":"814","post_code":"22026","name":"Mcid","wilaya_id":"22", "ar_name":"مسيد", "longitude":"35.139035", "latitude":"-0.2525651"},
{"id":"815","post_code":"22027","name":"Sidi Khaled","wilaya_id":"22", "ar_name":"سيدي خالد", "longitude":"35.1200723", "latitude":"-0.7417066"},
{"id":"816","post_code":"22028","name":"Ain El Berd","wilaya_id":"22", "ar_name":"عين البرد", "longitude":"35.3828523", "latitude":"-0.5661419"},
{"id":"817","post_code":"22029","name":"Sfissef","wilaya_id":"22", "ar_name":"سفيزف", "longitude":"35.2442317", "latitude":"-0.3162029"},
{"id":"818","post_code":"22030","name":"Ain Adden","wilaya_id":"22", "ar_name":"عين عدان", "longitude":"35.330304", "latitude":"-0.2660537"},
{"id":"819","post_code":"22031","name":"Oued Taourira","wilaya_id":"22", "ar_name":"واد تاوريرة", "longitude":"34.8024792", "latitude":"-0.3307029"},
{"id":"820","post_code":"22032","name":"Dhaya","wilaya_id":"22", "ar_name":"الظاية", "longitude":"34.6752163", "latitude":"-0.6322849"},
{"id":"821","post_code":"22033","name":"Zerouala","wilaya_id":"22", "ar_name":"زروالة", "longitude":"35.2429835", "latitude":"-0.5271103"},
{"id":"822","post_code":"22034","name":"Lamtar","wilaya_id":"22", "ar_name":"لمطار", "longitude":"35.0592826", "latitude":"-0.8357771"},
{"id":"823","post_code":"22035","name":"Sidi Chaib","wilaya_id":"22", "ar_name":"سيدي شعيب", "longitude":"34.5927664", "latitude":"-0.555625"},
{"id":"824","post_code":"22036","name":"Sidi Dahou","wilaya_id":"22", "ar_name":"سيدي دحو", "longitude":"35.1171744", "latitude":"-0.9150603"},
{"id":"825","post_code":"22037","name":"Oued Sbaa","wilaya_id":"22", "ar_name":"واد السبع", "longitude":"34.5903826", "latitude":"-0.717178"},
{"id":"826","post_code":"22038","name":"Boudjebaa El Bordj","wilaya_id":"22", "ar_name":"بوجبهة البرج", "longitude":"35.3519121", "latitude":"-0.3280353"},
{"id":"827","post_code":"22039","name":"Sehala Thaoura","wilaya_id":"22", "ar_name":"سهالة الثورة", "longitude":"35.2240535", "latitude":"-0.929592"},
{"id":"828","post_code":"22040","name":"Sidi Yacoub","wilaya_id":"22", "ar_name":"سيدي يعقوب", "longitude":"35.1557033", "latitude":"-0.8069145"},
{"id":"829","post_code":"22041","name":"Sidi Hamadouche","wilaya_id":"22", "ar_name":"سيدي حمادوش", "longitude":"35.3114849", "latitude":"-0.5885866"},
{"id":"830","post_code":"22042","name":"Belarbi","wilaya_id":"22", "ar_name":"بلعربي", "longitude":"35.1309933", "latitude":"-0.5169609"},
{"id":"831","post_code":"22043","name":"Oued Sefioun","wilaya_id":"22", "ar_name":"واد سفيون", "longitude":"35.0664471", "latitude":"-0.3616477"},
{"id":"832","post_code":"22044","name":"Teghalimet","wilaya_id":"22", "ar_name":"تغاليمت", "longitude":"34.8852137", "latitude":"-0.563019"},
{"id":"833","post_code":"22045","name":"Ben Badis","wilaya_id":"22", "ar_name":"ابن باديس", "longitude":"34.92614", "latitude":"-0.9757259"},
{"id":"834","post_code":"22046","name":"Sidi Ali Benyoub","wilaya_id":"22", "ar_name":"سيدي علي بن يوب", "longitude":"34.9488777", "latitude":"-0.794194"},
{"id":"835","post_code":"22047","name":"Chetouane Belaila","wilaya_id":"22", "ar_name":"شطوان بلايلة", "longitude":"34.950063", "latitude":"-0.8453465"},
{"id":"836","post_code":"22048","name":"Bir El Hammam","wilaya_id":"22", "ar_name":"بئر الحمام", "longitude":"34.4187435", "latitude":"-0.5031502"},
{"id":"837","post_code":"22049","name":"Taoudmout","wilaya_id":"22", "ar_name":"تاودموت", "longitude":"34.5881382", "latitude":"-0.1211071"},
{"id":"838","post_code":"22050","name":"Redjem Demouche","wilaya_id":"22", "ar_name":"رجم دموش", "longitude":"34.4269826", "latitude":"-0.8185673"},
{"id":"839","post_code":"22051","name":"Benachiba Chelia","wilaya_id":"22", "ar_name":"بن عشيبة شلية", "longitude":"34.9637049", "latitude":"-0.6169817"},
{"id":"840","post_code":"22052","name":"Hassi Dahou","wilaya_id":"22", "ar_name":"حاسي دحو", "longitude":"35.108878", "latitude":"-0.5963542"},
{"id":"841","post_code":"23001","name":"Annaba","wilaya_id":"23", "ar_name":"عنابة", "longitude":"36.9184139", "latitude":"7.6752351"},
{"id":"842","post_code":"23002","name":"Berrahel","wilaya_id":"23", "ar_name":"برحال", "longitude":"36.8106093", "latitude":"7.4170564"},
{"id":"843","post_code":"23003","name":"El Hadjar","wilaya_id":"23", "ar_name":"الحجار", "longitude":"36.7742133", "latitude":"7.6264832"},
{"id":"844","post_code":"23004","name":"Eulma","wilaya_id":"23", "ar_name":"العلمة", "longitude":"36.7095806", "latitude":"7.3636267"},
{"id":"845","post_code":"23005","name":"El Bouni","wilaya_id":"23", "ar_name":"البوني", "longitude":"36.8404084", "latitude":"7.6381562"},
{"id":"846","post_code":"23006","name":"Oued El Aneb","wilaya_id":"23", "ar_name":"وادي العنب", "longitude":"36.936216", "latitude":"7.4220268"},
{"id":"847","post_code":"23007","name":"Cheurfa","wilaya_id":"23", "ar_name":"الشرفة", "longitude":"36.721776", "latitude":"7.5026509"},
{"id":"848","post_code":"23008","name":"Seraidi","wilaya_id":"23", "ar_name":"سرايدي", "longitude":"36.9465799", "latitude":"7.5518106"},
{"id":"849","post_code":"23009","name":"Ain Berda","wilaya_id":"23", "ar_name":"عين الباردة", "longitude":"36.6718947", "latitude":"7.5182507"},
{"id":"850","post_code":"23010","name":"Chetaibi","wilaya_id":"23", "ar_name":"شطايبي", "longitude":"37.0130857", "latitude":"7.3259042"},
{"id":"851","post_code":"23011","name":"Sidi Amer","wilaya_id":"23", "ar_name":"سيدي عمار", "longitude":"36.8079881", "latitude":"7.5912068"},
{"id":"852","post_code":"23012","name":"Treat","wilaya_id":"23", "ar_name":"التريعات", "longitude":"36.933511", "latitude":"7.3342297"},
{"id":"853","post_code":"24001","name":"Guelma","wilaya_id":"24", "ar_name":"قالمة", "longitude":"36.4569037", "latitude":"7.3984116"},
{"id":"854","post_code":"24002","name":"Nechmaya","wilaya_id":"24", "ar_name":"نشماية", "longitude":"36.611178", "latitude":"7.5036084"},
{"id":"855","post_code":"24003","name":"Bouati Mahmoud","wilaya_id":"24", "ar_name":"بوعاتي محمود", "longitude":"36.5895674", "latitude":"7.3207353"},
{"id":"856","post_code":"24004","name":"Oued Zenati","wilaya_id":"24", "ar_name":"وادي الزناتي", "longitude":"36.3153995", "latitude":"7.1469496"},
{"id":"857","post_code":"24005","name":"Tamlouka","wilaya_id":"24", "ar_name":"تاملوكة", "longitude":"36.1569512", "latitude":"7.1315431"},
{"id":"858","post_code":"24006","name":"Oued Fragha","wilaya_id":"24", "ar_name":"وادي فراغة", "longitude":"36.5567569", "latitude":"7.7080895"},
{"id":"859","post_code":"24007","name":"Ain Sandel","wilaya_id":"24", "ar_name":"عين صندل", "longitude":"36.2432261", "latitude":"7.5088549"},
{"id":"860","post_code":"24008","name":"Ras El Agba","wilaya_id":"24", "ar_name":"راس العقبة", "longitude":"36.3734915", "latitude":"7.2205067"},
{"id":"861","post_code":"24009","name":"Dahouara","wilaya_id":"24", "ar_name":"الدهوارة", "longitude":"36.3509675", "latitude":"7.728399"},
{"id":"862","post_code":"24010","name":"Belkhir","wilaya_id":"24", "ar_name":"بلخير", "longitude":"36.4607299", "latitude":"7.4686431"},
{"id":"863","post_code":"24011","name":"Ben Djarah","wilaya_id":"24", "ar_name":"بن جراح", "longitude":"36.4323579", "latitude":"7.3656034"},
{"id":"864","post_code":"24012","name":"Bou Hamdane","wilaya_id":"24", "ar_name":"بوحمدان", "longitude":"36.4607875", "latitude":"7.0672535"},
{"id":"865","post_code":"24013","name":"Ain Makhlouf","wilaya_id":"24", "ar_name":"عين مخلوف", "longitude":"36.2433466", "latitude":"7.2423719"},
{"id":"866","post_code":"24014","name":"Ain Ben Beida","wilaya_id":"24", "ar_name":"عين بن بيضاء", "longitude":"36.6166662", "latitude":"7.6912452"},
{"id":"867","post_code":"24015","name":"Khezara","wilaya_id":"24", "ar_name":"خزارة", "longitude":"36.3706489", "latitude":"7.5256133"},
{"id":"868","post_code":"24016","name":"Beni Mezline","wilaya_id":"24", "ar_name":"بني مزلين", "longitude":"36.4813374", "latitude":"7.5999319"},
{"id":"869","post_code":"24017","name":"Bou Hachana","wilaya_id":"24", "ar_name":"بوحشانة", "longitude":"36.3062461", "latitude":"7.5039195"},
{"id":"870","post_code":"24018","name":"Guelaat Bou Sbaa","wilaya_id":"24", "ar_name":"قلعة بوصبع", "longitude":"36.5487411", "latitude":"7.4658322"},
{"id":"871","post_code":"24019","name":"Hammam Maskhoutine","wilaya_id":"24", "ar_name":"حمام مسخوطين", "longitude":"36.4599991", "latitude":"7.2612452"},
{"id":"872","post_code":"24020","name":"El Fedjoudj","wilaya_id":"24", "ar_name":"الفجوج", "longitude":"36.5092883", "latitude":"7.3712466"},
{"id":"873","post_code":"24021","name":"Bordj Sabat","wilaya_id":"24", "ar_name":"برج صباط", "longitude":"36.4020468", "latitude":"7.038288"},
{"id":"874","post_code":"24022","name":"Hamman Nbail","wilaya_id":"24", "ar_name":"حمام النبايل", "longitude":"36.3240283", "latitude":"7.6336956"},
{"id":"875","post_code":"24023","name":"Ain Larbi","wilaya_id":"24", "ar_name":"عين العربى", "longitude":"36.4569088", "latitude":"7.4334312"},
{"id":"876","post_code":"24024","name":"Medjez Amar","wilaya_id":"24", "ar_name":"مجاز عمار", "longitude":"36.4453028", "latitude":"7.3073994"},
{"id":"877","post_code":"24025","name":"Bouchegouf","wilaya_id":"24", "ar_name":"بوشقوف", "longitude":"36.4698174", "latitude":"7.7139471"},
{"id":"878","post_code":"24026","name":"Heliopolis","wilaya_id":"24", "ar_name":"ھيليوبوليس", "longitude":"36.5051137", "latitude":"7.4183462"},
{"id":"879","post_code":"24027","name":"Houari Boumediene","wilaya_id":"24", "ar_name":"هواري بومدين", "longitude":"36.4170552", "latitude":"7.2737237"},
{"id":"880","post_code":"24028","name":"Roknia","wilaya_id":"24", "ar_name":"الركنية", "longitude":"36.5448453", "latitude":"7.2242832"},
{"id":"881","post_code":"24029","name":"Salaoua Announa","wilaya_id":"24", "ar_name":"سلاوة عنونة", "longitude":"36.3864653", "latitude":"7.2468782"},
{"id":"882","post_code":"24030","name":"Medjez Sfa","wilaya_id":"24", "ar_name":"مجاز الصفاء", "longitude":"36.4343861", "latitude":"7.7743936"},
{"id":"883","post_code":"24031","name":"Boumahra Ahmed","wilaya_id":"24", "ar_name":"بومهرة أحمد", "longitude":"36.4588487", "latitude":"7.5044774"},
{"id":"884","post_code":"24032","name":"Ain Reggada","wilaya_id":"24", "ar_name":"عين رقادة", "longitude":"36.2606161", "latitude":"7.0637584"},
{"id":"885","post_code":"24033","name":"Oued Cheham","wilaya_id":"24", "ar_name":"وادي الشحم", "longitude":"36.3814206", "latitude":"7.75918"},
{"id":"886","post_code":"24034","name":"Djeballah Khemissi","wilaya_id":"24", "ar_name":"جبالة لخميسي", "longitude":"36.4642865", "latitude":"7.5646019"},
{"id":"887","post_code":"25001","name":"Constantine","wilaya_id":"25", "ar_name":"قسنطينة", "longitude":"36.3539041", "latitude":"6.5073908"},
{"id":"888","post_code":"25002","name":"Hamma Bouziane","wilaya_id":"25", "ar_name":"حامة بوزيان", "longitude":"36.4279139", "latitude":"6.4871452"},
{"id":"889","post_code":"25003","name":"Ibn Badis","wilaya_id":"25", "ar_name":"إبن باديس", "longitude":"36.3480237", "latitude":"6.7097178"},
{"id":"890","post_code":"25004","name":"Zighoud Youcef","wilaya_id":"25", "ar_name":"زيغود يوسف", "longitude":"36.5297763", "latitude":"6.5941469"},
{"id":"891","post_code":"25005","name":"Didouche Mourad","wilaya_id":"25", "ar_name":"ديدوش مراد", "longitude":"36.4639526", "latitude":"6.5927385"},
{"id":"892","post_code":"25006","name":"El Khroub","wilaya_id":"25", "ar_name":"الخروب", "longitude":"36.2838625", "latitude":"6.5156439"},
{"id":"893","post_code":"25007","name":"Ain Abid","wilaya_id":"25", "ar_name":"عين عبيد", "longitude":"36.2465349", "latitude":"6.7979518"},
{"id":"894","post_code":"25008","name":"Beni Hamiden","wilaya_id":"25", "ar_name":"بني حميدان", "longitude":"36.5203461", "latitude":"6.4731762"},
{"id":"895","post_code":"25009","name":"Ouled Rahmoune","wilaya_id":"25", "ar_name":"أولاد رحمون", "longitude":"36.1816075", "latitude":"6.5482917"},
{"id":"896","post_code":"25010","name":"Ain Smara","wilaya_id":"25", "ar_name":"عين سمارة", "longitude":"36.2946549", "latitude":"6.4221713"},
{"id":"897","post_code":"25011","name":"Mesaoud Boudjeriou","wilaya_id":"25", "ar_name":"مسعود بوجريو", "longitude":"36.4463789", "latitude":"6.3586781"},
{"id":"898","post_code":"25012","name":"Ibn Ziad","wilaya_id":"25", "ar_name":"ابن زياد", "longitude":"36.3691447", "latitude":"6.3523267"},
{"id":"899","post_code":"26001","name":"Medea","wilaya_id":"26", "ar_name":"المدية", "longitude":"36.2838204", "latitude":"2.7028058"},
{"id":"900","post_code":"26002","name":"Ouzera","wilaya_id":"26", "ar_name":"وزرة", "longitude":"36.2615351", "latitude":"2.8073906"},
{"id":"901","post_code":"26003","name":"Ouled Maaref","wilaya_id":"26", "ar_name":"أولاد معرف", "longitude":"35.7948583", "latitude":"2.9102508"},
{"id":"902","post_code":"26004","name":"Ain Boucif","wilaya_id":"26", "ar_name":"عين بوسيف", "longitude":"35.8347645", "latitude":"3.0031194"},
{"id":"903","post_code":"26005","name":"Aissaouia","wilaya_id":"26", "ar_name":"العيساوية", "longitude":"36.4109256", "latitude":"3.1566785"},
{"id":"904","post_code":"26006","name":"Ouled Deide","wilaya_id":"26", "ar_name":"أولاد دايد", "longitude":"36.1013316", "latitude":"2.9097357"},
{"id":"905","post_code":"26007","name":"El Omaria","wilaya_id":"26", "ar_name":"العمارية", "longitude":"36.3025098", "latitude":"2.9355786"},
{"id":"906","post_code":"26008","name":"Derrag","wilaya_id":"26", "ar_name":"دراڨ", "longitude":"35.8431534", "latitude":"2.2165241"},
{"id":"907","post_code":"26009","name":"El Guelbelkebir","wilaya_id":"26", "ar_name":"القلب الكبير", "longitude":"36.2477512", "latitude":"3.3416865"},
{"id":"908","post_code":"26010","name":"Bouaiche","wilaya_id":"26", "ar_name":"بوعيش", "longitude":"35.6097793", "latitude":"2.1731367"},
{"id":"909","post_code":"26011","name":"Mezerena","wilaya_id":"26", "ar_name":"مزغنة", "longitude":"36.3467956", "latitude":"3.2758115"},
{"id":"910","post_code":"26012","name":"Ouled Brahim","wilaya_id":"26", "ar_name":"أولاد إبراهيم", "longitude":"36.2195223", "latitude":"2.8815053"},
{"id":"911","post_code":"26013","name":"Tizi Mahdi","wilaya_id":"26", "ar_name":"تيزي المهدي", "longitude":"36.2131052", "latitude":"2.7286213"},
{"id":"912","post_code":"26014","name":"Sidi Ziane","wilaya_id":"26", "ar_name":"سيدي زيان", "longitude":"36.0275429", "latitude":"3.1710122"},
{"id":"913","post_code":"26015","name":"Tamesguida","wilaya_id":"26", "ar_name":"تمزڨيدة", "longitude":"36.3206865", "latitude":"2.6122974"},
{"id":"914","post_code":"26016","name":"El Hamdania","wilaya_id":"26", "ar_name":"الحمدانية", "longitude":"36.3465239", "latitude":"2.7878641"},
{"id":"915","post_code":"26017","name":"Kef Lakhdar","wilaya_id":"26", "ar_name":"الكاف الأخضر", "longitude":"35.9218925", "latitude":"3.1958172"},
{"id":"916","post_code":"26018","name":"Chelalet El Adhaoura","wilaya_id":"26", "ar_name":"شلالة العذاورة", "longitude":"35.9605086", "latitude":"3.3358071"},
{"id":"917","post_code":"26019","name":"Bouskene","wilaya_id":"26", "ar_name":"بوسكن", "longitude":"36.1950571", "latitude":"3.1348775"},
{"id":"918","post_code":"26020","name":"Rebaia","wilaya_id":"26", "ar_name":"الربعية", "longitude":"36.0223957", "latitude":"3.0759976"},
{"id":"919","post_code":"26021","name":"Bouchrahil","wilaya_id":"26", "ar_name":"بوشراحيل", "longitude":"36.2735556", "latitude":"3.1350492"},
{"id":"920","post_code":"26022","name":"Ouled Hellal","wilaya_id":"26", "ar_name":"أولاد هلال", "longitude":"36.0034964", "latitude":"2.3924341"},
{"id":"921","post_code":"26023","name":"Tafraout","wilaya_id":"26", "ar_name":"تافراوت", "longitude":"35.9953336", "latitude":"3.2743524"},
{"id":"922","post_code":"26024","name":"Baata","wilaya_id":"26", "ar_name":"بعطة", "longitude":"36.3720772", "latitude":"3.059561"},
{"id":"923","post_code":"26025","name":"Boghar","wilaya_id":"26", "ar_name":"بوغار", "longitude":"35.9371699", "latitude":"2.6326821"},
{"id":"924","post_code":"26026","name":"Sidi Naamane","wilaya_id":"26", "ar_name":"سيدي نعمان", "longitude":"36.2654589", "latitude":"3.0135987"},
{"id":"925","post_code":"26027","name":"Ouled Bouachra","wilaya_id":"26", "ar_name":"أولاد بوعشرة", "longitude":"36.0987018", "latitude":"2.6346133"},
{"id":"926","post_code":"26028","name":"Sidi Zahar","wilaya_id":"26", "ar_name":"سيدي زهار", "longitude":"36.0709876", "latitude":"3.2656834"},
{"id":"927","post_code":"26029","name":"Oued Harbil","wilaya_id":"26", "ar_name":"وادي حربيل", "longitude":"36.2397747", "latitude":"2.5734161"},
{"id":"928","post_code":"26030","name":"Benchicao","wilaya_id":"26", "ar_name":"بن شكاو", "longitude":"36.1913329", "latitude":"2.7682088"},
{"id":"929","post_code":"26031","name":"Sidi Damed","wilaya_id":"26", "ar_name":"سيدي دامد", "longitude":"35.8219378", "latitude":"3.2306644"},
{"id":"930","post_code":"26032","name":"Aziz","wilaya_id":"26", "ar_name":"عزيز", "longitude":"35.7544258", "latitude":"2.2227155"},
{"id":"931","post_code":"26033","name":"Souagui","wilaya_id":"26", "ar_name":"السواڨي", "longitude":"36.1114893", "latitude":"3.1635449"},
{"id":"932","post_code":"26034","name":"Zoubiria","wilaya_id":"26", "ar_name":"الزبيرية", "longitude":"36.065692", "latitude":"2.7869199"},
{"id":"933","post_code":"26035","name":"Ksar El Boukhari","wilaya_id":"26", "ar_name":"قصر البخاري", "longitude":"35.8750361", "latitude":"2.6861545"},
{"id":"934","post_code":"26036","name":"El Azizia","wilaya_id":"26", "ar_name":"العزيزية", "longitude":"36.3151797", "latitude":"3.4549421"},
{"id":"935","post_code":"26037","name":"Djouab","wilaya_id":"26", "ar_name":"جواب", "longitude":"36.1117003", "latitude":"3.3535741"},
{"id":"936","post_code":"26038","name":"Chahbounia","wilaya_id":"26", "ar_name":"الشهبونية", "longitude":"35.5846723", "latitude":"2.4968901"},
{"id":"937","post_code":"26039","name":"Meghraoua","wilaya_id":"26", "ar_name":"مغراوة", "longitude":"36.3822217", "latitude":"3.4680723"},
{"id":"938","post_code":"26040","name":"Cheniguel","wilaya_id":"26", "ar_name":"شنيڨل", "longitude":"35.9572465", "latitude":"3.4658406"},
{"id":"939","post_code":"26041","name":"Ain Ouksir","wilaya_id":"26", "ar_name":"عين القصير", "longitude":"35.7760055", "latitude":"3.3282462"},
{"id":"940","post_code":"26042","name":"Oum El Djalil","wilaya_id":"26", "ar_name":"أم الجليل", "longitude":"35.8304434", "latitude":"2.6069329"},
{"id":"941","post_code":"26043","name":"Ouamri","wilaya_id":"26", "ar_name":"عوامري", "longitude":"36.2537814", "latitude":"2.4798606"},
{"id":"942","post_code":"26044","name":"Si Mahdjoub","wilaya_id":"26", "ar_name":"سى المحجوب", "longitude":"36.1691158", "latitude":"2.7172917"},
{"id":"943","post_code":"26045","name":"Tlatet Eddouair","wilaya_id":"26", "ar_name":"ثلاثة الدوائر", "longitude":"35.9963494", "latitude":"2.8731719"},
{"id":"944","post_code":"26046","name":"Beni Slimane","wilaya_id":"26", "ar_name":"بني سليمان", "longitude":"36.2181313", "latitude":"3.2412217"},
{"id":"945","post_code":"26047","name":"Berrouaghia","wilaya_id":"26", "ar_name":"البرواڨية", "longitude":"36.1528037", "latitude":"2.8436539"},
{"id":"946","post_code":"26048","name":"Seghouane","wilaya_id":"26", "ar_name":"سغوان", "longitude":"35.9968593", "latitude":"2.8356717"},
{"id":"947","post_code":"26049","name":"Meftaha","wilaya_id":"26", "ar_name":"المفاتحة", "longitude":"35.8821773", "latitude":",2.8140852"},
{"id":"948","post_code":"26050","name":"Mihoub","wilaya_id":"26", "ar_name":"ميهوب", "longitude":"36.3861353", "latitude":"3.3691094"},
{"id":"949","post_code":"26051","name":"Boughezoul","wilaya_id":"26", "ar_name":"بوغزول", "longitude":"35.6795297", "latitude":"2.6264943"},
{"id":"950","post_code":"26052","name":"Tablat","wilaya_id":"26", "ar_name":"تابلاط", "longitude":"36.4231476", "latitude":"3.2734726"},
{"id":"951","post_code":"26053","name":"Deux Bassins","wilaya_id":"26", "ar_name":"فج الحوضين", "longitude":"36.4676678", "latitude":"3.2670586"},
{"id":"952","post_code":"26054","name":"Draa Essamar","wilaya_id":"26", "ar_name":"ذراع السمار", "longitude":"36.2689828", "latitude":"2.6257727"},
{"id":"953","post_code":"26055","name":"Sidi Errabia","wilaya_id":"26", "ar_name":"سيدي الربيع", "longitude":"36.282548", "latitude":"3.2951253"},
{"id":"954","post_code":"26056","name":"Bir Ben Laabed","wilaya_id":"26", "ar_name":"بئر بن العابد", "longitude":"36.1905774", "latitude":"3.3408711"},
{"id":"955","post_code":"26057","name":"El Ouinet","wilaya_id":"26", "ar_name":"العوينات", "longitude":"35.8638348", "latitude":"2.9963896"},
{"id":"956","post_code":"26058","name":"Ouled Antar","wilaya_id":"26", "ar_name":"أولاد عنتر", "longitude":"35.9546472", "latitude":"2.5264159"},
{"id":"957","post_code":"26059","name":"Bouaichoune","wilaya_id":"26", "ar_name":"بوعيشون", "longitude":"36.1351828", "latitude":"2.5703691"},
{"id":"958","post_code":"26060","name":"Hannacha","wilaya_id":"26", "ar_name":"حناشة", "longitude":"36.1693058", "latitude":"2.5216172"},
{"id":"959","post_code":"26061","name":"Sedraia","wilaya_id":"26", "ar_name":"سدراية", "longitude":"36.2330914", "latitude":"3.4389756"},
{"id":"960","post_code":"26062","name":"Medjebar","wilaya_id":"26", "ar_name":"مجبر", "longitude":"35.9562974", "latitude":"2.7541324"},
{"id":"961","post_code":"26063","name":"Khams Djouamaa","wilaya_id":"26", "ar_name":"خمس جوامع", "longitude":"36.1478178", "latitude":"3.0538534"},
{"id":"962","post_code":"26064","name":"Saneg","wilaya_id":"26", "ar_name":"سانڨ", "longitude":"35.8406053", "latitude":"2.760999"},
{"id":"963","post_code":"27001","name":"Mostaganem","wilaya_id":"27", "ar_name":"مستغانم", "longitude":"35.9751637", "latitude":"0.0448868"},
{"id":"964","post_code":"27002","name":"Sayada","wilaya_id":"27", "ar_name":"صيادة", "longitude":"35.9321147", "latitude":"0.0894758"},
{"id":"965","post_code":"27003","name":"Fornaka","wilaya_id":"27", "ar_name":"فرناكة", "longitude":"35.7604979", "latitude":"-0.0327569"},
{"id":"966","post_code":"27004","name":"Stidia","wilaya_id":"27", "ar_name":"ستيدية", "longitude":"35.8286516", "latitude":"-0.0143553"},
{"id":"967","post_code":"27005","name":"Ain Nouissy","wilaya_id":"27", "ar_name":"عين نويسي", "longitude":"35.8032737", "latitude":"0.0398253"},
{"id":"968","post_code":"27006","name":"Hassi Maameche","wilaya_id":"27", "ar_name":"حاسي مماش", "longitude":"35.8606343", "latitude":"0.0229141"},
{"id":"969","post_code":"27007","name":"Ain Tadles","wilaya_id":"27", "ar_name":"عين تادلس", "longitude":"35.9940136", "latitude":"0.2932405"},
{"id":"970","post_code":"27008","name":"Sour","wilaya_id":"27", "ar_name":"صور", "longitude":"36.0006026", "latitude":"0.3356516"},
{"id":"971","post_code":"27009","name":"Oued El Kheir","wilaya_id":"27", "ar_name":"واد الخير", "longitude":"35.9498792", "latitude":"0.3768825"},
{"id":"972","post_code":"27010","name":"Sidi Bellater","wilaya_id":"27", "ar_name":"سيدي بلعاتر", "longitude":"36.0267851", "latitude":"0.2672123"},
{"id":"973","post_code":"27011","name":"Kheiredine","wilaya_id":"27", "ar_name":"خير الدين", "longitude":"35.9549081", "latitude":"0.1016209"},
{"id":"974","post_code":"27012","name":"Sidi Ali","wilaya_id":"27", "ar_name":"سيدي علي", "longitude":"36.0968612", "latitude":"0.3972458"},
{"id":"975","post_code":"27013","name":"Abdelmalek Ramdane","wilaya_id":"27", "ar_name":"عبد المالك رمضان", "longitude":"36.1034611", "latitude":"0.2672312"},
{"id":"976","post_code":"27014","name":"Hadjadj","wilaya_id":"27", "ar_name":"حجاج", "longitude":"36.0963942", "latitude":"0.3163505"},
{"id":"977","post_code":"27015","name":"Nekmaria","wilaya_id":"27", "ar_name":"نقمارية", "longitude":"36.1880382", "latitude":"0.6165754"},
{"id":"978","post_code":"27016","name":"Sidi Lakhdar","wilaya_id":"27", "ar_name":"سيدي لخضر", "longitude":"36.1633785", "latitude":"0.4315995"},
{"id":"979","post_code":"27017","name":"Achaacha","wilaya_id":"27", "ar_name":"عشعاشة", "longitude":"36.2449302", "latitude":"0.6235599"},
{"id":"980","post_code":"27018","name":"Khadra","wilaya_id":"27", "ar_name":"خضراء", "longitude":"36.2538598", "latitude":"0.5688214"},
{"id":"981","post_code":"27019","name":"Bouguirat","wilaya_id":"27", "ar_name":"بوقيراط", "longitude":"35.751283", "latitude":"0.2465079"},
{"id":"982","post_code":"27020","name":"Sirat","wilaya_id":"27", "ar_name":"سيرات", "longitude":"35.780582", "latitude":"0.183571"},
{"id":"983","post_code":"27021","name":"Ain Sidi Cherif","wilaya_id":"27", "ar_name":"عين سيدي شريف", "longitude":"35.8358105", "latitude":"0.1163113"},
{"id":"984","post_code":"27022","name":"Mesra","wilaya_id":"27", "ar_name":"ماسرة", "longitude":"35.8389326", "latitude":"0.1574992"},
{"id":"985","post_code":"27023","name":"Mansourah","wilaya_id":"27", "ar_name":"منصورة", "longitude":"35.8432384", "latitude":"0.2285671"},
{"id":"986","post_code":"27024","name":"Souaflia","wilaya_id":"27", "ar_name":"سوافلية", "longitude":"35.8616131", "latitude":"0.3271866"},
{"id":"987","post_code":"27025","name":"Ouled Boughalem","wilaya_id":"27", "ar_name":"أوالد بوغالم", "longitude":"36.3160232", "latitude":"0.661025"},
{"id":"988","post_code":"27026","name":"Ouled Maallah","wilaya_id":"27", "ar_name":"أولاد مع اللّه", "longitude":"36.0072076", "latitude":"0.5870175"},
{"id":"989","post_code":"27027","name":"Mezghrane","wilaya_id":"27", "ar_name":"مزغران", "longitude":"35.8844492", "latitude":"0.0250619"},
{"id":"990","post_code":"27028","name":"Ain Boudinar","wilaya_id":"27", "ar_name":"عين بودينار", "longitude":"36.0091691", "latitude":"0.1825405"},
{"id":"991","post_code":"27029","name":"Tazgait","wilaya_id":"27", "ar_name":"تزقايت", "longitude":"36.0944945", "latitude":"0.5438018"},
{"id":"992","post_code":"27030","name":"Safsaf","wilaya_id":"27", "ar_name":"صفصاف", "longitude":"35.8436642", "latitude":"0.3698229"},
{"id":"993","post_code":"27031","name":"Touahria","wilaya_id":"27", "ar_name":"طواھيرية", "longitude":"35.8109224", "latitude":"0.2054358"},
{"id":"994","post_code":"27032","name":"El Hassiane","wilaya_id":"27", "ar_name":"الحسيان", "longitude":"35.7530845", "latitude":"0.1155925"},
{"id":"995","post_code":"28001","name":"Msila","wilaya_id":"28", "ar_name":"المسيلة", "longitude":"35.7210664", "latitude":"4.3786421"},
{"id":"996","post_code":"28002","name":"Maadid","wilaya_id":"28", "ar_name":"المعاضيد", "longitude":"35.7973219", "latitude":"4.6873829"},
{"id":"997","post_code":"28003","name":"Hammam Dhalaa","wilaya_id":"28", "ar_name":"حمـام الضلعة", "longitude":"35.9303231", "latitude":"4.2004576"},
{"id":"998","post_code":"28004","name":"Ouled Derradj","wilaya_id":"28", "ar_name":"أولاد دراج", "longitude":"35.6715243", "latitude":"4.6290103"},
{"id":"999","post_code":"28005","name":"Tarmount","wilaya_id":"28", "ar_name":"تارمونت", "longitude":"35.8174498", "latitude":"4.1865609"},
{"id":"1000","post_code":"28006","name":"Mtarfa","wilaya_id":"28", "ar_name":"مطارفة", "longitude":"35.6887798", "latitude":"4.5064011"},
{"id":"1001","post_code":"28007","name":"Khoubana","wilaya_id":"28", "ar_name":"خبانة", "longitude":"35.3114022", "latitude":"4.3378725"},
{"id":"1002","post_code":"28008","name":"M'cif","wilaya_id":"28", "ar_name":"مسيف", "longitude":"35.315", "latitude":"4.5858374"},
{"id":"1003","post_code":"28009","name":"Chellal","wilaya_id":"28", "ar_name":"شلال", "longitude":"35.5134267", "latitude":",4.2335024"},
{"id":"1004","post_code":"28010","name":"Ouled Madhi","wilaya_id":"28", "ar_name":"أولاد مـاضي", "longitude":"35.524776", "latitude":"4.4095411"},
{"id":"1005","post_code":"28011","name":"Magra","wilaya_id":"28", "ar_name":"مقرة", "longitude":"35.6076161", "latitude":"4.9762812"},
{"id":"1006","post_code":"28012","name":"Berhoum","wilaya_id":"28", "ar_name":"برهوم", "longitude":"35.6351693", "latitude":"4.9263356"},
{"id":"1007","post_code":"28013","name":"Ain Khadra","wilaya_id":"28", "ar_name":"عين الخضراء", "longitude":"35.5456141", "latitude":"4.8777554"},
{"id":"1008","post_code":"28014","name":"Ouled Addi Guebala","wilaya_id":"28", "ar_name":"اولاد عدي لقبالة", "longitude":"35.676541", "latitude":"4.7484866"},
{"id":"1009","post_code":"28015","name":"Belaiba","wilaya_id":"28", "ar_name":"بلعايبة", "longitude":"35.5682674", "latitude":"5.1394531"},
{"id":"1010","post_code":"28016","name":"Sidi Aissa","wilaya_id":"28", "ar_name":"سيدي عيسى", "longitude":"35.8255783", "latitude":"3.6556044"},
{"id":"1011","post_code":"28017","name":"Ain El Hadjel","wilaya_id":"28", "ar_name":"عين الحجل", "longitude":"35.6184864", "latitude":"3.6830434"},
{"id":"1012","post_code":"28018","name":"Sidi Hadjeres","wilaya_id":"28", "ar_name":"سيدي ھجرس", "longitude":"35.7020457", "latitude":"3.7978373"},
{"id":"1013","post_code":"28019","name":"Ouanougha","wilaya_id":"28", "ar_name":"ونوغة", "longitude":"35.9310253", "latitude":"4.041585"},
{"id":"1014","post_code":"28020","name":"Bou Saada","wilaya_id":"28", "ar_name":"بوسعادة", "longitude":"35.2131065", "latitude":"4.0429152"},
{"id":"1015","post_code":"28021","name":"Ouled Sidi Brahim","wilaya_id":"28", "ar_name":"أولاد سيدي ابراهيم", "longitude":"35.3495826", "latitude":"3.9983262"},
{"id":"1016","post_code":"28022","name":"Sidi Ameur","wilaya_id":"28", "ar_name":"سيدي عامر", "longitude":"35.3659746", "latitude":"3.6834134"},
{"id":"1017","post_code":"28023","name":"Tamsa","wilaya_id":"28", "ar_name":"تامسة", "longitude":"35.2027774", "latitude":"3.7451258"},
{"id":"1018","post_code":"28024","name":"Ben Srour","wilaya_id":"28", "ar_name":"بن سرور", "longitude":"34.9930313", "latitude":"4.4258488"},
{"id":"1019","post_code":"28025","name":"Ouled Slimane","wilaya_id":"28", "ar_name":"أولاد سليمان", "longitude":"34.9246808", "latitude":"4.7197332"},
{"id":"1020","post_code":"28026","name":"El Houamed","wilaya_id":"28", "ar_name":"الحوامد", "longitude":"35.1664895", "latitude":"4.3637934"},
{"id":"1021","post_code":"28027","name":"El Hamel","wilaya_id":"28", "ar_name":"الهامل", "longitude":"35.1212832", "latitude":"3.9996217"},
{"id":"1022","post_code":"28028","name":"Ouled Mansour","wilaya_id":"28", "ar_name":"أولاد منصور", "longitude":"35.7319531", "latitude":"4.2625131"},
{"id":"1023","post_code":"28029","name":"Maarif","wilaya_id":"28", "ar_name":"المعاريف", "longitude":"35.360452", "latitude":"4.1652669"},
{"id":"1024","post_code":"28030","name":"Dehahna","wilaya_id":"28", "ar_name":"الدهاهنة", "longitude":"35.7328552", "latitude":"4.9668048"},
{"id":"1025","post_code":"28031","name":"Bouti Sayah","wilaya_id":"28", "ar_name":"بوطي السايح", "longitude":"35.6757367", "latitude":"3.4628716"},
{"id":"1026","post_code":"28032","name":"Khettouti Sed Djir","wilaya_id":"28", "ar_name":"خطوطي سد الجير", "longitude":"35.679121", "latitude":"4.0557899"},
{"id":"1027","post_code":"28033","name":"Zarzour","wilaya_id":"28", "ar_name":"الزرزور", "longitude":"35.0536128", "latitude":"4.7163"},
{"id":"1028","post_code":"28034","name":"Oued Chair","wilaya_id":"28", "ar_name":"محمد بوضياف", "longitude":"34.8994154", "latitude":"4.3559827"},
{"id":"1029","post_code":"28035","name":"Benzouh","wilaya_id":"28", "ar_name":"بن الزوه", "longitude":"35.4561038", "latitude":"3.9870825"},
{"id":"1030","post_code":"28036","name":"Bir Foda","wilaya_id":"28", "ar_name":"بير الفضة", "longitude":"34.8183094", "latitude":"3.7172307"},
{"id":"1031","post_code":"28037","name":"Ain Fares","wilaya_id":"28", "ar_name":"عين فارس", "longitude":"34.742717", "latitude":"4.3576134"},
{"id":"1032","post_code":"28038","name":"Sidi Mhamed","wilaya_id":"28", "ar_name":"سيدي محمد", "longitude":"34.7726262", "latitude":"4.1440667"},
{"id":"1033","post_code":"28039","name":"Ouled Atia","wilaya_id":"28", "ar_name":"منعة", "longitude":"35.1324378", "latitude":"3.455394"},
{"id":"1034","post_code":"28040","name":"Souamaa","wilaya_id":"28", "ar_name":"الصوامع", "longitude":"35.5173258", "latitude":"4.5889058"},
{"id":"1035","post_code":"28041","name":"Ain El Melh","wilaya_id":"28", "ar_name":"عين الملح", "longitude":"34.7887663", "latitude":"4.0208139"},
{"id":"1036","post_code":"28042","name":"Medjedel","wilaya_id":"28", "ar_name":"مجدل", "longitude":"35.0618873", "latitude":"3.3440071"},
{"id":"1037","post_code":"28043","name":"Slim","wilaya_id":"28", "ar_name":"سليم", "longitude":"34.9545036", "latitude":"3.7464989"},
{"id":"1038","post_code":"28044","name":"Ain Errich","wilaya_id":"28", "ar_name":"عين الريش", "longitude":"34.4919894", "latitude":"3.8159469"},
{"id":"1039","post_code":"28045","name":"Beni Ilmane","wilaya_id":"28", "ar_name":"بنى يلمان", "longitude":"35.9460168", "latitude":"3.934125"},
{"id":"1040","post_code":"28046","name":"Oultene","wilaya_id":"28", "ar_name":"ولتام", "longitude":"35.1127067", "latitude":"4.1979763"},
{"id":"1041","post_code":"28047","name":"Djebel Messaad","wilaya_id":"28", "ar_name":"جبل مساعد", "longitude":"34.9863778", "latitude":"4.0131749"},
{"id":"1042","post_code":"29001","name":"Mascara","wilaya_id":"29", "ar_name":"مـعـسـكـر", "longitude":"35.3185", "latitude":"0.1511"},
{"id":"1043","post_code":"29002","name":"Bou Hanifia","wilaya_id":"29", "ar_name":"بوحنيفية", "longitude":"35.2512", "latitude":"-0.0494"},
{"id":"1044","post_code":"29003","name":"Tizi","wilaya_id":"29", "ar_name":"تيزي", "longitude":"35.2512", "latitude":"0.0714"},
{"id":"1045","post_code":"29004","name":"Hacine","wilaya_id":"29", "ar_name":"حسين", "longitude":"35.3969", "latitude":"-0.0055"},
{"id":"1046","post_code":"29005","name":"Maoussa","wilaya_id":"29", "ar_name":"ماوسة", "longitude":"35.314", "latitude":"0.2472"},
{"id":"1047","post_code":"29006","name":"Teghennif","wilaya_id":"29", "ar_name":"تيغنيف", "longitude":"35.3521", "latitude":"0.3323"},
{"id":"1048","post_code":"29007","name":"El Hachem","wilaya_id":"29", "ar_name":"الهاشم", "longitude":"35.3028", "latitude":"0.4834"},
{"id":"1049","post_code":"29008","name":"Sidi Kada","wilaya_id":"29", "ar_name":"سيدي قادة", "longitude":"35.2692", "latitude":"0.3488"},
{"id":"1050","post_code":"29009","name":"Zelmata","wilaya_id":"29", "ar_name":"زلماطة", "longitude":"35.2266", "latitude":"0.4752"},
{"id":"1051","post_code":"29010","name":"Oued El Abtal","wilaya_id":"29", "ar_name":"واد الأبطال", "longitude":"35.3924", "latitude":"0.6894"},
{"id":"1052","post_code":"29011","name":"Ain Ferah","wilaya_id":"29", "ar_name":"عين فراح", "longitude":"35.3162", "latitude":"0.7828"},
{"id":"1053","post_code":"29012","name":"Ghriss","wilaya_id":"29", "ar_name":"غريس", "longitude":"35.1839", "latitude":"0.162"},
{"id":"1054","post_code":"29013","name":"Froha","wilaya_id":"29", "ar_name":"فروحة", "longitude":"35.24", "latitude":"0.1263"},
{"id":"1055","post_code":"29014","name":"Matemore","wilaya_id":"29", "ar_name":"مطمور", "longitude":"35.2624", "latitude":"0.2142"},
{"id":"1056","post_code":"29015","name":"Makdha","wilaya_id":"29", "ar_name":"ماقضة", "longitude":"35.1076", "latitude":"0.2966"},
{"id":"1057","post_code":"29016","name":"Sidi Boussaid","wilaya_id":"29", "ar_name":"سيدي بوسعيد", "longitude":"35.2266", "latitude":"0.2966"},
{"id":"1058","post_code":"29017","name":"El Bordj","wilaya_id":"29", "ar_name":"البرج", "longitude":"35.4528", "latitude":"0.3021"},
{"id":"1059","post_code":"29018","name":"Ain Fekan","wilaya_id":"29", "ar_name":"عين فكان", "longitude":"35.1615", "latitude":"-0.0027"},
{"id":"1060","post_code":"29019","name":"Benian","wilaya_id":"29", "ar_name":"بنيان", "longitude":"35.0356", "latitude":"0.2307"},
{"id":"1061","post_code":"29020","name":"Khalouia","wilaya_id":"29", "ar_name":"خلوية", "longitude":"35.3991", "latitude":"0.2966"},
{"id":"1062","post_code":"29021","name":"El Menaouer","wilaya_id":"29", "ar_name":"المناور", "longitude":"35.4797", "latitude":"0.4037"},
{"id":"1063","post_code":"29022","name":"Oued Taria","wilaya_id":"29", "ar_name":"واد التاغية", "longitude":"35.0514", "latitude":"0.0879"},
{"id":"1064","post_code":"29023","name":"Aouf","wilaya_id":"29", "ar_name":"عوف", "longitude":"35.0334", "latitude":"0.3571"},
{"id":"1065","post_code":"29024","name":"Ain Fares","wilaya_id":"29", "ar_name":"عين فارس", "longitude":"35.417", "latitude":"0.2444"},
{"id":"1066","post_code":"29025","name":"Ain Frass","wilaya_id":"29", "ar_name":"عين فراس", "longitude":"35.1255", "latitude":"-0.1511"},
{"id":"1067","post_code":"29026","name":"Sig","wilaya_id":"29", "ar_name":"سيڨ", "longitude":"35.4685", "latitude":"-0.184"},
{"id":"1068","post_code":"29027","name":"Oggaz","wilaya_id":"29", "ar_name":"عقاز", "longitude":"35.502", "latitude":"-0.2664"},
{"id":"1069","post_code":"29028","name":"Alaimia","wilaya_id":"29", "ar_name":"العلايمية", "longitude":"35.6115", "latitude":"-0.2197"},
{"id":"1070","post_code":"29029","name":"El Gaada","wilaya_id":"29", "ar_name":"القعدة", "longitude":"35.4685", "latitude":"-0.184"},
{"id":"1071","post_code":"29030","name":"Zahana","wilaya_id":"29", "ar_name":"زھانة", "longitude":"35.4551", "latitude":"-0.412"},
{"id":"1072","post_code":"29031","name":"Mohammadia","wilaya_id":"29", "ar_name":"المحمدية", "longitude":"35.5266", "latitude":"0.0687"},
{"id":"1073","post_code":"29032","name":"Sidi Abdelmoumene","wilaya_id":"29", "ar_name":"سيدي عبد المومن", "longitude":"35.5713", "latitude":"0.0522"},
{"id":"1074","post_code":"29033","name":"Ferraguig","wilaya_id":"29", "ar_name":"فرقيق", "longitude":"35.4685", "latitude":"0.1538"},
{"id":"1075","post_code":"29034","name":"El Ghomri","wilaya_id":"29", "ar_name":"الغمري", "longitude":"35.6205", "latitude":"0.2005"},
{"id":"1076","post_code":"29035","name":"Sedjerara","wilaya_id":"29", "ar_name":"سجرارة", "longitude":"35.6138", "latitude":"0.3433"},
{"id":"1077","post_code":"29036","name":"Moctadouz","wilaya_id":"29", "ar_name":"مقطع الدوز", "longitude":"35.5356", "latitude":"-0.0494"},
{"id":"1078","post_code":"29037","name":"Bou Henni","wilaya_id":"29", "ar_name":"بوهني", "longitude":"35.4976", "latitude":"-0.0851"},
{"id":"1079","post_code":"29038","name":"Guettena","wilaya_id":"29", "ar_name":"القيطنة", "longitude":"35.3207", "latitude":"-0.2032"},
{"id":"1080","post_code":"29039","name":"El Mamounia","wilaya_id":"29", "ar_name":"المامونية", "longitude":"35.3611", "latitude":"0.1401"},
{"id":"1081","post_code":"29040","name":"El Keurt","wilaya_id":"29", "ar_name":"الكرط", "longitude":"35.3162", "latitude":"0.0906"},
{"id":"1082","post_code":"29041","name":"Gharrous","wilaya_id":"29", "ar_name":"غروس", "longitude":"35.1323", "latitude":"0.401"},
{"id":"1083","post_code":"29042","name":"Gherdjoum","wilaya_id":"29", "ar_name":"ڤرجوم", "longitude":"35.1143", "latitude":"0.0714"},
{"id":"1084","post_code":"29043","name":"Chorfa","wilaya_id":"29", "ar_name":"الشرفة", "longitude":"35.3678", "latitude":"-0.2444"},
{"id":"1085","post_code":"29044","name":"Ras Ain Amirouche","wilaya_id":"29", "ar_name":"رأس العين عميروش", "longitude":"35.5289", "latitude":"-0.2115"},
{"id":"1086","post_code":"29045","name":"Nesmot","wilaya_id":"29", "ar_name":"نسموط", "longitude":"35.1862", "latitude":"0.3845"},
{"id":"1087","post_code":"29046","name":"Sidi Abdeldjebar","wilaya_id":"29", "ar_name":"سيدي عبد الجبار", "longitude":"35.379", "latitude":"0.5246"},
{"id":"1088","post_code":"29047","name":"Sehailia","wilaya_id":"29", "ar_name":"سحايلية", "longitude":"35.4484", "latitude":"0.3818"},
{"id":"1089","post_code":"30001","name":"Ouargla","wilaya_id":"30", "ar_name":"ورڨلة", "longitude":"31.8974", "latitude":"5.3421"},
{"id":"1090","post_code":"30002","name":"Ain Beida","wilaya_id":"30", "ar_name":"عين البيضاء", "longitude":"31.8717", "latitude":"5.3888"},
{"id":"1091","post_code":"30003","name":"Ngoussa","wilaya_id":"30", "ar_name":"نقوسة", "longitude":"32.0744", "latitude":"5.3091"},
{"id":"1092","post_code":"30004","name":"Hassi Messaoud","wilaya_id":"30", "ar_name":"حاسي مسعود", "longitude":"31.6148", "latitude":"6.0727"},
{"id":"1093","post_code":"30005","name":"Rouissat","wilaya_id":"30", "ar_name":"الرويسات", "longitude":"31.8694", "latitude":"5.3366"},
{"id":"1094","post_code":"30006","name":"Balidat Ameur","wilaya_id":"30", "ar_name":"بليدة عامر", "longitude":"32.8854", "latitude":"5.9793"},
{"id":"1095","post_code":"30007","name":"Tebesbest","wilaya_id":"30", "ar_name":"تبسبست", "longitude":"33.0536", "latitude":"6.0809"},
{"id":"1096","post_code":"30008","name":"Nezla","wilaya_id":"30", "ar_name":"نزلة", "longitude":"33.0167", "latitude":"6.0507"},
{"id":"1097","post_code":"30009","name":"Zaouia El Abidia","wilaya_id":"30", "ar_name":"الزاوية العابدية", "longitude":"33.072", "latitude":"6.0809"},
{"id":"1098","post_code":"30010","name":"Sidi Slimane","wilaya_id":"30", "ar_name":"سيدي سليمان", "longitude":"33.2238", "latitude":"6.0919"},
{"id":"1099","post_code":"30011","name":"Sidi Khouiled","wilaya_id":"30", "ar_name":"سيدي خويلد", "longitude":"31.9137", "latitude":"5.419"},
{"id":"1100","post_code":"30012","name":"Hassi Ben Abdellah","wilaya_id":"30", "ar_name":"حاسي بن عبد ﷲ", "longitude":"31.9603", "latitude":"5.4684"},
{"id":"1101","post_code":"30013","name":"Touggourt","wilaya_id":"30", "ar_name":"توقرت", "longitude":"33.0351", "latitude":"6.0672"},
{"id":"1102","post_code":"30014","name":"El Hadjira","wilaya_id":"30", "ar_name":"الحجيرة", "longitude":"32.548", "latitude":"5.5124"},
{"id":"1103","post_code":"30015","name":"Taibet","wilaya_id":"30", "ar_name":"الطيبات", "longitude":"33.019", "latitude":"6.3995"},
{"id":"1104","post_code":"30016","name":"Tamacine","wilaya_id":"30", "ar_name":"تماسين", "longitude":"32.9568", "latitude":"6.0233"},
{"id":"1105","post_code":"30017","name":"Benaceur","wilaya_id":"30", "ar_name":"بن ناصر", "longitude":"33.0444", "latitude":"6.4407"},
{"id":"1106","post_code":"30018","name":"Mnaguer","wilaya_id":"30", "ar_name":"المنقر", "longitude":"33.0467", "latitude":"6.405"},
{"id":"1107","post_code":"30019","name":"Megarine","wilaya_id":"30", "ar_name":"المقارين", "longitude":"33.1272", "latitude":"6.0947"},
{"id":"1108","post_code":"30020","name":"El Allia","wilaya_id":"30", "ar_name":"العالية", "longitude":"32.6313", "latitude":"5.4245"},
{"id":"1109","post_code":"30021","name":"El Borma","wilaya_id":"30", "ar_name":"البرمة", "longitude":"31.5891", "latitude":"9.1791"},
{"id":"1110","post_code":"31001","name":"Oran","wilaya_id":"31", "ar_name":"وهران", "longitude":"35.6372", "latitude":"-0.6482"},
{"id":"1111","post_code":"31002","name":"Gdyel","wilaya_id":"31", "ar_name":"ڨديل", "longitude":"35.7175", "latitude":"-0.423"},
{"id":"1112","post_code":"31003","name":"Bir El Djir","wilaya_id":"31", "ar_name":"بئر الجير", "longitude":"35.6729", "latitude":"-0.5548"},
{"id":"1113","post_code":"31004","name":"Hassi Bounif","wilaya_id":"31", "ar_name":"حاسيْ بُونِيف", "longitude":"35.635", "latitude":"-0.4999"},
{"id":"1114","post_code":"31005","name":"Es Senia","wilaya_id":"31", "ar_name":"السانية", "longitude":"35.5859", "latitude":"-0.618"},
{"id":"1115","post_code":"31006","name":"Arzew","wilaya_id":"31", "ar_name":"أرزيو", "longitude":"35.8044", "latitude":"-0.3214"},
{"id":"1116","post_code":"31007","name":"Bethioua","wilaya_id":"31", "ar_name":"ﺑﻃﻴﻭة", "longitude":"35.7398", "latitude":"-0.2609"},
{"id":"1117","post_code":"31008","name":"Marsat El Hadjadj","wilaya_id":"31", "ar_name":"مَرس ألحَجَاج", "longitude":"35.7198", "latitude":"-0.1675"},
{"id":"1118","post_code":"31009","name":"Ain Turk","wilaya_id":"31", "ar_name":"عيْن التُرْكْ", "longitude":"35.6662", "latitude":"-0.7498"},
{"id":"1119","post_code":"31010","name":"El Ancar","wilaya_id":"31", "ar_name":"العنصر", "longitude":"35.6216", "latitude":"-0.8679"},
{"id":"1120","post_code":"31011","name":"Oued Tlelat","wilaya_id":"31", "ar_name":"وادى تليلات", "longitude":"35.4853", "latitude":"-0.4504"},
{"id":"1121","post_code":"31012","name":"Tafraoui","wilaya_id":"31", "ar_name":"طفراوي", "longitude":"35.4182", "latitude":"-0.5273"},
{"id":"1122","post_code":"31013","name":"Sidi Chami","wilaya_id":"31", "ar_name":"سيدي الشحمي", "longitude":"35.5859", "latitude":"-0.5191"},
{"id":"1123","post_code":"31014","name":"Boufatis","wilaya_id":"31", "ar_name":"بوفاطيس", "longitude":"35.6149", "latitude":"-0.412"},
{"id":"1124","post_code":"31015","name":"Mers El Kebir","wilaya_id":"31", "ar_name":"المرسى الكبير", "longitude":"35.6751", "latitude":"-0.7196"},
{"id":"1125","post_code":"31016","name":"Bousfer","wilaya_id":"31", "ar_name":"بوسفر", "longitude":"35.6461", "latitude":"-0.8102"},
{"id":"1126","post_code":"31017","name":"El Karma","wilaya_id":"31", "ar_name":"الكرمة", "longitude":"35.5479", "latitude":"-0.5795"},
{"id":"1127","post_code":"31018","name":"El Braya","wilaya_id":"31", "ar_name":"ألبْرَيَ", "longitude":"35.5657", "latitude":"-0.5191"},
{"id":"1128","post_code":"31019","name":"Hassi Ben Okba","wilaya_id":"31", "ar_name":"حاسي بن عقبة", "longitude":"35.664", "latitude":"-0.4669"},
{"id":"1129","post_code":"31020","name":"Ben Freha","wilaya_id":"31", "ar_name":"بن فريحة", "longitude":"35.6283", "latitude":"-0.4202"},
{"id":"1130","post_code":"31021","name":"Hassi Mefsoukh","wilaya_id":"31", "ar_name":"حاسي مفسوخ", "longitude":"35.7376", "latitude":"-0.3735"},
{"id":"1131","post_code":"31022","name":"Sidi Ben Yabka","wilaya_id":"31", "ar_name":"سيدي بن يبقى", "longitude":"35.7643", "latitude":"-0.3955"},
{"id":"1132","post_code":"31023","name":"Messerghin","wilaya_id":"31", "ar_name":"مسرغين", "longitude":"35.5546", "latitude":"-0.7306"},
{"id":"1133","post_code":"31024","name":"Boutlelis","wilaya_id":"31", "ar_name":"بوتليليس", "longitude":"35.5076", "latitude":"-0.9009"},
{"id":"1134","post_code":"31025","name":"Ain Kerma","wilaya_id":"31", "ar_name":"عين الكرمة", "longitude":"35.5836", "latitude":"-0.975"},
{"id":"1135","post_code":"31026","name":"Ain Biya","wilaya_id":"31", "ar_name":"عين البية", "longitude":"35.751", "latitude":"-0.2829"},
{"id":"1136","post_code":"32001","name":"El Bayadh","wilaya_id":"32", "ar_name":"الـبـيـض", "longitude":"33.6146", "latitude":"1.019"},
{"id":"1137","post_code":"32002","name":"Rogassa","wilaya_id":"32", "ar_name":"روقاصة", "longitude":"33.9525", "latitude":"0.9256"},
{"id":"1138","post_code":"32003","name":"Stitten","wilaya_id":"32", "ar_name":"ستيتين", "longitude":"33.6924", "latitude":"1.2222"},
{"id":"1139","post_code":"32004","name":"Brezina","wilaya_id":"32", "ar_name":"بريزينة", "longitude":"33.0317", "latitude":"1.2607"},
{"id":"1140","post_code":"32005","name":"Ghassoul","wilaya_id":"32", "ar_name":"غسول", "longitude":"33.3099", "latitude":"1.203"},
{"id":"1141","post_code":"32006","name":"Boualem","wilaya_id":"32", "ar_name":"بوعلام", "longitude":"33.6626", "latitude":"1.5353"},
{"id":"1142","post_code":"32007","name":"El Abiodh Sidi Cheikh","wilaya_id":"32", "ar_name":"الابيض سيدي الشيخ", "longitude":"32.8311", "latitude":"0.5383"},
{"id":"1143","post_code":"32008","name":"Ain El Orak","wilaya_id":"32", "ar_name":"عين العراك", "longitude":"33.3443", "latitude":"0.7388"},
{"id":"1144","post_code":"32009","name":"Arbaouat","wilaya_id":"32", "ar_name":"أربوات", "longitude":"33.0225", "latitude":"0.5823"},
{"id":"1145","post_code":"32010","name":"Bougtoub","wilaya_id":"32", "ar_name":"بوقطب", "longitude":"33.9775", "latitude":"0.0879"},
{"id":"1146","post_code":"32011","name":"El Kheither","wilaya_id":"32", "ar_name":"الخيثر", "longitude":"34.0777", "latitude":"0.0742"},
{"id":"1147","post_code":"32012","name":"Kef El Ahmar","wilaya_id":"32", "ar_name":"الكاف الاحمر", "longitude":"33.7837", "latitude":"0.6317"},
{"id":"1148","post_code":"32013","name":"Boussemghoun","wilaya_id":"32", "ar_name":"بوسمغون", "longitude":"32.7965", "latitude":"0.0192"},
{"id":"1149","post_code":"32014","name":"Chellala","wilaya_id":"32", "ar_name":"شلالة", "longitude":"32.9672", "latitude":"0.0549"},
{"id":"1150","post_code":"32015","name":"Krakda","wilaya_id":"32", "ar_name":"كراكدة", "longitude":"33.2525", "latitude":"0.9586"},
{"id":"1151","post_code":"32016","name":"El Bnoud","wilaya_id":"32", "ar_name":"البنود", "longitude":"32.2453", "latitude":"0.2444"},
{"id":"1152","post_code":"32017","name":"Cheguig","wilaya_id":"32", "ar_name":"الشقيق", "longitude":"34.1027", "latitude":"1.2277"},
{"id":"1153","post_code":"32018","name":"Sidi Ameur","wilaya_id":"32", "ar_name":"سيدي عامر", "longitude":"33.7015", "latitude":"1.4337"},
{"id":"1154","post_code":"32019","name":"El Mehara","wilaya_id":"32", "ar_name":"المھارة", "longitude":"33.2456", "latitude":"0.3653"},
{"id":"1155","post_code":"32020","name":"Tousmouline","wilaya_id":"32", "ar_name":"توسمولين", "longitude":"33.5849", "latitude":"0.3076"},
{"id":"1156","post_code":"32021","name":"Sidi Slimane","wilaya_id":"32", "ar_name":"سيدي سليمان", "longitude":"33.7814", "latitude":"1.7303"},
{"id":"1157","post_code":"32022","name":"Sidi Tifour","wilaya_id":"32", "ar_name":"سيدي طيفور", "longitude":"33.6512", "latitude":"1.6837"},
{"id":"1158","post_code":"33001","name":"Illizi","wilaya_id":"33", "ar_name":"إلـيـزي", "longitude":"26.4361", "latitude":"8.4842"},
{"id":"1159","post_code":"33002","name":"Djanet","wilaya_id":"33", "ar_name":"جانت", "longitude":"24.4821", "latitude":"9.4839"},
{"id":"1160","post_code":"33003","name":"Debdeb","wilaya_id":"33", "ar_name":"دبداب", "longitude":"29.8978", "latitude":"9.4208"},
{"id":"1161","post_code":"33004","name":"Bordj Omar Driss","wilaya_id":"33", "ar_name":"برج عمر ادريس", "longitude":"28.0793", "latitude":"6.817"},
{"id":"1162","post_code":"33005","name":"Bordj El Haouasse","wilaya_id":"33", "ar_name":"برج الحواس", "longitude":"24.5146", "latitude":"9.451"},
{"id":"1163","post_code":"33006","name":"In Amenas","wilaya_id":"33", "ar_name":"إن أميناس", "longitude":"27.9677", "latitude":"9.5663"},
{"id":"1164","post_code":"34001","name":"Bordj Bou Arreridj","wilaya_id":"34", "ar_name":"برج بوعريريج", "longitude":"36.0025", "latitude":"4.7653"},
{"id":"1165","post_code":"34002","name":"Ras El Oued","wilaya_id":"34", "ar_name":"رأس الوادي", "longitude":"35.8846", "latitude":"5.0372"},
{"id":"1166","post_code":"34003","name":"Bordj Zemoura","wilaya_id":"34", "ar_name":"برج زمورة", "longitude":"36.2288", "latitude":"4.856"},
{"id":"1167","post_code":"34004","name":"Mansoura","wilaya_id":"34", "ar_name":"منصورة", "longitude":"36.018", "latitude":"4.4604"},
{"id":"1168","post_code":"34005","name":"El Mhir","wilaya_id":"34", "ar_name":"المھير", "longitude":"36.0535", "latitude":"4.3835"},
{"id":"1169","post_code":"34006","name":"Ben Daoud","wilaya_id":"34", "ar_name":"بن داود", "longitude":"36.0757", "latitude":"4.1721"},
{"id":"1170","post_code":"34007","name":"El Achir","wilaya_id":"34", "ar_name":"العشير", "longitude":"36.0025", "latitude":"4.6335"},
{"id":"1171","post_code":"34008","name":"Ain Taghrout","wilaya_id":"34", "ar_name":"عين تاغروت", "longitude":"36.0646", "latitude":"5.0757"},
{"id":"1172","post_code":"34009","name":"Bordj Ghdir","wilaya_id":"34", "ar_name":"برج غدير", "longitude":"35.8356", "latitude":"4.8889"},
{"id":"1173","post_code":"34010","name":"Sidi Embarek","wilaya_id":"34", "ar_name":"سيدي مبارك", "longitude":"36.0402", "latitude":"4.9109"},
{"id":"1174","post_code":"34011","name":"El Hamadia","wilaya_id":"34", "ar_name":"الحمادية", "longitude":"35.9157", "latitude":"4.7461"},
{"id":"1175","post_code":"34012","name":"Belimour","wilaya_id":"34", "ar_name":"بليمور", "longitude":"35.9157", "latitude":"4.8807"},
{"id":"1176","post_code":"34013","name":"Medjana","wilaya_id":"34", "ar_name":"مجانة", "longitude":"36.0669", "latitude":"4.6692"},
{"id":"1177","post_code":"34014","name":"Teniet En Nasr","wilaya_id":"34", "ar_name":"ثنية النصر", "longitude":"36.1667", "latitude":"4.6005"},
{"id":"1178","post_code":"34015","name":"Djaafra","wilaya_id":"34", "ar_name":"جعافرة", "longitude":"36.2288", "latitude":"4.6637"},
{"id":"1179","post_code":"34016","name":"El Main","wilaya_id":"34", "ar_name":"إلماين", "longitude":"36.3018", "latitude":"4.7488"},
{"id":"1180","post_code":"34017","name":"Ouled Brahem","wilaya_id":"34", "ar_name":"أولاد ابراھم", "longitude":"35.8067", "latitude":"5.0757"},
{"id":"1181","post_code":"34018","name":"Ouled Dahmane","wilaya_id":"34", "ar_name":"أولاد دحمان", "longitude":"36.3018", "latitude":"3.4662"},
{"id":"1182","post_code":"34019","name":"Hasnaoua","wilaya_id":"34", "ar_name":"حسناوة", "longitude":"36.0891", "latitude":"4.7955"},
{"id":"1183","post_code":"34020","name":"Khelil","wilaya_id":"34", "ar_name":"خليل", "longitude":"36.1113", "latitude":"5.029"},
{"id":"1184","post_code":"34021","name":"Taglait","wilaya_id":"34", "ar_name":"تاقلعيت", "longitude":"35.7064", "latitude":"4.9988"},
{"id":"1185","post_code":"34022","name":"Ksour","wilaya_id":"34", "ar_name":"القصور", "longitude":"35.9269", "latitude":"4.5978"},
{"id":"1186","post_code":"34023","name":"Ouled Sidi Brahim","wilaya_id":"34", "ar_name":"آث سيذى پراهم.", "longitude":"36.1645", "latitude":"4.3341"},
{"id":"1187","post_code":"34024","name":"Tafreg","wilaya_id":"34", "ar_name":"تفرڨ", "longitude":"36.2531", "latitude":"4.7324"},
{"id":"1188","post_code":"34025","name":"Colla","wilaya_id":"34", "ar_name":"القلة", "longitude":"36.1977", "latitude":"4.6609"},
{"id":"1189","post_code":"34026","name":"Tixter","wilaya_id":"34", "ar_name":"تقصطر", "longitude":"35.9825", "latitude":"5.0812"},
{"id":"1190","post_code":"34027","name":"El Ach","wilaya_id":"34", "ar_name":"العش", "longitude":"35.8846", "latitude":"4.6829"},
{"id":"1191","post_code":"34028","name":"El Anseur","wilaya_id":"34", "ar_name":"العناصر", "longitude":"36.1357", "latitude":"4.4907"},
{"id":"1192","post_code":"34029","name":"Tesmart","wilaya_id":"34", "ar_name":"تسمارت", "longitude":"36.2044", "latitude":"4.823"},
{"id":"1193","post_code":"34030","name":"Ain Tesra","wilaya_id":"34", "ar_name":"عين تسرة", "longitude":"35.9713", "latitude":"5.0015"},
{"id":"1194","post_code":"34031","name":"Bir Kasdali","wilaya_id":"34", "ar_name":"بئر قصد علي", "longitude":"36.0846", "latitude":"5.0345"},
{"id":"1195","post_code":"34032","name":"Ghilassa","wilaya_id":"34", "ar_name":"غيلاسة", "longitude":"35.8067", "latitude":"4.9054"},
{"id":"1196","post_code":"34033","name":"Rabta","wilaya_id":"34", "ar_name":"الرابطة", "longitude":"35.8579", "latitude":"4.7488"},
{"id":"1197","post_code":"34034","name":"Haraza","wilaya_id":"34", "ar_name":"الحرازة", "longitude":"36.0913", "latitude":"4.2242"},
{"id":"1198","post_code":"35001","name":"Boumerdes","wilaya_id":"35", "ar_name":"بومرداس", "longitude":"36.7048", "latitude":"3.4662"},
{"id":"1199","post_code":"35002","name":"Boudouaou","wilaya_id":"35", "ar_name":"بودواو", "longitude":"36.6695", "latitude":"3.4058"},
{"id":"1200","post_code":"35004","name":"Afir","wilaya_id":"35", "ar_name":"أفير", "longitude":"36.7048", "latitude":"3.7024"},
{"id":"1201","post_code":"35005","name":"Bordj Menaiel","wilaya_id":"35", "ar_name":"برج منايل", "longitude":"36.6783", "latitude":"3.7244"},
{"id":"1202","post_code":"35006","name":"Baghlia","wilaya_id":"35", "ar_name":"بغلية", "longitude":"36.7532", "latitude":"3.8617"},
{"id":"1203","post_code":"35007","name":"Sidi Daoud","wilaya_id":"35", "ar_name":"سيدي داود", "longitude":"36.7884", "latitude":"3.8507"},
{"id":"1204","post_code":"35008","name":"Naciria","wilaya_id":"35", "ar_name":"الناصرية", "longitude":"36.6849", "latitude":"3.8287"},
{"id":"1205","post_code":"35009","name":"Djinet","wilaya_id":"35", "ar_name":"جنات", "longitude":"36.8148", "latitude":"3.7244"},
{"id":"1206","post_code":"35010","name":"Isser","wilaya_id":"35", "ar_name":"يسر", "longitude":"36.6607", "latitude":"3.6667"},
{"id":"1207","post_code":"35011","name":"Zemmouri","wilaya_id":"35", "ar_name":"زموري", "longitude":"36.7202", "latitude":"3.6008"},
{"id":"1208","post_code":"35012","name":"Si Mustapha","wilaya_id":"35", "ar_name":"سي مصطفى", "longitude":"36.6629", "latitude":"3.6145"},
{"id":"1209","post_code":"35013","name":"Tidjelabine","wilaya_id":"35", "ar_name":"تيجلابين", "longitude":"36.6651", "latitude":"3.4964"},
{"id":"1210","post_code":"35014","name":"Chabet El Ameur","wilaya_id":"35", "ar_name":"شعبة العامر", "longitude":"36.5703", "latitude":"3.6996"},
{"id":"1211","post_code":"35015","name":"Thenia","wilaya_id":"35", "ar_name":"الثنية", "longitude":"36.6651", "latitude":"3.5541"},
{"id":"1212","post_code":"35018","name":"Timezrit","wilaya_id":"35", "ar_name":"تمزريت", "longitude":"36.61", "latitude":"3.8068"},
{"id":"1213","post_code":"35019","name":"Corso","wilaya_id":"35", "ar_name":"قورصو", "longitude":"36.6937", "latitude":"3.4332"},
{"id":"1214","post_code":"35020","name":"Ouled Moussa","wilaya_id":"35", "ar_name":"أولاد موسى", "longitude":"36.621", "latitude":"3.3673"},
{"id":"1215","post_code":"35021","name":"Larbatache","wilaya_id":"35", "ar_name":"الأربعطاش", "longitude":"36.5725", "latitude":"3.3728"},
{"id":"1216","post_code":"35022","name":"Bouzegza Keddara","wilaya_id":"35", "ar_name":"بوزقزة قدارة", "longitude":"36.5615", "latitude":"3.4799"},
{"id":"1217","post_code":"35025","name":"Taourga","wilaya_id":"35", "ar_name":"تورقة", "longitude":"36.7312", "latitude":"3.9468"},
{"id":"1218","post_code":"35026","name":"Ouled Aissa","wilaya_id":"35", "ar_name":"أولاد عيسى", "longitude":"36.7444", "latitude":"3.815"},
{"id":"1219","post_code":"35027","name":"Ben Choud","wilaya_id":"35", "ar_name":"بن شود", "longitude":"36.7994", "latitude":"3.8809"},
{"id":"1220","post_code":"35028","name":"Dellys","wilaya_id":"35", "ar_name":"دلس", "longitude":"36.85", "latitude":"3.9139"},
{"id":"1221","post_code":"35029","name":"Ammal","wilaya_id":"35", "ar_name":"عمال", "longitude":"36.5725", "latitude":"3.5898"},
{"id":"1222","post_code":"35030","name":"Beni Amrane","wilaya_id":"35", "ar_name":"بنى عمران", "longitude":"36.6056", "latitude":"3.5925"},
{"id":"1223","post_code":"35031","name":"Souk El Had","wilaya_id":"35", "ar_name":"سوق الحد", "longitude":"36.6277", "latitude":"3.5898"},
{"id":"1224","post_code":"35032","name":"Boudouaou El Bahri","wilaya_id":"35", "ar_name":"بودواو البحرى", "longitude":"36.7114", "latitude":"3.3865"},
{"id":"1225","post_code":"35033","name":"Ouled Hedadj","wilaya_id":"35", "ar_name":"أولاد ھداج", "longitude":"36.6563", "latitude":"3.3508"},
{"id":"1226","post_code":"35035","name":"Laghata","wilaya_id":"35", "ar_name":"لقاطة", "longitude":"36.6871", "latitude":"3.6832"},
{"id":"1227","post_code":"35036","name":"Hammedi","wilaya_id":"35", "ar_name":"حمادى", "longitude":"36.6144", "latitude":"3.2684"},
{"id":"1228","post_code":"35037","name":"Khemis El Khechna","wilaya_id":"35", "ar_name":"خميس الخشنة", "longitude":"36.5858", "latitude":"3.3289"},
{"id":"1229","post_code":"35038","name":"El Kharrouba","wilaya_id":"35", "ar_name":"الخروبة", "longitude":"36.5946", "latitude":"3.4058"},
{"id":"1230","post_code":"36001","name":"El Tarf","wilaya_id":"36", "ar_name":"الطارف", "longitude":"36.7048", "latitude":"8.3139"},
{"id":"1231","post_code":"36002","name":"Bouhadjar","wilaya_id":"36", "ar_name":"بوحجار", "longitude":"36.4401", "latitude":"8.1052"},
{"id":"1232","post_code":"36003","name":"Ben Mhidi","wilaya_id":"36", "ar_name":"بن مهيدى", "longitude":"36.7092", "latitude":"7.9047"},
{"id":"1233","post_code":"36004","name":"Bougous","wilaya_id":"36", "ar_name":"بوقوس", "longitude":"36.5968", "latitude":"8.3688"},
{"id":"1234","post_code":"36005","name":"El Kala","wilaya_id":"36", "ar_name":"القالة", "longitude":"36.8324", "latitude":"8.443"},
{"id":"1235","post_code":"36006","name":"Ain El Assel","wilaya_id":"36", "ar_name":"عين العسل", "longitude":"36.7246", "latitude":"8.3826"},
{"id":"1236","post_code":"36007","name":"El Aioun","wilaya_id":"36", "ar_name":"العيون", "longitude":"36.8082", "latitude":"8.5968"},
{"id":"1237","post_code":"36008","name":"Bouteldja","wilaya_id":"36", "ar_name":"بوثلجة", "longitude":"36.4401", "latitude":"8.1052"},
{"id":"1238","post_code":"36009","name":"Souarekh","wilaya_id":"36", "ar_name":"السوارخ", "longitude":"36.8192", "latitude":"8.5638"},
{"id":"1239","post_code":"36010","name":"Berrihane","wilaya_id":"36", "ar_name":"برحان", "longitude":"36.7752", "latitude":"8.1244"},
{"id":"1240","post_code":"36011","name":"Lac Des Oiseaux","wilaya_id":"36", "ar_name":"بحيرة الطيور", "longitude":"36.7136", "latitude":"8.1189"},
{"id":"1241","post_code":"36012","name":"Chefia","wilaya_id":"36", "ar_name":"الشافية", "longitude":"36.5483", "latitude":"8.0392"},
{"id":"1242","post_code":"36013","name":"Drean","wilaya_id":"36", "ar_name":"الذرعان", "longitude":"36.621", "latitude":"7.7509"},
{"id":"1243","post_code":"36014","name":"Chihani","wilaya_id":"36", "ar_name":"شهانى", "longitude":"36.5836", "latitude":"7.7756"},
{"id":"1244","post_code":"36015","name":"Chebaita Mokhtar","wilaya_id":"36", "ar_name":"شبيطة مختار", "longitude":"36.6937", "latitude":"7.7426"},
{"id":"1245","post_code":"36016","name":"Besbes","wilaya_id":"36", "ar_name":"البسباس", "longitude":"36.6387", "latitude":"7.847"},
{"id":"1246","post_code":"36017","name":"Asfour","wilaya_id":"36", "ar_name":"عصفور", "longitude":"36.6122", "latitude":"7.9761"},
{"id":"1247","post_code":"36018","name":"Echatt","wilaya_id":"36", "ar_name":"الشط", "longitude":"36.7664", "latitude":"7.8745"},
{"id":"1248","post_code":"36019","name":"Zerizer","wilaya_id":"36", "ar_name":"زريزر", "longitude":"36.6651", "latitude":"7.8937"},
{"id":"1249","post_code":"36020","name":"Zitouna","wilaya_id":"36", "ar_name":"الزيتونة", "longitude":"36.6056", "latitude":"8.2343"},
{"id":"1250","post_code":"36021","name":"Ain Kerma","wilaya_id":"36", "ar_name":"عين الكرمة", "longitude":"36.5306", "latitude":"8.2013"},
{"id":"1251","post_code":"36022","name":"Oued Zitoun","wilaya_id":"36", "ar_name":"وادى الزيتون", "longitude":"36.4025", "latitude":"8.0585"},
{"id":"1252","post_code":"36023","name":"Hammam Beni Salah","wilaya_id":"36", "ar_name":"حمام بنى صالح", "longitude":"36.4577", "latitude":"8.0338"},
{"id":"1253","post_code":"36024","name":"Raml Souk","wilaya_id":"36", "ar_name":"رمل سوق", "longitude":"36.7224", "latitude":"8.5364"},
{"id":"1254","post_code":"37001","name":"Tindouf","wilaya_id":"37", "ar_name":"تندوف", "longitude":"27.5972", "latitude":"-8.1491"},
{"id":"1255","post_code":"37002","name":"Oum El Assel","wilaya_id":"37", "ar_name":"أم العسل", "longitude":"28.2983", "latitude":"-6.5341"},
{"id":"1256","post_code":"38001","name":"Tissemsilt","wilaya_id":"38", "ar_name":"تـيـسـمـسـيـلـت", "longitude":"35.5445", "latitude":"1.8127"},
{"id":"1257","post_code":"38002","name":"Bordj Bou Naama","wilaya_id":"38", "ar_name":"برج بونعامة", "longitude":"35.7855", "latitude":"1.6177"},
{"id":"1258","post_code":"38003","name":"Theniet El Had","wilaya_id":"38", "ar_name":"ثنية الاحد", "longitude":"35.8078", "latitude":"2.0297"},
{"id":"1259","post_code":"38004","name":"Lazharia","wilaya_id":"38", "ar_name":"الازھرية", "longitude":"35.8746", "latitude":"1.5601"},
{"id":"1260","post_code":"38005","name":"Beni Chaib","wilaya_id":"38", "ar_name":"بنى شعيب", "longitude":"35.7565", "latitude":"1.799"},
{"id":"1261","post_code":"38006","name":"Lardjem","wilaya_id":"38", "ar_name":"لارجم", "longitude":"35.6852", "latitude":"1.5491"},
{"id":"1262","post_code":"38007","name":"Melaab","wilaya_id":"38", "ar_name":"ملعب", "longitude":"35.6517", "latitude":"1.3321"},
{"id":"1263","post_code":"38008","name":"Sidi Lantri","wilaya_id":"38", "ar_name":"سيدي العنترى", "longitude":"35.6383", "latitude":"1.4035"},
{"id":"1264","post_code":"38009","name":"Bordj El Emir Abdelkader","wilaya_id":"38", "ar_name":"برج الامير عبد القادر", "longitude":"35.8523", "latitude":"2.2714"},
{"id":"1265","post_code":"38010","name":"Layoune","wilaya_id":"38", "ar_name":"العيون", "longitude":"35.6316", "latitude":"1.9995"},
{"id":"1266","post_code":"38011","name":"Khemisti","wilaya_id":"38", "ar_name":"خميستى", "longitude":"35.6026", "latitude":"1.9501"},
{"id":"1267","post_code":"38012","name":"Ouled Bessem","wilaya_id":"38", "ar_name":"أولاد بسام", "longitude":"35.6227", "latitude":"1.8649"},
{"id":"1268","post_code":"38013","name":"Ammari","wilaya_id":"38", "ar_name":"عمارى", "longitude":"35.5132", "latitude":"1.6617"},
{"id":"1269","post_code":"38014","name":"Youssoufia","wilaya_id":"38", "ar_name":"اليوسفية", "longitude":"35.8857", "latitude":"2.1121"},
{"id":"1270","post_code":"38015","name":"Sidi Boutouchent","wilaya_id":"38", "ar_name":"سيدي بوتوشنت", "longitude":"35.761", "latitude":"1.9501"},
{"id":"1271","post_code":"38016","name":"Larbaa","wilaya_id":"38", "ar_name":"الاربعاء", "longitude":"35.8501", "latitude":"1.4749"},
{"id":"1272","post_code":"38017","name":"Maasem","wilaya_id":"38", "ar_name":"المعاصم", "longitude":"35.5959", "latitude":"1.5546"},
{"id":"1273","post_code":"38018","name":"Sidi Abed","wilaya_id":"38", "ar_name":"سيدي عابد", "longitude":"35.6807", "latitude":"1.7056"},
{"id":"1274","post_code":"38019","name":"Tamalaht","wilaya_id":"38", "ar_name":"تاملاحت", "longitude":"35.7499", "latitude":"1.6232"},
{"id":"1275","post_code":"38020","name":"Sidi Slimane","wilaya_id":"38", "ar_name":"سيدي سليمان", "longitude":"35.7967", "latitude":"1.6809"},
{"id":"1276","post_code":"38021","name":"Boucaid","wilaya_id":"38", "ar_name":"بوقايد", "longitude":"35.8278", "latitude":"1.6205"},
{"id":"1277","post_code":"38022","name":"Beni Lahcene","wilaya_id":"38", "ar_name":"بنى لحسن", "longitude":"35.7499", "latitude":"1.6891"},
{"id":"1278","post_code":"39001","name":"El Oued","wilaya_id":"39", "ar_name":"الوادي", "longitude":"33.3018", "latitude":"6.8665"},
{"id":"1279","post_code":"39002","name":"Robbah","wilaya_id":"39", "ar_name":"رباح", "longitude":"33.2146", "latitude":"6.9104"},
{"id":"1280","post_code":"39003","name":"Oued El Alenda","wilaya_id":"39", "ar_name":"وادى العلندة", "longitude":"33.164", "latitude":"6.7566"},
{"id":"1281","post_code":"39004","name":"Bayadha","wilaya_id":"39", "ar_name":"البياضة", "longitude":"33.2697", "latitude":"6.9159"},
{"id":"1282","post_code":"39005","name":"Nakhla","wilaya_id":"39", "ar_name":"النخلة", "longitude":"33.2123", "latitude":"6.9516"},
{"id":"1283","post_code":"39006","name":"Guemar","wilaya_id":"39", "ar_name":"ڨمار", "longitude":"33.428", "latitude":"6.7978"},
{"id":"1284","post_code":"39007","name":"Kouinine","wilaya_id":"39", "ar_name":"كوينين", "longitude":"33.3386", "latitude":"6.8253"},
{"id":"1285","post_code":"39008","name":"Reguiba","wilaya_id":"39", "ar_name":"الرڨيبة", "longitude":"33.499", "latitude":"6.7126"},
{"id":"1286","post_code":"39009","name":"Hamraia","wilaya_id":"39", "ar_name":"الحمراية", "longitude":"34.047", "latitude":"6.2292"},
{"id":"1287","post_code":"39010","name":"Taghzout","wilaya_id":"39", "ar_name":"تغزوت", "longitude":"33.4097", "latitude":"6.795"},
{"id":"1288","post_code":"39011","name":"Debila","wilaya_id":"39", "ar_name":"الدبيلة", "longitude":"33.4418", "latitude":"6.9379"},
{"id":"1289","post_code":"39012","name":"Hassani Abdelkrim","wilaya_id":"39", "ar_name":"بلدية حساني عبد الكريم", "longitude":"33.412", "latitude":"6.8939"},
{"id":"1290","post_code":"39013","name":"Hassi Khelifa","wilaya_id":"39", "ar_name":"حاسى خليفة", "longitude":"33.5357", "latitude":"7.0285"},
{"id":"1291","post_code":"39014","name":"Taleb Larbi","wilaya_id":"39", "ar_name":"طالب العربي", "longitude":"33.6615", "latitude":"7.5174"},
{"id":"1292","post_code":"39015","name":"Douar El Ma","wilaya_id":"39", "ar_name":"دوار الماء", "longitude":"33.1962", "latitude":"7.3746"},
{"id":"1293","post_code":"39016","name":"Sidi Aoun","wilaya_id":"39", "ar_name":"سيدي عون", "longitude":"33.4761", "latitude":"6.9022"},
{"id":"1294","post_code":"39017","name":"Trifaoui","wilaya_id":"39", "ar_name":"تريفاوى", "longitude":"33.3569", "latitude":"6.9351"},
{"id":"1295","post_code":"39018","name":"Magrane","wilaya_id":"39", "ar_name":"المڨرن", "longitude":"33.5174", "latitude":"6.9489"},
{"id":"1296","post_code":"39019","name":"Beni Guecha","wilaya_id":"39", "ar_name":"بن ڨشة", "longitude":"33.9331", "latitude":"7.3361"},
{"id":"1297","post_code":"39020","name":"Ourmas","wilaya_id":"39", "ar_name":"أورماس", "longitude":"33.3477", "latitude":"6.7813"},
{"id":"1298","post_code":"39021","name":"Still","wilaya_id":"39", "ar_name":"سطيل", "longitude":"34.1948", "latitude":"5.9134"},
{"id":"1299","post_code":"39022","name":"Mrara","wilaya_id":"39", "ar_name":"مرارة", "longitude":"33.5883", "latitude":"5.2872"},
{"id":"1300","post_code":"39023","name":"Sidi Khellil","wilaya_id":"39", "ar_name":"سيدي خليل", "longitude":"33.7734", "latitude":"5.9601"},
{"id":"1301","post_code":"39024","name":"Tendla","wilaya_id":"39", "ar_name":"تندلة", "longitude":"33.6112", "latitude":"6.0342"},
{"id":"1302","post_code":"39025","name":"El Ogla","wilaya_id":"39", "ar_name":"العقلة", "longitude":"33.1801", "latitude":"6.9461"},
{"id":"1303","post_code":"39026","name":"Mih Ouansa","wilaya_id":"39", "ar_name":"مية ونسة", "longitude":"33.1318", "latitude":"6.7044"},
{"id":"1304","post_code":"39027","name":"El Mghair","wilaya_id":"39", "ar_name":"المغير", "longitude":"33.8852", "latitude":"5.9244"},
{"id":"1305","post_code":"39028","name":"Djamaa","wilaya_id":"39", "ar_name":"جامعة", "longitude":"33.467", "latitude":"5.9903"},
{"id":"1306","post_code":"39029","name":"Oum Touyour","wilaya_id":"39", "ar_name":"أم الطيور", "longitude":"34.0879", "latitude":"5.8337"},
{"id":"1307","post_code":"39030","name":"Sidi Amrane","wilaya_id":"39", "ar_name":"سيدي عمران", "longitude":"33.4303", "latitude":"6.0123"},
{"id":"1308","post_code":"40001","name":"Khenchela","wilaya_id":"40", "ar_name":"خنشلة", "longitude":"35.3678", "latitude":"7.1439"},
{"id":"1309","post_code":"40002","name":"Mtoussa","wilaya_id":"40", "ar_name":"متوسة", "longitude":"35.5356", "latitude":"7.2455"},
{"id":"1310","post_code":"40003","name":"Kais","wilaya_id":"40", "ar_name":"قايس", "longitude":"35.4305", "latitude":"6.9241"},
{"id":"1311","post_code":"40004","name":"Baghai","wilaya_id":"40", "ar_name":"بغاي", "longitude":"35.4573", "latitude":"7.1136"},
{"id":"1312","post_code":"40005","name":"El Hamma","wilaya_id":"40", "ar_name":"الحامة", "longitude":"35.3991", "latitude":"7.0834"},
{"id":"1313","post_code":"40006","name":"Ain Touila","wilaya_id":"40", "ar_name":"عين الطويلة", "longitude":"35.3723", "latitude":"7.3526"},
{"id":"1314","post_code":"40007","name":"Taouzianat","wilaya_id":"40", "ar_name":"تاوزيانت", "longitude":"35.4506", "latitude":"6.7538"},
{"id":"1315","post_code":"40008","name":"Bouhmama","wilaya_id":"40", "ar_name":"بوحمامة", "longitude":"35.2557", "latitude":"6.7456"},
{"id":"1316","post_code":"40009","name":"El Oueldja","wilaya_id":"40", "ar_name":"الولجة", "longitude":"34.851", "latitude":"6.6797"},
{"id":"1317","post_code":"40010","name":"Remila","wilaya_id":"40", "ar_name":"الرميلة", "longitude":"35.5065", "latitude":"6.9351"},
{"id":"1318","post_code":"40011","name":"Cherchar","wilaya_id":"40", "ar_name":"ششار", "longitude":"34.9749", "latitude":"7.0038"},
{"id":"1319","post_code":"40012","name":"Djellal","wilaya_id":"40", "ar_name":"جلال", "longitude":"34.8533", "latitude":"6.8994"},
{"id":"1320","post_code":"40013","name":"Babar","wilaya_id":"40", "ar_name":"بابار", "longitude":"35.1053", "latitude":"7.0999"},
{"id":"1321","post_code":"40014","name":"Tamza","wilaya_id":"40", "ar_name":"تامزة", "longitude":"35.2535", "latitude":"6.8335"},
{"id":"1322","post_code":"40015","name":"Ensigha","wilaya_id":"40", "ar_name":"انسيغة", "longitude":"35.3342", "latitude":"7.1439"},
{"id":"1323","post_code":"40016","name":"Ouled Rechache","wilaya_id":"40", "ar_name":"أولاد رشاش", "longitude":"35.2333", "latitude":"7.3526"},
{"id":"1324","post_code":"40017","name":"El Mahmal","wilaya_id":"40", "ar_name":"المحمل", "longitude":"35.3095", "latitude":"7.2125"},
{"id":"1325","post_code":"40018","name":"Msara","wilaya_id":"40", "ar_name":"أمصارة", "longitude":"35.1749", "latitude":"6.5726"},
{"id":"1326","post_code":"40019","name":"Yabous","wilaya_id":"40", "ar_name":"يابوس", "longitude":"35.3454", "latitude":"6.6412"},
{"id":"1327","post_code":"40020","name":"Khirane","wilaya_id":"40", "ar_name":"خيران", "longitude":"34.9344", "latitude":"6.7593"},
{"id":"1328","post_code":"40021","name":"Chelia","wilaya_id":"40", "ar_name":"شلية", "longitude":"35.3006", "latitude":"6.7786"},
{"id":"1329","post_code":"41001","name":"Souk Ahras","wilaya_id":"41", "ar_name":"سوق أهراس", "longitude":"36.2232", "latitude":"7.9541"},
{"id":"1330","post_code":"41002","name":"Sedrata","wilaya_id":"41", "ar_name":"سدراتة", "longitude":"36.0658", "latitude":"7.5311"},
{"id":"1331","post_code":"41003","name":"Hanancha","wilaya_id":"41", "ar_name":"الحنانشة", "longitude":"36.1545", "latitude":"7.8333"},
{"id":"1332","post_code":"41004","name":"Mechroha","wilaya_id":"41", "ar_name":"المشروحة", "longitude":"36.2941", "latitude":"7.836"},
{"id":"1333","post_code":"41005","name":"Ouled Driss","wilaya_id":"41", "ar_name":"أولاد ادريس", "longitude":"36.2875", "latitude":"8.0173"},
{"id":"1334","post_code":"41006","name":"Tiffech","wilaya_id":"41", "ar_name":"تيفاش", "longitude":"36.0924", "latitude":"7.6987"},
{"id":"1335","post_code":"41007","name":"Zaarouria","wilaya_id":"41", "ar_name":"الزعرورية", "longitude":"36.1634", "latitude":"7.9568"},
{"id":"1336","post_code":"41008","name":"Taoura","wilaya_id":"41", "ar_name":"تاورة", "longitude":"36.1057", "latitude":"8.042"},
{"id":"1337","post_code":"41009","name":"Drea","wilaya_id":"41", "ar_name":"الدريعة", "longitude":"36.0524", "latitude":"7.8827"},
{"id":"1338","post_code":"41010","name":"Haddada","wilaya_id":"41", "ar_name":"الحدادة", "longitude":"36.1678", "latitude":"8.3386"},
{"id":"1339","post_code":"41011","name":"Khedara","wilaya_id":"41", "ar_name":"لخضارة", "longitude":"36.221", "latitude":"8.3167"},
{"id":"1340","post_code":"41012","name":"Merahna","wilaya_id":"41", "ar_name":"المراهنة", "longitude":"36.1345", "latitude":"8.1738"},
{"id":"1341","post_code":"41013","name":"Ouled Moumen","wilaya_id":"41", "ar_name":"أولاد مؤمن", "longitude":"36.3472", "latitude":"8.399"},
{"id":"1342","post_code":"41014","name":"Bir Bouhouche","wilaya_id":"41", "ar_name":"بئر بوحوش", "longitude":"35.9858", "latitude":"7.468"},
{"id":"1343","post_code":"41015","name":"Mdaourouche","wilaya_id":"41", "ar_name":"مداوروش", "longitude":"36.0124", "latitude":"7.8195"},
{"id":"1344","post_code":"41016","name":"Oum El Adhaim","wilaya_id":"41", "ar_name":"أم العظائم", "longitude":"35.9702", "latitude":"7.6025"},
{"id":"1345","post_code":"41017","name":"Ain Zana","wilaya_id":"41", "ar_name":"عين الزانة", "longitude":"36.3384", "latitude":"8.1903"},
{"id":"1346","post_code":"41018","name":"Ain Soltane","wilaya_id":"41", "ar_name":"عين السلطان", "longitude":"36.1146", "latitude":"7.3691"},
{"id":"1347","post_code":"41019","name":"Quillen","wilaya_id":"41", "ar_name":"ويلان", "longitude":"36.2653", "latitude":"8.1216"},
{"id":"1348","post_code":"41020","name":"Sidi Fredj","wilaya_id":"41", "ar_name":"سيدي فرج", "longitude":"36.0902", "latitude":"8.1958"},
{"id":"1349","post_code":"41021","name":"Safel El Ouiden","wilaya_id":"41", "ar_name":"سافل الويدان", "longitude":"35.9213", "latitude":"7.4625"},
{"id":"1350","post_code":"41022","name":"Ragouba","wilaya_id":"41", "ar_name":"الرقوبة", "longitude":"36.0191", "latitude":"7.7179"},
{"id":"1351","post_code":"41023","name":"Khemissa","wilaya_id":"41", "ar_name":"خميسة", "longitude":"36.1257", "latitude":"7.6602"},
{"id":"1352","post_code":"41024","name":"Oued Keberit","wilaya_id":"41", "ar_name":"وادى الكبريت", "longitude":"35.8701", "latitude":"7.9156"},
{"id":"1353","post_code":"41025","name":"Terraguelt","wilaya_id":"41", "ar_name":"ترقالت", "longitude":"35.839", "latitude":"7.5613"},
{"id":"1354","post_code":"41026","name":"Zouabi","wilaya_id":"41", "ar_name":"الزوابى", "longitude":"36.0524", "latitude":"7.4432"},
{"id":"1355","post_code":"42001","name":"Tipaza","wilaya_id":"42", "ar_name":"تيبازة", "longitude":"36.5284", "latitude":"2.45"},
{"id":"1356","post_code":"42002","name":"Menaceur","wilaya_id":"42", "ar_name":"مناصر", "longitude":"36.4312", "latitude":"2.2412"},
{"id":"1357","post_code":"42003","name":"Larhat","wilaya_id":"42", "ar_name":"الأرهاط", "longitude":"36.4953", "latitude":"1.8018"},
{"id":"1358","post_code":"42004","name":"Douaouda","wilaya_id":"42", "ar_name":"دواودة", "longitude":"36.6034", "latitude":"2.7823"},
{"id":"1359","post_code":"42005","name":"Bourkika","wilaya_id":"42", "ar_name":"بورقيقة", "longitude":"36.4312", "latitude":"2.4747"},
{"id":"1360","post_code":"42006","name":"Khemisti","wilaya_id":"42", "ar_name":"خميستي", "longitude":"36.5637", "latitude":"2.6807"},
{"id":"1361","post_code":"42010","name":"Aghabal","wilaya_id":"42", "ar_name":"أغابال", "longitude":"36.429", "latitude":"1.8402"},
{"id":"1362","post_code":"42012","name":"Hadjout","wilaya_id":"42", "ar_name":"حجوط", "longitude":"36.4489", "latitude":"2.4142"},
{"id":"1363","post_code":"42013","name":"Sidi Amar","wilaya_id":"42", "ar_name":"سيدي عمر", "longitude":"36.4798", "latitude":"2.3071"},
{"id":"1364","post_code":"42014","name":"Gouraya","wilaya_id":"42", "ar_name":"ڨورايا", "longitude":"36.5041", "latitude":"1.9061"},
{"id":"1365","post_code":"42015","name":"Nodor","wilaya_id":"42", "ar_name":"الناظور", "longitude":"36.5063", "latitude":"2.3923"},
{"id":"1366","post_code":"42016","name":"Chaiba","wilaya_id":"42", "ar_name":"الشعيبة", "longitude":"36.5615", "latitude":"2.7301"},
{"id":"1367","post_code":"42017","name":"Ain Tagourait","wilaya_id":"42", "ar_name":"عين تڨورايت", "longitude":"36.5416", "latitude":"2.6093"},
{"id":"1368","post_code":"42022","name":"Cherchel","wilaya_id":"42", "ar_name":"شرشال", "longitude":"36.5461", "latitude":"2.1973"},
{"id":"1369","post_code":"42023","name":"Damous","wilaya_id":"42", "ar_name":"الداموس", "longitude":"36.4865", "latitude":"1.7056"},
{"id":"1370","post_code":"42024","name":"Meurad","wilaya_id":"42", "ar_name":"مراد", "longitude":"36.4135", "latitude":"2.428"},
{"id":"1371","post_code":"42025","name":"Fouka","wilaya_id":"42", "ar_name":"فوكة", "longitude":"36.6034", "latitude":"2.7493"},
{"id":"1372","post_code":"42026","name":"Bou Ismail","wilaya_id":"42", "ar_name":"بو اسماعيل", "longitude":"36.5791", "latitude":"2.6889"},
{"id":"1373","post_code":"42027","name":"Ahmer El Ain","wilaya_id":"42", "ar_name":"أحمر العين", "longitude":"36.4158", "latitude":"2.5626"},
{"id":"1374","post_code":"42030","name":"Bou Haroun","wilaya_id":"42", "ar_name":"بوهارون", "longitude":"36.5615", "latitude":"2.6559"},
{"id":"1375","post_code":"42032","name":"Sidi Ghiles","wilaya_id":"42", "ar_name":"سيدي غيلاس", "longitude":"36.5196", "latitude":"2.1231"},
{"id":"1376","post_code":"42033","name":"Messelmoun","wilaya_id":"42", "ar_name":"مسلمون", "longitude":"36.4997", "latitude":"2.0023"},
{"id":"1377","post_code":"42034","name":"Sidi Rached","wilaya_id":"42", "ar_name":"سيدي راشد", "longitude":"36.4997", "latitude":"2.5323"},
{"id":"1378","post_code":"42035","name":"Kolea","wilaya_id":"42", "ar_name":"القليعة", "longitude":"36.5769", "latitude":"2.7658"},
{"id":"1379","post_code":"42036","name":"Attatba","wilaya_id":"42", "ar_name":"الحطاطبة", "longitude":"36.5107", "latitude":"2.6752"},
{"id":"1380","post_code":"42040","name":"Sidi Semiane","wilaya_id":"42", "ar_name":"سيدي سميان", "longitude":"36.4401", "latitude":"2.0984"},
{"id":"1381","post_code":"42041","name":"Beni Milleuk","wilaya_id":"42", "ar_name":"بني ميلك", "longitude":"36.3538", "latitude":"1.7496"},
{"id":"1382","post_code":"42042","name":"Hadjerat Ennous","wilaya_id":"42", "ar_name":"حجرة النص", "longitude":"36.5107", "latitude":"2.0517"},
 {"id":"1383","post_code":"43001","name":"Mila","wilaya_id":"43", "ar_name":"ميلة", "longitude":"36.387", "latitude":"6.2677"},
{"id":"1384","post_code":"43002","name":"Ferdjioua","wilaya_id":"43", "ar_name":"فرجيوة", "longitude":"36.345", "latitude":"5.9464"},
{"id":"1385","post_code":"43003","name":"Chelghoum Laid","wilaya_id":"43", "ar_name":"شلغوم العيد", "longitude":"36.1035", "latitude":"6.1661"},
{"id":"1386","post_code":"43004","name":"Oued Athmenia","wilaya_id":"43", "ar_name":"وادي العثمانية", "longitude":"36.1856", "latitude":"6.2869"},
{"id":"1387","post_code":"43005","name":"Ain Mellouk","wilaya_id":"43", "ar_name":"عين ملوك", "longitude":"36.2121", "latitude":"6.1771"},
{"id":"1388","post_code":"43006","name":"Telerghma","wilaya_id":"43", "ar_name":"تلاغمة", "longitude":"36.0524", "latitude":"6.3638"},
{"id":"1389","post_code":"43007","name":"Oued Seguen","wilaya_id":"43", "ar_name":"وادى سقان", "longitude":"36.1079", "latitude":"6.4188"},
{"id":"1390","post_code":"43008","name":"Tadjenanet","wilaya_id":"43", "ar_name":"تاجنانت", "longitude":"36.0524", "latitude":"6.0013"},
{"id":"1391","post_code":"43009","name":"Benyahia Abderrahmane","wilaya_id":"43", "ar_name":"بن يحيى عبد الرحمان", "longitude":"36.17", "latitude":"6.0123"},
{"id":"1392","post_code":"43010","name":"Oued Endja","wilaya_id":"43", "ar_name":"وادى النجاء", "longitude":"36.3671", "latitude":"6.1221"},
{"id":"1393","post_code":"43011","name":"Ahmed Rachedi","wilaya_id":"43", "ar_name":"أحمد راشدي", "longitude":"36.3273", "latitude":"6.1249"},
{"id":"1394","post_code":"43012","name":"Ouled Khalouf","wilaya_id":"43", "ar_name":"أولاد خلوف", "longitude":"35.9947", "latitude":"6.1386"},
{"id":"1395","post_code":"43013","name":"Tiberguent","wilaya_id":"43", "ar_name":"تيبرقنت", "longitude":"36.3472", "latitude":"6.0397"},
{"id":"1396","post_code":"43014","name":"Bouhatem","wilaya_id":"43", "ar_name":"بوحاتم", "longitude":"36.241", "latitude":"6.015"},
{"id":"1397","post_code":"43015","name":"Rouached","wilaya_id":"43", "ar_name":"رواشد", "longitude":"36.2121", "latitude":"6.2677"},
{"id":"1398","post_code":"43016","name":"Tessala Lamatai","wilaya_id":"43", "ar_name":"تسالة لمطاي", "longitude":"36.2786", "latitude":"5.5893"},
{"id":"1399","post_code":"43017","name":"Grarem Gouga","wilaya_id":"43", "ar_name":"القرارم قوقة", "longitude":"36.4533", "latitude":"6.3336"},
{"id":"1400","post_code":"43018","name":"Sidi Merouane","wilaya_id":"43", "ar_name":"سيدي مروان", "longitude":"36.4577", "latitude":"6.2622"},
{"id":"1401","post_code":"43019","name":"Tassadane Haddada","wilaya_id":"43", "ar_name":"تسدان حدادة", "longitude":"36.345", "latitude":"5.9464"},
{"id":"1402","post_code":"43020","name":"Derradji Bousselah","wilaya_id":"43", "ar_name":"دراحي بوصلاح", "longitude":"36.2764", "latitude":"5.9573"},
{"id":"1403","post_code":"43021","name":"Minar Zarza","wilaya_id":"43", "ar_name":"مينار زرزة", "longitude":"36.2542", "latitude":"5.5399"},
{"id":"1404","post_code":"43022","name":"Amira Arras","wilaya_id":"43", "ar_name":"عميرة أراس", "longitude":"36.2587", "latitude":"6.3968"},
{"id":"1405","post_code":"43023","name":"Terrai Bainen","wilaya_id":"43", "ar_name":"ترعى بينان", "longitude":"36.4688", "latitude":"6.1221"},
{"id":"1406","post_code":"43024","name":"Hamala","wilaya_id":"43", "ar_name":"حمالة", "longitude":"36.5085", "latitude":"6.3391"},
{"id":"1407","post_code":"43025","name":"Ain Tine","wilaya_id":"43", "ar_name":"عين التين", "longitude":"36.3339", "latitude":"6.3254"},
{"id":"1408","post_code":"43026","name":"El Mechira","wilaya_id":"43", "ar_name":"المشيرة", "longitude":"35.948", "latitude":"6.232"},
{"id":"1409","post_code":"43027","name":"Sidi Khelifa","wilaya_id":"43", "ar_name":"سيدي خليفة", "longitude":"36.2875", "latitude":"6.3007"},
{"id":"1410","post_code":"43028","name":"Zeghaia","wilaya_id":"43", "ar_name":"زغاية", "longitude":"36.4047", "latitude":"6.1716"},
{"id":"1411","post_code":"43029","name":"Elayadi Barbes","wilaya_id":"43", "ar_name":"العياضى برباس", "longitude":"36.3782", "latitude":"5.8804"},
{"id":"1412","post_code":"43030","name":"Ain Beida Harriche","wilaya_id":"43", "ar_name":"عين البيضاء حريش", "longitude":"36.3361", "latitude":"5.8997"},
{"id":"1413","post_code":"43031","name":"Yahia Beniguecha","wilaya_id":"43", "ar_name":"يحيى بنى قشة", "longitude":"36.1722", "latitude":"5.592"},
{"id":"1414","post_code":"43032","name":"Chigara","wilaya_id":"43", "ar_name":"الشيقارة", "longitude":"36.4975", "latitude":"6.221"},
{"id":"1415","post_code":"44001","name":"Ain Defla","wilaya_id":"44", "ar_name":"عين دفلة - عين الدفلى", "longitude":"36.2", "latitude":"1.9693"},
{"id":"1416","post_code":"44002","name":"Miliana","wilaya_id":"44", "ar_name":"مليانة", "longitude":"36.2266", "latitude":"2.211"},
{"id":"1417","post_code":"44003","name":"Boumedfaa","wilaya_id":"44", "ar_name":"بومدفع", "longitude":"36.3063", "latitude":"2.4774"},
{"id":"1418","post_code":"44004","name":"Khemis Miliana","wilaya_id":"44", "ar_name":"خميس مليانة", "longitude":"36.1955", "latitude":"2.2"},
{"id":"1419","post_code":"44005","name":"Hammam Righa","wilaya_id":"44", "ar_name":"حمام ريغة", "longitude":"36.3196", "latitude":"2.4005"},
{"id":"1420","post_code":"44006","name":"Arib","wilaya_id":"44", "ar_name":"عريب", "longitude":"36.2199", "latitude":"2.0654"},
{"id":"1421","post_code":"44007","name":"Djelida","wilaya_id":"44", "ar_name":"جليدة", "longitude":"36.1357", "latitude":"2.0847"},
{"id":"1422","post_code":"44008","name":"El Amra","wilaya_id":"44", "ar_name":"العامرة", "longitude":"36.2398", "latitude":"1.8457"},
{"id":"1423","post_code":"44009","name":"Bourached","wilaya_id":"44", "ar_name":"بوراشد", "longitude":"36.1046", "latitude":"1.9281"},
{"id":"1424","post_code":"44010","name":"El Attaf","wilaya_id":"44", "ar_name":"العطاف", "longitude":"36.1578", "latitude":"1.6699"},
{"id":"1425","post_code":"44011","name":"El Abadia","wilaya_id":"44", "ar_name":"العبادية", "longitude":"36.2044", "latitude":"1.6837"},
{"id":"1426","post_code":"44012","name":"Djendel","wilaya_id":"44", "ar_name":"جندل", "longitude":"36.1534", "latitude":"2.4088"},
{"id":"1427","post_code":"44013","name":"Oued Chorfa","wilaya_id":"44", "ar_name":"وادى الشرفاء", "longitude":"36.1357", "latitude":"2.5159"},
{"id":"1428","post_code":"44014","name":"Ain Lechiakh","wilaya_id":"44", "ar_name":"عين االشياخ", "longitude":"36.0935", "latitude":"2.4033"},
{"id":"1429","post_code":"44015","name":"Oued Djemaa","wilaya_id":"44", "ar_name":"وادى جمعة", "longitude":"35.7354", "latitude":"0.6839"},
{"id":"1430","post_code":"44016","name":"Rouina","wilaya_id":"44", "ar_name":"روينة", "longitude":"36.1867", "latitude":"1.8155"},
{"id":"1431","post_code":"44017","name":"Zeddine","wilaya_id":"44", "ar_name":"زدين", "longitude":"36.1024", "latitude":"1.8512"},
{"id":"1432","post_code":"44018","name":"El Hassania","wilaya_id":"44", "ar_name":"الحسنية", "longitude":"35.8824", "latitude":"1.9171"},
{"id":"1433","post_code":"44019","name":"Bir Ouled Khelifa","wilaya_id":"44", "ar_name":"بئر ولد خليفة", "longitude":"36.1179", "latitude":"2.233"},
{"id":"1434","post_code":"44020","name":"Ain Soltane","wilaya_id":"44", "ar_name":"عين السلطان", "longitude":"36.1822", "latitude":"2.2961"},
{"id":"1435","post_code":"44021","name":"Tarik Ibn Ziad","wilaya_id":"44", "ar_name":"طارق بن زياد", "longitude":"35.9358", "latitude":"2.1506"},
{"id":"1436","post_code":"44022","name":"Bordj Emir Khaled","wilaya_id":"44", "ar_name":"برج الأمير خالد", "longitude":"36.058", "latitude":"2.2055"},
{"id":"1437","post_code":"44023","name":"Ain Torki","wilaya_id":"44", "ar_name":"عين التركى", "longitude":"36.2686", "latitude":"2.3016"},
{"id":"1438","post_code":"44024","name":"Sidi Lakhdar","wilaya_id":"44", "ar_name":"سيدي لخضر", "longitude":"36.2022", "latitude":"2.1616"},
{"id":"1439","post_code":"44025","name":"Ben Allal","wilaya_id":"44", "ar_name":"بن علال", "longitude":"36.2465", "latitude":"2.1588"},
{"id":"1440","post_code":"44026","name":"Ain Benian","wilaya_id":"44", "ar_name":"عين البنيان", "longitude":"36.291", "latitude":"2.472"},
{"id":"1441","post_code":"44027","name":"Hoceinia","wilaya_id":"44", "ar_name":"حسينية", "longitude":"36.2531", "latitude":"2.4005"},
{"id":"1442","post_code":"44028","name":"Barbouche","wilaya_id":"44", "ar_name":"بربوش", "longitude":"36.0358", "latitude":"2.4829"},
{"id":"1443","post_code":"44029","name":"Djemaa Ouled Chikh","wilaya_id":"44", "ar_name":"جمعة أولاد الشيخ", "longitude":"36.0136", "latitude":"2.005"},
{"id":"1444","post_code":"44030","name":"Mekhatria","wilaya_id":"44", "ar_name":"المخاطرية", "longitude":"36.2376", "latitude":"1.9611"},
{"id":"1445","post_code":"44031","name":"Bathia","wilaya_id":"44", "ar_name":"بطحية", "longitude":"35.8512", "latitude":"1.8402"},
{"id":"1446","post_code":"44032","name":"Tachta Zegagha","wilaya_id":"44", "ar_name":"تاشتة زقاغة", "longitude":"36.2863", "latitude":"1.6507"},
{"id":"1447","post_code":"44033","name":"Ain Bouyahia","wilaya_id":"44", "ar_name":"عين بويحى", "longitude":"36.2199", "latitude":"1.7661"},
{"id":"1448","post_code":"44034","name":"El Maine","wilaya_id":"44", "ar_name":"الماين", "longitude":"35.9869", "latitude":"1.7578"},
{"id":"1449","post_code":"44035","name":"Tiberkanine","wilaya_id":"44", "ar_name":"تبركانين", "longitude":"36.1024", "latitude":"1.6342"},
{"id":"1450","post_code":"44036","name":"Belaas","wilaya_id":"44", "ar_name":"بالعاص", "longitude":"35.918", "latitude":"1.8512"},
{"id":"1451","post_code":"45001","name":"Naama","wilaya_id":"45", "ar_name":"النــعـامـة", "longitude":"33.195", "latitude":"-0.3131"},
{"id":"1452","post_code":"45002","name":"Mechria","wilaya_id":"45", "ar_name":"مشرية", "longitude":"33.4841", "latitude":"-0.2829"},
{"id":"1453","post_code":"45003","name":"Ain Sefra","wilaya_id":"45", "ar_name":"عين الصفراء", "longitude":"32.6879", "latitude":"-0.585"},
{"id":"1454","post_code":"45004","name":"Tiout","wilaya_id":"45", "ar_name":"تيوت", "longitude":"32.7041", "latitude":"-0.4202"},
{"id":"1455","post_code":"45005","name":"Sfissifa","wilaya_id":"45", "ar_name":"صفيصيفة", "longitude":"32.6648", "latitude":"-0.8679"},
{"id":"1456","post_code":"45006","name":"Moghrar","wilaya_id":"45", "ar_name":"مغرار", "longitude":"32.4495", "latitude":"-0.5795"},
{"id":"1457","post_code":"45007","name":"Assela","wilaya_id":"45", "ar_name":"عسلة", "longitude":"32.9372", "latitude":"-0.0769"},
{"id":"1458","post_code":"45008","name":"Djeniane Bourzeg","wilaya_id":"45", "ar_name":"جنين بورزق", "longitude":"32.3057", "latitude":"-0.8047"},
{"id":"1459","post_code":"45009","name":"Ain Ben Khelil","wilaya_id":"45", "ar_name":"عين بن خليل", "longitude":"33.2226", "latitude":"-0.7635"},
{"id":"1460","post_code":"45010","name":"Makman Ben Amer","wilaya_id":"45", "ar_name":"مكمن بن عمر", "longitude":"33.6512", "latitude":"-0.7278"},
{"id":"1461","post_code":"45011","name":"Kasdir","wilaya_id":"45", "ar_name":"قصدير", "longitude":"33.6443", "latitude":"-1.3596"},
{"id":"1462","post_code":"45012","name":"El Biod","wilaya_id":"45", "ar_name":"البيوض", "longitude":"33.6969", "latitude":"-0.1346"},
{"id":"1463","post_code":"46001","name":"Ain Temouchent","wilaya_id":"46", "ar_name":"عـيـن تـمـوشـنـت", "longitude":"35.2254", "latitude":"-1.1398"},
{"id":"1464","post_code":"46002","name":"Chaabet El Ham","wilaya_id":"46", "ar_name":"شعبة اللحم", "longitude":"35.2703", "latitude":"-1.1014"},
{"id":"1465","post_code":"46003","name":"Ain Kihal","wilaya_id":"46", "ar_name":"عين الكيحل", "longitude":"35.1401", "latitude":"-1.1948"},
{"id":"1466","post_code":"46004","name":"Hammam Bouhadjar","wilaya_id":"46", "ar_name":"حمام بو حجر", "longitude":"35.3151", "latitude":"-0.9695"},
{"id":"1467","post_code":"46005","name":"Bou Zedjar","wilaya_id":"46", "ar_name":"بوزجار", "longitude":"35.5099", "latitude":"-1.1673"},
{"id":"1468","post_code":"46006","name":"Oued Berkeche","wilaya_id":"46", "ar_name":"وادى برقش", "longitude":"35.1581", "latitude":"-0.9833"},
{"id":"1469","post_code":"46007","name":"Aghlal","wilaya_id":"46", "ar_name":"أغلال", "longitude":"35.1356", "latitude":"-1.0684"},
{"id":"1470","post_code":"46008","name":"Terga","wilaya_id":"46", "ar_name":"تارقة", "longitude":"35.3532", "latitude":"-1.1783"},
{"id":"1471","post_code":"46009","name":"Ain El Arbaa","wilaya_id":"46", "ar_name":"عين الاربعاء", "longitude":"35.342", "latitude":"-0.8817"},
{"id":"1472","post_code":"46010","name":"Tamzoura","wilaya_id":"46", "ar_name":"تامزوغة", "longitude":"35.3443", "latitude":"-0.6564"},
{"id":"1473","post_code":"46011","name":"Chentouf","wilaya_id":"46", "ar_name":"شنتوف", "longitude":"35.2389", "latitude":"-1.03"},
{"id":"1474","post_code":"46012","name":"Sidi Ben Adda","wilaya_id":"46", "ar_name":"سيدي بن عدة", "longitude":"35.2411", "latitude":"-1.181"},
{"id":"1475","post_code":"46013","name":"Aoubellil","wilaya_id":"46", "ar_name":"عقب الليل", "longitude":"35.0705", "latitude":"-0.9915"},
{"id":"1476","post_code":"46014","name":"El Malah","wilaya_id":"46", "ar_name":"المالح", "longitude":"35.3286", "latitude":"-1.0931"},
{"id":"1477","post_code":"46015","name":"Sidi Boumediene","wilaya_id":"46", "ar_name":"سيدي بومدين", "longitude":"35.2882", "latitude":"-0.8926"},
{"id":"1478","post_code":"46016","name":"Oued Sabah","wilaya_id":"46", "ar_name":"وادى الصباح", "longitude":"35.3084", "latitude":"-0.8102"},
{"id":"1479","post_code":"46017","name":"Ouled Boudjemaa","wilaya_id":"46", "ar_name":"أولاد بوجمعة", "longitude":"35.4092", "latitude":"-1.192"},
{"id":"1480","post_code":"46018","name":"Ain Tolba","wilaya_id":"46", "ar_name":"عين الطلبة", "longitude":"35.1828", "latitude":"-1.2497"},
{"id":"1481","post_code":"46019","name":"El Amria","wilaya_id":"46", "ar_name":"العامرية", "longitude":"35.4607", "latitude":"-1.0162"},
{"id":"1482","post_code":"46020","name":"Hassi El Ghella","wilaya_id":"46", "ar_name":"حاسى الغلة", "longitude":"35.389", "latitude":"-1.0519"},
{"id":"1483","post_code":"46021","name":"Hassasna","wilaya_id":"46", "ar_name":"الحساسنة", "longitude":"35.2075", "latitude":"-0.986"},
{"id":"1484","post_code":"46022","name":"Ouled Kihal","wilaya_id":"46", "ar_name":"أولاد الكيحل", "longitude":"35.3039", "latitude":"-1.236"},
{"id":"1485","post_code":"46023","name":"Beni Saf","wilaya_id":"46", "ar_name":"بني صاف", "longitude":"35.2366", "latitude":"-1.3843"},
{"id":"1486","post_code":"46024","name":"Sidi Safi","wilaya_id":"46", "ar_name":"سيدي الصافي", "longitude":"35.2165", "latitude":"-1.3129"},
{"id":"1487","post_code":"46025","name":"Oulhaca El Gheraba","wilaya_id":"46", "ar_name":"ولهاصة الغرابة", "longitude":"35.1671", "latitude":"-1.5051"},
{"id":"1488","post_code":"46026","name":"Sidi Ouriache","wilaya_id":"46", "ar_name":"سيدي وريلش", "longitude":"35.1244", "latitude":"-1.5051"},
{"id":"1489","post_code":"46027","name":"El Emir Abdelkader","wilaya_id":"46", "ar_name":"الأمير عبد القادر", "longitude":"35.1603", "latitude":"-1.4035"},
{"id":"1490","post_code":"46028","name":"El Messaid","wilaya_id":"46", "ar_name":"المساعيد", "longitude":"35.4763", "latitude":"-1.1234"},
{"id":"1491","post_code":"47001","name":"Ghardaia","wilaya_id":"47", "ar_name":"غرداية", "longitude":"32.4171", "latitude":"3.6832"},
{"id":"1492","post_code":"47002","name":"El Meniaa","wilaya_id":"47", "ar_name":"المنيعة", "longitude":"30.5197", "latitude":"2.8812"},
{"id":"1493","post_code":"47003","name":"Dhayet Bendhahoua","wilaya_id":"47", "ar_name":"ضاية بن ضحوة", "longitude":"32.4704", "latitude":"3.6063"},
{"id":"1494","post_code":"47004","name":"Berriane","wilaya_id":"47", "ar_name":"بريان", "longitude":"32.7919", "latitude":"3.7628"},
{"id":"1495","post_code":"47005","name":"Metlili","wilaya_id":"47", "ar_name":"متليلي الشعانبة", "longitude":"32.2058", "latitude":"3.6282"},
{"id":"1496","post_code":"47006","name":"El Guerrara","wilaya_id":"47", "ar_name":"الڨرارة", "longitude":"32.7226", "latitude":"4.4934"},
{"id":"1497","post_code":"47007","name":"El Atteuf","wilaya_id":"47", "ar_name":"العطف", "longitude":"32.4101", "latitude":"3.7491"},
{"id":"1498","post_code":"47008","name":"Zelfana","wilaya_id":"47", "ar_name":"زلفانة", "longitude":"32.612", "latitude":"3.312"},
{"id":"1499","post_code":"47009","name":"Sebseb","wilaya_id":"47", "ar_name":"سبسب", "longitude":"32.0965", "latitude":"3.5898"},
{"id":"1500","post_code":"47010","name":"Bounoura","wilaya_id":"47", "ar_name":"بونورة", "longitude":"32.4194", "latitude":"3.7079"},
{"id":"1501","post_code":"47011","name":"Hassi Fehal","wilaya_id":"47", "ar_name":"حاسي الفحل", "longitude":"31.5364", "latitude":"3.6749"},
{"id":"1502","post_code":"47012","name":"Hassi Gara","wilaya_id":"47", "ar_name":"حاسي قارة", "longitude":"30.4842", "latitude":"2.9141"},
{"id":"1503","post_code":"47013","name":"Mansoura","wilaya_id":"47", "ar_name":"منصورة", "longitude":"31.9125", "latitude":"3.7463"},
{"id":"1504","post_code":"48001","name":"Relizane","wilaya_id":"48", "ar_name":"غيليزان", "longitude":"35.6696", "latitude":"0.5493"},
{"id":"1505","post_code":"48002","name":"Oued Rhiou","wilaya_id":"48", "ar_name":"وادي رهيو", "longitude":"35.9035", "latitude":"0.9174"},
{"id":"1506","post_code":"48003","name":"Belaassel Bouzegza","wilaya_id":"48", "ar_name":"بلعسل بوزقزة", "longitude":"35.7766", "latitude":"0.4999"},
{"id":"1507","post_code":"48004","name":"Sidi Saada","wilaya_id":"48", "ar_name":"سيدي سعادة", "longitude":"35.6227", "latitude":"0.206"},
{"id":"1508","post_code":"48005","name":"Ouled Aiche","wilaya_id":"48", "ar_name":"أولاد يعيش", "longitude":"35.7788", "latitude":"0.9998"},
{"id":"1509","post_code":"48006","name":"Sidi Lazreg","wilaya_id":"48", "ar_name":"سيدي لزرق", "longitude":"35.5803", "latitude":"0.7745"},
{"id":"1510","post_code":"48007","name":"El Hamadna","wilaya_id":"48", "ar_name":"الحمادنة", "longitude":"35.8367", "latitude":"0.7498"},
{"id":"1511","post_code":"48008","name":"Sidi Mhamed Ben Ali","wilaya_id":"48", "ar_name":"سيدي امحمد بن علي", "longitude":"36.0791", "latitude":"0.8432"},
{"id":"1512","post_code":"48009","name":"Mediouna","wilaya_id":"48", "ar_name":"مديونة", "longitude":"36.0524", "latitude":"0.7498"},
{"id":"1513","post_code":"48010","name":"Sidi Khettab","wilaya_id":"48", "ar_name":"سيدي خطاب", "longitude":"35.8479", "latitude":"0.5109"},
{"id":"1514","post_code":"48011","name":"Ammi Moussa","wilaya_id":"48", "ar_name":"عمي موسى", "longitude":"35.8033", "latitude":"1.1179"},
{"id":"1515","post_code":"48012","name":"Zemmoura","wilaya_id":"48", "ar_name":"زمورة", "longitude":"35.6539", "latitude":"0.7498"},
{"id":"1516","post_code":"48013","name":"Beni Dergoun","wilaya_id":"48", "ar_name":"بني درقن", "longitude":"35.7164", "latitude":"0.7993"},
{"id":"1517","post_code":"48014","name":"Djidiouia","wilaya_id":"48", "ar_name":"جيديوة", "longitude":"35.8657", "latitude":"0.8295"},
{"id":"1518","post_code":"48015","name":"El Guettar","wilaya_id":"48", "ar_name":"القطارة", "longitude":"36.0236", "latitude":"0.8157"},
{"id":"1519","post_code":"48016","name":"Hamri","wilaya_id":"48", "ar_name":"الحمري", "longitude":"36.042", "latitude":"-0.379"},
{"id":"1520","post_code":"48017","name":"El Matmar","wilaya_id":"48", "ar_name":"المطمار", "longitude":"35.6673", "latitude":"0.4614"},
{"id":"1521","post_code":"48018","name":"Sidi Mhamed Ben Aouda","wilaya_id":"48", "ar_name":"سيدي بن عودة", "longitude":"35.54", "latitude":"0.5878"},
{"id":"1522","post_code":"48019","name":"Ain Tarek","wilaya_id":"48", "ar_name":"عين طارق", "longitude":"35.7164", "latitude":"1.1316"},
{"id":"1523","post_code":"48020","name":"Oued Essalem","wilaya_id":"48", "ar_name":"وادي السلام", "longitude":"35.5155", "latitude":"0.9256"},
{"id":"1524","post_code":"48021","name":"Ouarizane","wilaya_id":"48", "ar_name":"ﻭﺍﺭﻳﺯﺍﻥ", "longitude":"35.9813", "latitude":"0.8981"},
{"id":"1525","post_code":"48022","name":"Mazouna","wilaya_id":"48", "ar_name":"مازونة", "longitude":"36.0635", "latitude":"0.8762"},
{"id":"1526","post_code":"48023","name":"Kalaa","wilaya_id":"48", "ar_name":"قلعة", "longitude":"35.5177", "latitude":"0.3296"},
{"id":"1527","post_code":"48024","name":"Ain Rahma","wilaya_id":"48", "ar_name":"عين الرحمة", "longitude":"35.5602", "latitude":"0.3928"},
{"id":"1528","post_code":"48025","name":"Yellel","wilaya_id":"48", "ar_name":"يلل", "longitude":"35.6584", "latitude":"0.3543"},
{"id":"1529","post_code":"48026","name":"Oued El Djemaa","wilaya_id":"48", "ar_name":"وادى الجمعة", "longitude":"35.7343", "latitude":"0.6812"},
{"id":"1530","post_code":"48027","name":"Ramka","wilaya_id":"48", "ar_name":"رمكة", "longitude":"35.7922", "latitude":"1.3321"},
{"id":"1531","post_code":"48028","name":"Mendes","wilaya_id":"48", "ar_name":"مندس", "longitude":"35.587", "latitude":"0.8624"},
{"id":"1532","post_code":"48029","name":"Lahlef","wilaya_id":"48", "ar_name":"لحلاف", "longitude":"35.8301", "latitude":"0.9805"},
{"id":"1533","post_code":"48030","name":"Beni Zentis","wilaya_id":"48", "ar_name":"بني زنتيس", "longitude":"36.048", "latitude":"0.6647"},
{"id":"1534","post_code":"48031","name":"Souk El Haad","wilaya_id":"48", "ar_name":"سوق الحد", "longitude":"35.8924", "latitude":"1.2469"},
{"id":"1535","post_code":"48032","name":"Dar Ben Abdellah","wilaya_id":"48", "ar_name":"دار بن عبد الله", "longitude":"35.6383", "latitude":"0.6866"},
{"id":"1536","post_code":"48033","name":"El Hassi","wilaya_id":"48", "ar_name":"الحاسى", "longitude":"35.7075", "latitude":"1.0547"},
{"id":"1537","post_code":"48034","name":"Had Echkalla","wilaya_id":"48", "ar_name":"حد الشقالة", "longitude":"35.616", "latitude":"1.1481"},
{"id":"1538","post_code":"48035","name":"Bendaoud","wilaya_id":"48", "ar_name":"بن داود", "longitude":"35.6562", "latitude":"0.5191"},
{"id":"1539","post_code":"48036","name":"El Ouldja","wilaya_id":"48", "ar_name":"العلجة", "longitude":"35.8479", "latitude":"1.1206"},
{"id":"1540","post_code":"48037","name":"Merdja Sidi Abed","wilaya_id":"48", "ar_name":"مرجة سيدي عابد", "longitude":"36.013883", "latitude":"0.989746"},
{"id":"1541","post_code":"48038","name":"Ouled Sidi Mihoub","wilaya_id":"48", "ar_name":"أولاد سيدي ميهوب", "longitude":"35.9752", "latitude":"0.6916"},
{"id":"1542","post_code":"49000","name":"El M'ghair","wilaya_id":"49", "ar_name":"المغير", "longitude":"33.947222", "latitude":"5.922222"},    {"id":"50","code":"50","name":"El Menia", "ar_name":"المنيعة", "longitude":"30.579167", "latitude":"2.879167"},
{"id":"1543","post_code":"50000","name":"El Menia","wilaya_id":"50", "ar_name":"المنيعة", "longitude":"30.579167", "latitude":"2.879167"},
{"id":"1544","post_code":"51000","name":"Ouled Djellal","wilaya_id":"51", "ar_name":"أولاد جلال", "longitude":"34.433333", "latitude":"5.066667"},
{"id":"1545","post_code":"52000","name":"Bordj Baji Mokhtar","wilaya_id":"52", "ar_name":"برج باجي مختار", "longitude":"21.327778", "latitude":"0.955556"},
{"id":"1546","post_code":"53000","name":"Béni Abbès", "wilaya_id":"53", "ar_name":"بني عباس", "longitude":"30.133333", "latitude":"-2.166667"},
{"id":"1547","post_code":"54000","name":"Timimoun","wilaya_id":"54", "ar_name":"تيميمون", "longitude":"29.258333", "latitude":"0.230556"},
{"id":"1548","post_code":"55000","name":"Touggourt","wilaya_id":"55", "ar_name":"تقرت", "longitude":"33.108333", "latitude":"6.063889"},
{"id":"1549","post_code":"56000","name":"Djanet","wilaya_id":"56", "ar_name":"جانت", "longitude":"24.554167", "latitude":"9.484722"},
{"id":"1550","post_code":"57000","name":"In Salah","wilaya_id":"57", "ar_name":"عين صالح", "longitude":"27.197222", "latitude":"2.483333"},
{"id":"1551","post_code":"58000","name":"In Guezzam", "wilaya_id":"58","ar_name":"عين قزام", "longitude":"19.572222", "latitude":"5.769444"}
    ]

export default Communes